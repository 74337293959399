import React, { useEffect, useState } from "react";
import "../../../../assets/css/TCGL_V_A1/duplicate.scss";
// import logo from "../../../../assets/img/TCGL_V_A1/logo.png";
import like from "../../../../assets/img/TCGL_V_A1/like.png";
// import logo_webp from "../../../../assets/img/TCGL_V_A1/logo.webp";
import { urlParams,getQueryString } from "../../../../Utility/QueryString";
import { useDuplicateInfo } from "../../../../Hooks/useDuplicateInfo";
import { UUID } from "../../../../Utility/UUID";
import LogoBasedOnDomain from "../Common/LogoBasedOnDomain";
import Footer from '../../../Includes/Layouts/TCGL_V_A1/Footer';


const Duplicate = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [fadeOut, setFadeOut] = useState(false);
    const query = getQueryString();
    const { saveDuplicateLog } = useDuplicateInfo();
    const {fetchUuid} = UUID()

    localStorage.clear();
    useEffect(() => {
        const type = new URLSearchParams(query).get("type");
        const key = new URLSearchParams(query).get("key");
        let uuid = fetchUuid();
        if (!uuid) {
            uuid = new URLSearchParams(query).get("uuid");
          }
        const fetchData = async () => {
            console.log(type, uuid);
            await saveDuplicateLog(type, uuid, key);
            const timer = setTimeout(() => {
                setFadeOut(true);
                setTimeout(() => {
                    setIsLoading(false);
                }, 500);
            }, 2000);
            return () => clearTimeout(timer);
        };

        fetchData();

    }, []);

    return (
        <>
            <div className="TCGL_V_A1 ">
                {/*TOP NAVIGATION*/}
                <div className="duplicate">
                    <header id="header" className="sticky-header position-relative">
                        <div className="container text-center">
                            {/* <a href="index.html">
                            <img className="logo" src={logo} alt="" />
                        </a> */}
                            <a><LogoBasedOnDomain bg="black" /></a>
                        </div>
                    </header>
                    <div className="container ">
                        <div className="row mt-5 pb-5 justify-content-center">
                            <div className="col-lg-12 col-12 mt-5 thankyou-div text-center">
                                <img
                                    src={like}
                                    height={64}
                                    width={64}
                                    className="animate__animated  animate__bounce"
                                    alt=""
                                />
                                <h1>Thank You</h1>
                                <h2>Looks like you are already a client of The Claims Guys Legal.</h2>
                                <p>
                                    We will be in touch with updates for any active claims you have with
                                    us.
                                </p>
                            </div>
                        </div>
                    </div>
                    {isLoading && <div className={`loading-step ${fadeOut ? 'fade-out' : ''}`}>
                        <div className="loading-spinner">
                            {/* <img
                            src={logo_webp}
                            alt=""
                            className="loader_logo animate__animated  animate__bounce"
                        /> */}
                            {/* <LogoBasedOnDomain className="loader_logo animate__animated  animate__bounce" bg="black" /> */}
                        </div>
                        <div className="overlay ">
                            <p className="fw-bold showBefore_add" />
                        </div>
                    </div>}
                </div>
                <div className="btm_sec" style={{marginTop:"30%"}}>
                    <Footer />
                </div>
            </div>
        </>

    )
}
export default Duplicate;