
import React, { useReducer, useState, useContext, useRef } from "react";
import AddressTextField from "../UI/AddressTextField";
import AddressSelectBox from "../UI/AddressSelectBox";
import { usePostCodeAddress } from "../../Hooks/usePostCodeAddress";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import Button from "../UI/Button";
import HiddenField from "../UI/HiddenField";

const initialState = {
    lookUp: true,
    nextClick: false,
    checkSelect: false,
    getDetails: [],
    getAddress: [],
    showManualAddress: "hide",
    getPostcode: "#",
};
const postcodeReducer = (state, action) => {
    switch (action.type) {
        case "validationSucess": {
            return {
                ...state,
                lookUp: action.payload.lookup,
                nextClick: action.payload.next_click,
                getPostcode: action.payload.postcode,
                getAddress: action.payload.get_address,
                checkSelect: action.payload.check_select,
                showManualAddress: action.payload.manual_address,
            };
        }
        case "getExtraAddressDetails": {
            return {
                ...state,
                getDetails: action.payload.get_details,
                showManualAddress: action.payload.manual_address,
            };
        }
        case "showManualDiv": {
            return {
                ...state,
                showManualAddress: "show",
                lookUp: false,
                nextClick: true,
                checkSelect: false,
                clickManualLink: action.payload.manual_click,
            };
        }
        case "resetaddress": {
            return {
                ...state,
                getDetails: [],
                getAddress: [],
                showManualAddress: "hide",
                lookUp: true,
                nextClick: false,
                checkSelect: false,
            };
        }
        default:
            return state;
    }
};
const PreviousPostCodeEdit = (props) => {
    const { getPostcodeAddress, getSplitPostcodeAddress } =
        usePostCodeAddress();
    const [state, dispatch] = useReducer(postcodeReducer, initialState);
    const { visitorParameters } = useContext(VisitorParamsContext);
    const scrollToEvent = useRef(null);
    const [preRawPostCode,setPreRawPostCode] = useState('');
    const checkValidation = async (e) => {
        e.preventDefault();
        dispatch({
            type: "resetaddress",
            get_address: [],
        });
        const txtPrevPostCode = await props.trigger(`txtPrevPostCode_${props.preCount}`);
        if (txtPrevPostCode) {
            await getPostcodeAddress(e.target.value, visitorParameters.visitor_parameters.uuid).then(res => {
                if (res.data.status === "0") {
                    props.setError(`txtPrevPostCode_${props.preCount}`, {
                        type: "manual",
                        message: "Please Enter Valid Postcode",
                    });
                }
                if (res.data.length > 0) {
                    dispatch({
                        type: "validationSucess",
                        payload: {
                            lookup: false,
                            next_click: true,
                            postcode: e.target.value,
                            get_address: res.data,
                            check_select: true,
                            manual_address: "hide",
                        },
                    });
                }
            }).catch(
                function () {
                    props.setError(`txtPrevPostCode_${props.preCount}`, {
                        type: "manual",
                        message: "Please Enter Valid Postcode",
                    });
                }
            );
        }
    };
    const getValue = async (e) => {
        e.preventDefault();
        props.clearErrors([
            `txtPrevHouseNumber_${props.preCount}`,
            `txtPrevAddress3_${props.preCount}`,
            `txtPrevCounty_${props.preCount}`,
            `txtPrevTown_${props.preCount}`,
            `txtPrevCountry_${props.preCount}`,
        ]);
        if (e.target.value != "") {
            const getData = await getSplitPostcodeAddress(
                e.target.value,
                visitorParameters.visitor_parameters.uuid,
                // state.getPostcode,
            );
            const rawPostCode = getData?.postCode || '';
            setPreRawPostCode(rawPostCode);
            dispatch({
                type: "getExtraAddressDetails",
                payload: { get_details: getData, manual_address: "show" },
            });
            // props.setPrevPostcodeval(prepostcodeval);
            props.setPostCode((prepostcodeval) => {
                const updatedPostCodes = [...prepostcodeval]; // Create a shallow copy of the array
                updatedPostCodes[props.preCount] = state.getPostcode; // Update the specific index
                return updatedPostCodes; // Return the updated array
              });
            props.clearErrors([
                `txtPrevAddress1_${props.preCount}`,
                `txtPrevHouseNumber_${props.preCount}`,
                `txtPrevAddress3_${props.preCount}`,
                `txtPrevCounty_${props.preCount}`,
                `txtPrevTown_${props.preCount}`,
                `txtPrevCountry_${props.preCount}`,
            ]);
              setTimeout(() => {
                props.scrollToEvent.current.scrollIntoView({
                  behavior: 'smooth',
                  block: 'end',
                });
              }, 50);
        }
        else {
            dispatch({
                type: "getExtraAddressDetails",
                payload: { get_details: [], manual_address: "hide" },
            });
        }
    };

    const lookupValidation = async (e) => {
        await props.trigger(`txtPrevPostCode_${props.preCount}`);
    };

    const HiddenFields = () => (
        <>
          <HiddenField
            name={`txtPrevUdprn_${props.preCount}`}
            inputId={`txtPrevUdprn_${props.preCount}`}
            inputValue={state.getDetails !== "#" ? state.getDetails.Udprn : ""}
          />
          <HiddenField
            name={`txtPrevDeliveryPointSuffix_${props.preCount}`}
            inputId={`txtPrevDeliveryPointSuffix_${props.preCount}`}
            inputValue={
              state.getDetails !== "#" ? state.getDetails.deliverypointsuffix : ""
            }
          />
          <HiddenField
            name={`txtPrevPz_mailsort_${props.preCount}`}
            inputId={`txtPrevPz_mailsort_${props.preCount}`}
            inputValue={
              state.getDetails !== "#" ? state.getDetails.pz_mailsort : ""
            }
          />
          <HiddenField
            name={`txtPrevStreet_${props.preCount}`}
            inputId={`txtPrevStreet_${props.preCount}`}
            inputValue={
              state.getDetails.line_3 !== "#" ? state.getDetails.line_3 : ""
            }
          />
        </>
      );

    return (
        <div className={`postcodeyes row mt-3 ${props.showPrevPostCode}`}>
            <div className="col-12 d-flex mb-3">
                <div className="col-6">
                    <AddressTextField
                        type="text"
                        placeholder={props.placeholder}
                        name={`txtPrevPostCode_${props.preCount}`}
                        id={`txtPrevPostCode_${props.preCount}`}
                        className="form-control"
                        dataId={`txtPrevPostCode_${props.preCount}`}
                        onBlur={(e) => {e.target.value = e.target.value.trim();checkValidation(e)}}
                        // onChange={(e) => {
                        //     e.target.value = e.target.value.replace(/\s/g, "");
                        // }}
                        autoComplete="off"
                        validation={props.validation({
                            required: "Please Enter Postcode",
                            pattern: {
                                // value: /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i,
                                value: /^[A-Za-z0-9 ]+$/,
                                message: "Please Enter a Valid Postcode",
                            },
                            minLength: {
                                value: 5,
                                message: "Please Enter a Valid Postcode",
                            },
                        })}
                        validationMsg={
                            props.validationMsg[`txtPrevPostCode_${props.preCount}`] && props.validationMsg[`txtPrevPostCode_${props.preCount}`].message
                            // props.validationMsg.txtPrevPostCode && props.validationMsg.txtPrevPostCode.message
                        }
                    />
                </div>

                <div className="col-6 ms-1">
                    <Button
                        type="button"
                        className="btn-lookup add-btn-shadow"
                        value="Look Up Address"
                        buttonText="Look Up Address"
                        onClick={lookupValidation}
                    />
                </div>
            </div>
            <div id="currentAddressCollapse" style={state.checkSelect ? { display: "block" } : { display: "none" }}>
                <div className="col-lg-12 mb-3">
                    <AddressSelectBox
                        className="form-select"
                        OptionValue={state.getAddress}
                        name={`txtPrevAddress1_${props.preCount}`}
                        id={`txtPrevAddress1_${props.preCount}`}
                        onChange={getValue}
                        myRef={props.validation({ required: "Please select address" })}
                        validationMsg={
                            props.validationMsg[`txtPrevAddress1_${props.preCount}`] && props.validationMsg[`txtPrevAddress1_${props.preCount}`].message
                        }
                    />
                </div>

                <div className={`slide8_add row ${state.showManualAddress}`}>
                <div className={`col-lg-12 mb-3 form-group required-field adj_width`} style={{ display: state.getDetails.line_1 ? 'block' : 'none' }}>
                        <AddressTextField
                            type="text"
                            placeholder="Address Line 1 *"
                            name={`txtPrevHouseNumber_${props.preCount}`}
                            id={`txtPrevHouseNumber_${props.preCount}`}
                            className="form-control"
                            dataId={`txtPrevHouseNumber_${props.preCount}`}
                            autoComplete="off"
                            readonly={true}
                            validation={props.validation({
                                required: "Please Enter Address Line 1",
                            })}
                            validationMsg={
                                props.validationMsg[`txtPrevHouseNumber_${props.preCount}`] &&
                                props.validationMsg[`txtPrevHouseNumber_${props.preCount}`].message
                            }
                            value={state.getDetails.line_1}
                        />
                    </div>
                    <div className={`col-lg-12 mb-3 form-group adj_width`} style={{ display: state.getDetails.line_2 ? 'block' : 'none' }}>
                        <AddressTextField
                            type="text"
                            placeholder="Address Line 2"
                            name={`txtPrevAddress2_${props.preCount}`}
                            id={`txtPrevAddress2_${props.preCount}`}
                            className="form-control"
                            dataId={`txtPrevAddress2_${props.preCount}`}
                            autoComplete="off"
                            readonly={true}
                            validation={props.validation()}
                            value={state.getDetails.line_2}
                        />
                    </div>
                    <div className={`col-lg-12 mb-3 form-group adj_width`} style={{ display: state.getDetails.line_3 ? 'block' : 'none' }}>
                        <AddressTextField
                            type="text"
                            placeholder="Address Line 3"
                            name={`txtPrevAddress3_${props.preCount}`}
                            id={`txtPrevAddress3_${props.preCount}`}
                            className="form-control"
                            dataId={`txtPrevAddress3_${props.preCount}`}
                            autoComplete="off"
                            readonly={true}
                            validation={props.validation()}
                            validationMsg=""
                            value={state.getDetails.line_3}
                        />
                    </div>
                    <div className={`col-lg-12 mb-3 form-group adj_width`} style={{ display: state.getDetails.line_4 ? 'block' : 'none' }}>
                        <AddressTextField
                            type="text"
                            placeholder="Address Line 4"
                            name={`txtPrevAddress4_${props.preCount}`}
                            id={`txtPrevAddress4_${props.preCount}`}
                            className="form-control"
                            dataId={`txtPrevAddress4_${props.preCount}`}
                            autoComplete="off"
                            readonly={true}
                            validation={props.validation()}
                            validationMsg=""
                            value={state.getDetails.line_4}
                        />
                    </div>
                    <div className={`col-lg-12 mb-3 form-group adj_width`} style={{ display: state.getDetails.county ? 'block' : 'none' }}>
                        <AddressTextField
                            type="text"
                            placeholder="County"
                            name={`txtPrevCounty_${props.preCount}`}
                            id={`txtPrevCounty_${props.preCount}`}
                            className="form-control"
                            dataId={`txtPrevCounty_${props.preCount}`}
                            autoComplete="off"
                            readonly={true}
                            validation={props.validation()}
                            validationMsg=""
                            value={state.getDetails.county}
                        />
                    </div>
                    <div className={`col-lg-12 mb-3 form-group adj_width`} style={{ display: state.getDetails.town ? 'block' : 'none' }}>
                        <AddressTextField
                            type="text"
                            placeholder="Town *"
                            name={`txtPrevTown_${props.preCount}`}
                            id={`txtPrevTown_${props.preCount}`}
                            className="form-control  hero-input"
                            dataId={`txtPrevTown_${props.preCount}`}
                            autoComplete="off"
                            readonly={true}
                            validation={props.validation({ required: "Please Enter Town" })}
                            validationMsg={
                                props.validationMsg[`txtPrevTown_${props.preCount}`] && props.validationMsg[`txtPrevTown_${props.preCount}`].message
                            }
                            value={state.getDetails.town}
                        />
                    </div>
                    <div className={`col-lg-12 mb-3 form-group adj_width}`} style={{ display: state.getDetails.country ? 'block' : 'none' }}>
                        <AddressTextField
                            type="text"
                            placeholder="Country *"
                            name={`txtPrevCountry_${props.preCount}`}
                            id={`txtPrevCountry_${props.preCount}`}
                            className="form-control  hero-input"
                            dataId={`txtPrevCountry_${props.preCount}`}
                            autoComplete="off"
                            readonly={true}
                            validation={props.validation({ required: "Please Enter Country" })}
                            validationMsg={
                                props.validationMsg[`txtPrevCountry_${props.preCount}`] && props.validationMsg[`txtPrevCountry_${props.preCount}`].message
                            }
                            value={state.getDetails.country}
                        />
                    </div>
                    <div className={` d-none col-lg-12 mb-3 form-group adj_width}`} style={{ display: state.getDetails.district ? 'block' : 'none' }}>
                        <AddressTextField
                            type="text"
                            placeholder="District *"
                            name={`txtPrevDistrict_${props.preCount}`}
                            id={`txtPrevDistrict_${props.preCount}`}
                            className="form-control  hero-input"
                            dataId={`txtPrevDistrict_${props.preCount}`}
                            autoComplete="off"
                            readonly={true}
                            validation={props.validation({ required: "Please Enter Country" })}
                            validationMsg={
                                props.validationMsg[`txtPrevDistrict_${props.preCount}`] && props.validationMsg[`txtPrevDistrict_${props.preCount}`].message
                            }
                            value={state.getDetails.district}
                        />
                    </div>
                    <div className="d-none mb-2">
                        <AddressTextField
                            type="text"
                            placeholder="Building Name"
                            name={`txtPrevBuildingName_${props.preCount}`}
                            id={`txtPrevBuildingName_${props.preCount}`}
                            className="form-control address hero-input"
                            dataId={`txtPrevBuildingName_${props.preCount}`}
                            autoComplete="off"
                            validation={props.validation()}
                            // validationMsg={
                            //   props.validationMsg[`txtPrevBuildingName_${props.preCount}`] && props.validationMsg[`txtPrevBuildingName_${props.preCount}`].message
                            // }
                            value={state.getDetails?.RawAddress?.BuildingName}
                            readonly={true}
                        />
                    </div>
                    <div className="d-none mb-2">
                        <AddressTextField
                            type="text"
                            placeholder="Building Number"
                            name={`txtPrevBuildingNumber_${props.preCount}`}
                            id={`txtPrevBuildingNumber_${props.preCount}`}
                            className="form-control address hero-input"
                            dataId={`txtPrevBuildingNumber_${props.preCount}`}
                            autoComplete="off"
                            validation={props.validation()}
                            // validationMsg={
                            //   props.validationMsg[`txtPrevBuildingNumber_${props.preCount}`] && props.validationMsg[`txtPrevBuildingNumber_${props.preCount}`].message
                            // }
                            value={state.getDetails?.RawAddress?.BuildingNumber}
                            readonly={true}
                        />
                    </div>
                    <div className="d-none mb-2">
                        <AddressTextField
                            type="text"
                            placeholder="Thoroughfare"
                            name={`txtPrevThoroughfare_${props.preCount}`}
                            id={`txtPrevThoroughfare_${props.preCount}`}
                            className="form-control address hero-input"
                            dataId={`txtPrevThoroughfare_${props.preCount}`}
                            autoComplete="off"
                            validation={props.validation()}
                            // validationMsg={
                            //   props.validationMsg[`txtPrevThoroughfare_${props.preCount}`] && props.validationMsg[`txtPrevThoroughfare_${props.preCount}`].message
                            // }
                            value={state.getDetails?.RawAddress?.ThoroughfareName}
                            readonly={true}
                        />
                    </div>
                    <div className="d-none mb-2">
                        <AddressTextField
                            type="text"
                            placeholder="Sub building name"
                            name={`txtPrevSubBuildingName_${props.preCount}`}
                            id={`txtPrevSubBuildingName_${props.preCount}`}
                            className="form-control address hero-input"
                            dataId={`txtPrevSubBuildingName_${props.preCount}`}
                            autoComplete="off"
                            validation={props.validation()}
                            // validationMsg={
                            //   props.validationMsg[`txtPrevSubBuildingName_${props.preCount}`] && props.validationMsg[`txtPrevSubBuildingName_${props.preCount}`].message
                            // }
                            value={state.getDetails?.RawAddress?.SubBuildingName}
                            readonly={true}
                        />
                    </div>
                    <div className="d-none mb-2">
                        <AddressTextField
                            type="text"
                            placeholder="Sub building number"
                            name={`txtPrevSubBuildingNumber_${props.preCount}`}
                            id={`txtPrevSubBuildingNumber_${props.preCount}`}
                            className="form-control address hero-input"
                            dataId={`txtPrevSubBuildingNumber_${props.preCount}`}
                            autoComplete="off"
                            validation={props.validation()}
                            // validationMsg={
                            //   props.validationMsg[`txtPrevSubBuildingNumber_${props.preCount}`] && props.validationMsg[`txtPrevSubBuildingNumber_${props.preCount}`].message
                            // }
                            value={state.getDetails?.RawAddress?.SubBuildingNumber}
                            readonly={true}
                        />
                    </div>
                    <div className="d-none mb-2">
                        <AddressTextField
                            type="text"
                            placeholder="Address Line 4"
                            name={`txtPrevAddress4_${props.preCount}`}
                            id={`txtPrevAddress4_${props.preCount}`}
                            className="form-control  address hero-input"
                            dataId={`txtPrevAddress4_${props.preCount}`}
                            autoComplete="off"
                            validation={props.validation({
                                required: "Select Address",
                                message: "Please Select Address",
                            })}
                            value={state.getDetails.line_4}
                            readonly={true}
                        />
                    </div>
                    <div className="d-none mb-2">
                        <AddressTextField
                            type="text"
                            placeholder="Locality"
                            name={`txtPrevLocality_${props.preCount}`}
                            id={`txtPrevLocality_${props.preCount}`}
                            className="form-control address hero-input"
                            dataId={`txtPrevLocality_${props.preCount}`}
                            autoComplete="off"
                            validation={props.validation()}
                            value={state.getDetails?.locality}
                            readonly={true}
                        />
                    </div>
                    <div className="d-none mb-2">
                        <AddressTextField
                            type="text"
                            placeholder="District"
                            name={`txtPrevDistrict_${props.preCount}`}
                            id={`txtPrevDistrict_${props.preCount}`}
                            className="form-control address hero-input"
                            dataId={`txtPrevDistrict_${props.preCount}`}
                            autoComplete="off"
                            validation={props.validation()}
                            value={state.getDetails?.RawAddress?.Location?.District}
                            readonly={true}
                        />
                    </div>
                    <div className="d-none mb-2">
                        <AddressTextField
                            type="text"
                            placeholder="Thoroughfare Description"
                            name={`txtPrevThoroughfareDesc_${props.preCount}`}
                            id={`txtPrevThoroughfareDesc_${props.preCount}`}
                            className="form-control address hero-input"
                            dataId={`txtPrevThoroughfareDesc_${props.preCount}`}
                            autoComplete="off"
                            validation={props.validation()}
                            value={state.getDetails?.RawAddress?.ThoroughfareDesc}
                            readonly={true}
                        />
                    </div>
                    <div className="d-none">
                        <AddressTextField
                            type="text"
                            name={`preRawPostCode_${props.preCount}`}
                            id={`preRawPostCode_${props.preCount}`}
                            value={preRawPostCode}
                            validation={props.validation()}
                        />
                        <AddressTextField
                            type="text"
                            name={`txtPrevOrganisation_${props.preCount}`}
                            id={`txtPrevOrganisation_${props.preCount}`}
                            value={state.getDetails?.RawAddress?.Organisation}
                            validation={props.validation()}
                        />
                        <AddressTextField
                            type="text"
                            name={`txtPrevDepartment_${props.preCount}`}
                            id={`txtPrevDepartment_${props.preCount}`}
                            value={state.getDetails?.RawAddress?.Department}
                            validation={props.validation()}
                        />
                        <AddressTextField
                            type="text"
                            name={`txtPrevAddressKey_${props.preCount}`}
                            id={`txtPrevAddressKey_${props.preCount}`}
                            value={state.getDetails?.RawAddress?.AddressKey}
                            validation={props.validation()}
                        />
                        <AddressTextField
                            type="text"
                            name={`txtPrevOrganisationKey_${props.preCount}`}
                            id={`txtPrevOrganisationKey_${props.preCount}`}
                            value={state.getDetails?.RawAddress?.OrganisationKey}
                            validation={props.validation()}
                        />
                        <AddressTextField
                            type="text"
                            name={`txtPrevPostcodeType_${props.preCount}`}
                            id={`txtPrevPostcodeType_${props.preCount}`}
                            value={state.getDetails?.RawAddress?.PostcodeType}
                            validation={props.validation()}
                        />
                        <AddressTextField
                            type="text"
                            name={`txtPrevDependentThoroughfareName_${props.preCount}`}
                            id={`txtPrevDependentThoroughfareName_${props.preCount}`}
                            value={state.getDetails?.RawAddress?.DependentThoroughfareName}
                            validation={props.validation()}
                        />
                        <AddressTextField
                            type="text"
                            name={`txtPrevDependentThoroughfareDesc_${props.preCount}`}
                            id={`txtPrevDependentThoroughfareDesc_${props.preCount}`}
                            value={state.getDetails?.RawAddress?.DependentThoroughfareDesc}
                            validation={props.validation()}
                        />
                        <AddressTextField
                            type="text"
                            name={`txtPrevDoubleDependentLocality_${props.preCount}`}
                            id={`txtPrevDoubleDependentLocality_${props.preCount}`}
                            value={state.getDetails?.RawAddress?.DoubleDependentLocality}
                            validation={props.validation()}
                        />
                        <AddressTextField
                            type="text"
                            name={`txtPrevDependentLocality_${props.preCount}`}
                            id={`txtPrevDependentLocality_${props.preCount}`}
                            value={state.getDetails?.RawAddress?.DependentLocality}
                            validation={props.validation()}
                        />
                        <AddressTextField
                            type="text"
                            name={`txtPrevDps_${props.preCount}`}
                            id={`txtPrevDps_${props.preCount}`}
                            value={state.getDetails?.RawAddress?.Dps}
                            validation={props.validation()}
                        />
                        <AddressTextField
                            type="text"
                            name={`txtPrevPoBox_${props.preCount}`}
                            id={`txtPrevPoBox_${props.preCount}`}
                            value={state.getDetails?.RawAddress?.PoBox}
                            validation={props.validation()}
                        />
                        <AddressTextField
                            type="text"
                            name={`txtPrevPostalCounty_${props.preCount}`}
                            id={`txtPrevPostalCounty_${props.preCount}`}
                            value={state.getDetails?.RawAddress?.PostalCounty}
                            validation={props.validation()}
                        />
                        <AddressTextField
                            type="text"
                            name={`txtPrevTraditionalCounty_${props.preCount}`}
                            id={`txtPrevTraditionalCounty_${props.preCount}`}
                            value={state.getDetails?.RawAddress?.TraditionalCounty}
                            validation={props.validation()}
                        />
                        <AddressTextField
                            type="text"
                            name={`txtPrevAdministrativeCounty_${props.preCount}`}
                            id={`txtPrevAdministrativeCounty_${props.preCount}`}
                            value={state.getDetails?.RawAddress?.AdministrativeCounty}
                            validation={props.validation()}
                        />
                        <AddressTextField
                            type="text"
                            name={`txtPrevCountryISO2_${props.preCount}`}
                            id={`txtPrevCountryISO2_${props.preCount}`}
                            value={state.getDetails?.RawAddress?.CountryISO2}
                            validation={props.validation()}
                        />
                    </div>
                    {/* {Object.keys(state.getDetails).length > 0 ? <HiddenFields /> : null} */}
                </div>
            </div>
        </div>
    )
};

export default PreviousPostCodeEdit;
