import React from 'react'
import logo from "../../../../assets/img/TCGL_V_A1/Logo_white.png"
import LogoBasedOnDomain from '../Common/LogoBasedOnDomain'
const HeaderUpload = () => {
    return (
        <div>
            <header className="subpageheader">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                        <LogoBasedOnDomain height="40" />
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
}

export default HeaderUpload