import React from "react";
import model from "../../../../assets/img/TCGL_V_A1/model.webp";
import one_img from "../../../../assets/img/TCGL_V_A1/1.webp";
import two_img from "../../../../assets/img/TCGL_V_A1/2.webp";
import three_img from "../../../../assets/img/TCGL_V_A1/3.webp";
import four_img from "../../../../assets/img/TCGL_V_A1/4.webp";
import Accordion from "./Accordion";
import { Anchor } from "react-bootstrap";

const FAQSection = (props) => {
  return (
    <>
      <section className="you-claim mt-lg-5">
        <div className={`bg_wrap ${props.showFaq}`}>
          <div className="container">
            <div className="comm-head">
              <h2 className="text-center">Frequently Asked Questions</h2>
              <h1 className="text-center comm-head mb-lg-5 mb-md-5 mb-sm-4 mb-4">
                Frequently Asked Questions
              </h1>
            </div>
            <div className="row title-sec" />
            <div className="row flx">
              <div className="col-lg-4 col-12 d-none d-md-flex align-items-center">
                <div className="soft-info-thumb">
                  <img
                    src={model}
                    alt="Dashboard"
                    fetchpriority="high"
                    style={{ visibility: "visible", animationName: "fadeInUp" }}
                  />
                  <div
                    className="go-premium wow fadeInRight vert-move"
                    data-wow-delay="500ms"
                  >
                    <span>Start Now</span>
                    <h5>Find your potential claims worth up to £1,100*</h5>
                    <ul className="user-lists">
                      <li>
                        <img
                          decoding="async"
                          src={one_img}
                          alt=""
                          fetchpriority="high"
                          width={100}
                          height={100}
                        />
                      </li>
                      <li>
                        <img
                          decoding="async"
                          src={two_img}
                          alt=""
                          fetchpriority="high"
                          width={100}
                          height={100}
                        />
                      </li>
                      <li>
                        <img
                          decoding="async"
                          src={three_img}
                          alt=""
                          fetchpriority="high"
                          width={100}
                          height={100}
                        />
                      </li>
                      <li>
                        <img
                          decoding="async"
                          src={four_img}
                          alt=""
                          fetchpriority="high"
                          width={100}
                          height={100}
                        />
                      </li>
                      <li>
                        <i className="fas fa-plus" />
                      </li>
                    </ul>
                    <p>(Upto £1,100*)</p>
                    <a
                      className="btn circle mt-25 py-2 px-3 btn-sm btn-theme secondary animation"
                      href="#"
                    >
                      Start Now
                    </a>
                  </div>
                </div>
              </div>
              <div className={`col-lg-7 col-12 offset-lg-1 align-self-center fadein-eff  row text-center  ${props.isVisible ? "visible" : ""}`}>
                <div className="acdn">
                  <Accordion />
                </div>
                <Anchor
                  className="btn-web goto-page-personal"
                  href=""
                  onClick={props.onClick}
                >
                  Check my claim &gt;
                </Anchor>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FAQSection;
