import React, { useState, useReducer, useContext, useEffect } from 'react';
import AddressTextField from '../../UI/AddressTextField';
import AddressSelectBox from '../../UI/AddressSelectBox';
import InputButton from '../../UI/InputButton';
import HiddenField from '../../UI/HiddenField';
import VisitorParamsContext from '../../../Contexts/VisitorParams';
import { usePostCodeAddressCustomApi } from '../../../Hooks/usePostCodeAddressCustomApi';
import returnImg from "../../../assets/img/followup/return.png";
import edit from "../../../assets/img/followup/edit.png";
const initialState = {
  lookUp: true,
  nextClick: false,
  checkSelect: false,
  getDetails: [],
  getAddress: [],
  showManualAddress: "d-none",
  getPostcode: "#",
  clickManualLink: 0,
};
const postcodeReducer = (state, action) => {
  switch (action.type) {
    case "validationSucess": {
      return {
        ...state,
        lookUp: action.payload.lookup,
        nextClick: action.payload.next_click,
        getPostcode: action.payload.postcode,
        getAddress: action.payload.get_address,
        checkSelect: action.payload.check_select,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "getExtraAddressDetails": {
      return {
        ...state,
        getDetails: action.payload.get_details,
        showManualAddress: action.payload.manual_address,
        nextClick: action.payload.next_click,
      };
    }
    case "showManualDiv": {
      return {
        ...state,
        showManualAddress: "d-block",
        lookUp: false,
        nextClick: true,
        checkSelect: false,
        clickManualLink: action.payload.manual_click,
      };
    }
    case "resetaddress": {
      return {
        ...state,
        getDetails: [],
        getAddress: [],
        showManualAddress: "d-none",
        lookUp: true,
        nextClick: false,
        checkSelect: false,
      };
    }

    case "editPostcode": {
      return {
        ...state,
        showManualAddress: "d-none"
      };
    }

    default:
      return state;
  }
};
const FollowUpPostcode = (props) => {
  const { getPostcodeAddressApi } = usePostCodeAddressCustomApi();
  const [state, dispatch] = useReducer(postcodeReducer, initialState);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [postCodeAddress, setPostCodeAddress] = useState([]);
  const [getAddressDetails, setGetAddressDetails] = useState([]);
  const [postcodeEdit, setPostcodeEdit]  = useState('hide');
  const checkValidation = async (e) => {
    e.preventDefault();
    dispatch({
      type: "resetaddress",
      get_address: [],
    });
    const txtPostCode = await props.trigger("txtPostCode");
    if (txtPostCode) {
      try {
        const response = await getPostcodeAddressApi(e.target.value);
        if (response.length > 0) {
          setGetAddressDetails(response);
          dispatch({
            type: "validationSucess",
            payload: {
              lookup: false,
              next_click: false,
              postcode: e.target.value,
              get_address: response,
              check_select: true,
              manual_address: "d-none",
            },
          });
        } else {
          props.setError("txtPostCode", {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });
        }
      } catch (error) {
        console.error('Error fetching postcode details:', error);
        props.setError("txtPostCode", {
          type: "manual",
          message: "Please Enter Valid Postcode",
        });
      }
    }
  };

  useEffect(() => {
    localStorage.removeItem("userSelectedAddress")
  }, [])
  const getValue = async (e) => {
    e.preventDefault();
    props.clearErrors([
      "txtHouseNumber",
      "txtAddress3",
      "txtCounty",
      "txtTown",
      "txtCountry",
    ]);
    // check for duplicate address
    const { name, value } = e.target;
    let storedData = JSON.parse(localStorage.getItem('userSelectedAddress')) || {};
    for (let key in storedData) {
      if (storedData[key] === value && key !== name) {
        props.setError(name, {
          type: "manual",
          message: "The Address cannot be the same as previously entered",
        });
        dispatch({
          type: "getExtraAddressDetails",
          payload: { get_details: [], manual_address: "d-none" },
        });
        setPostCodeAddress([]);

        delete storedData[name];
        localStorage.setItem('userSelectedAddress', JSON.stringify(storedData));
        e.target.value = "";

        return;
      }
    }
    props.clearErrors(name);
    storedData[name] = value;
    localStorage.setItem('userSelectedAddress', JSON.stringify(storedData));
    //////////////////
    if (e.target.value != "") {
      const getExtraAddressData = getAddressDetails.find(address => address.id === e.target.value);
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: getExtraAddressData, manual_address: "show", next_click: true },
      });
      setPostCodeAddress(getExtraAddressData);
      props.clearErrors([
        "txtHouseNumber",
        "txtAddress3",
        "txtCounty",
        "txtTown",
        "txtCountry",
      ]);
      // setTimeout(() => {
      //     props.scrollToEvent.current.scrollIntoView({
      //         behavior: "smooth",
      //         block: 'end'
      //     });
      // }, 50);
    } else {
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: [], manual_address: "d-none" },
      });
      setPostCodeAddress([]);
    }
  };
  const lookupValidation = async (e) => {
    await props.trigger("txtPostCode");
  };
  
  const alterPostcode = (action) => {
    if(action == 'search'){
      props.setValue('txtPostCode', '')
      dispatch({
        type: "resetaddress",
        get_address: [],
      });
      return false;
    }

    if(action == 'edit'){
      setPostcodeEdit('show');
      dispatch({
        type: "editPostcode",
        showManualAddress: "d-none",
      });
    }
  }
  const HiddenFields = () => (
    <>
      <HiddenField
        name="txtUdprn"
        inputId="txtUdprn"
        inputValue={state.getDetails !== "#" ? state.getDetails.Udprn : ""}
      />
      <HiddenField
        name="txtDeliveryPointSuffix"
        inputId="txtDeliveryPointSuffix"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.deliverypointsuffix : ""
        }
      />
      <HiddenField
        name="txtPz_mailsort"
        inputId="txtPz_mailsort"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.pz_mailsort : ""
        }
      />
      <HiddenField
        name="txtStreet"
        inputId="txtStreet"
        inputValue={
          state.getDetails.line_3 !== "#" ? state.getDetails.line_3 : ""
        }
      />


    </>
  );
  return (
    <>

      <div className="enter_postcode_add animate__animated animate__fadeInUp animate__delay-.5s">
        <div className="postcode_wrap">
          <AddressTextField
            type="text"
            placeholder="ENTER POSTCODE"
            name="txtPostCode"
            id="txtPostCode"
            className="form-control"
            dataId="txtPostCode"
            onBlur={(e) => { e.target.value = e.target.value.trim(); checkValidation(e); }}
            autoComplete="off"
            validation={props.validation({
              required: "Please Enter Postcode",
              pattern: {
                // value:
                //   /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i,
                value: /^[A-Za-z0-9 ]+$/,
                message: "Please Enter a Valid Postcode",
              },
              minLength: {
                value: 5,
                message: "Please Enter a Valid Postcode",
              },
            })}
            // validationMsg={
            //   props.validationMsg.txtPostCode && props.validationMsg.txtPostCode.message
            // }
            onChange={() => {
              props.clearErrors(`txtPostCode`);
            }}
          />
          <InputButton
            name="submit0"
            className="bg_add_click button"
            id="findADD"
            value="Look Up Address"
            btnType="button"
            style={{ float: "none" }}
            onClick={lookupValidation}
          />
        </div>
        {props.validationMsg.txtPostCode && (
        <>
          <span className="address1_error error_msg mt-1">{props.validationMsg.txtPostCode.message}</span>
        </>
      )}
        <div className="col-12 pt-3 animate__animated animate__fadeInUp animate__delay-.5s " id="selcetAdd" style={state.checkSelect ? { display: "block" } : { display: "none" }}>
          <AddressSelectBox
            className="form-control "
            OptionValue={state.getAddress}
            name="address1"
            id="address1"
            onChange={getValue}
            myRef={props.validation({ required: "Please Select Address", message: "Please Select Address" })}
            validationMsg={
              props.validationMsg.address1 && props.validationMsg.address1.message
            }
          />
          <div className={`${state.showManualAddress}`}>
            <div className="ADD_detail py-3 px-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <p className="ADD_clr_typ">
                {state.getDetails.line_1 != '' && <>{state.getDetails.line_1} <br /></>}
                {state.getDetails.line_2 != '' && <>{state.getDetails.line_2} <br /></>}
                {state.getDetails.line_3 != '' && <>{state.getDetails.line_3} <br /></>}
                {state.getDetails.county != '' && <>{state.getDetails.county} <br /></>}
                {state.getDetails.town != '' && <>{state.getDetails.town} <br /></>}
                {state.getDetails.country != '' && <>{state.getDetails.country} <br /></>}
              </p>
            </div>

            <a className="return_back">
              <p className="mb-0 mt-3">
                <span>
                  <img src={returnImg} alt="" />
                  <span onClick={(e)=>{alterPostcode('search')}}>Search Again</span>
                </span>
                <span>
                  <span onClick={(e)=>{alterPostcode('edit')}}>Edit</span>
                  <img src={edit} alt="" />
                </span>
              </p>
            </a>
          </div>
          <div id="fullAddress" className={`col-lg-12 ${postcodeEdit}`}>
            {state.getDetails.line_1 != '' && <div className="form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s mt-3">
              <AddressTextField
                type="text"
                placeholder="Address Line 1 *"
                name="txtHouseNumber"
                id="txtHouseNumber"
                className="form-control address hero-input"
                dataId="txtHouseNumber"
                autoComplete="off"
                validation={props.validation({
                  required: "Please Enter Address Line 1",
                })}
                validationMsg={
                  props.validationMsg.txtHouseNumber &&
                  props.validationMsg.txtHouseNumber.message
                }
                value={state.getDetails.line_1}
                readonly={false}
              />
            </div>
            }
            {state.getDetails.line_2 != '' && <div className="form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Address Line 2"
                name="txtAddress2"
                id="txtAddress2"
                className="form-control  address hero-input"
                dataId="txtAddress2"
                autoComplete="off"
                validation={props.validation({
                  required: "Select Address",
                  message: "Please Select Address",
                })}
                value={state.getDetails.line_2}
                readonly={false}
              />
            </div>}
            {state.getDetails.line_3 != '' && <div className="form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Address Line 3"
                name="txtAddress3"
                id="txtAddress3"
                className="form-control  address hero-input"
                dataId="txtAddress3"
                autoComplete="off"
                validation={props.validation({
                  required: "Select Address",
                  message: "Please Select Address",
                })}
                value={state.getDetails.line_3}
                readonly={false}
              />
            </div>
            }

            {state.getDetails.county != '' && <div className="form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="County"
                name="txtCounty"
                id="txtCounty"
                className="form-control  address hero-input"
                dataId="txtCounty"
                autoComplete="off"
                validation={props.validation()}
                validationMsg=""
                value={state.getDetails.county}
                readonly={false}
              />
            </div>
            }

            {state.getDetails.town != '' && <div className="form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Town *"
                name="txtTown"
                id="txtTown"
                className="form-control  address hero-input"
                dataId="txtTown"
                autoComplete="off"
                validation={props.validation({ required: "Please Enter Town" })}
                validationMsg={
                  props.validationMsg.txtTown && props.validationMsg.txtTown.message
                }
                value={state.getDetails.town}
                readonly={false}
              />
            </div>}
            {state.getDetails.country != '' && <div className="form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Country *"
                name="txtCountry"
                id="txtCountry"
                className="form-control address hero-input"
                dataId="txtCountry"
                autoComplete="off"
                validation={props.validation({ required: "Please Enter Country" })}
                validationMsg={
                  props.validationMsg.txtCountry && props.validationMsg.txtCountry.message
                }
                value={state.getDetails.country}
                readonly={false}
              />
            </div>}
            {/* non visible fields */}
            <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Building Name"
                name="txtBuildingName"
                id="txtBuildingName"
                className="form-control address hero-input"
                dataId="txtBuildingName"
                autoComplete="off"
                validation={props.validation()}
                // validationMsg={
                //   props.validationMsg.txtBuildingName && props.validationMsg.txtBuildingName.message
                // }
                value={state.getDetails?.RawAddress?.BuildingName}
                readonly={true}
              />
            </div>
            <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Building Number"
                name="txtBuildingNumber"
                id="txtBuildingNumber"
                className="form-control address hero-input"
                dataId="txtBuildingNumber"
                autoComplete="off"
                validation={props.validation()}
                // validationMsg={
                //   props.validationMsg.txtBuildingNumber && props.validationMsg.txtBuildingNumber.message
                // }
                value={state.getDetails?.RawAddress?.BuildingNumber}
                readonly={true}
              />
            </div>
            <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Thoroughfare"
                name="txtThoroughfare"
                id="txtThoroughfare"
                className="form-control address hero-input"
                dataId="txtThoroughfare"
                autoComplete="off"
                validation={props.validation()}
                // validationMsg={
                //   props.validationMsg.txtThoroughfare && props.validationMsg.txtThoroughfare.message
                // }
                value={state.getDetails?.RawAddress?.ThoroughfareName}
                readonly={true}
              />
            </div>
            <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Sub building name"
                name="txtSubBuildingName"
                id="txtSubBuildingName"
                className="form-control address hero-input"
                dataId="txtSubBuildingName"
                autoComplete="off"
                validation={props.validation()}
                value={state.getDetails?.RawAddress?.SubBuildingName}
                readonly={true}
              />
            </div>
            <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Sub building number"
                name="txtSubBuildingNumber"
                id="txtSubBuildingNumber"
                className="form-control address hero-input"
                dataId="txtSubBuildingNumber"
                autoComplete="off"
                validation={props.validation()}
                value={state.getDetails?.RawAddress?.SubBuildingNumber}
                readonly={true}
              />
            </div>
            <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Address Line 4"
                name="txtAddress4"
                id="txtAddress4"
                className="form-control  address hero-input"
                dataId="txtAddress4"
                autoComplete="off"
                value={state.getDetails.line_4}
                readonly={true}
              />
            </div>
            <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Locality"
                name="txtLocality"
                id="txtLocality"
                className="form-control address hero-input"
                dataId="txtLocality"
                autoComplete="off"
                validation={props.validation()}
                value={state.getDetails?.locality}
                readonly={true}
              />
            </div>
            <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="District"
                name="txtDistrict"
                id="txtDistrict"
                className="form-control address hero-input"
                dataId="txtDistrict"
                autoComplete="off"
                validation={props.validation()}
                value={state.getDetails?.RawAddress?.Location?.District}
                readonly={true}
              />
            </div>
            <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Thoroughfare Description"
                name="txtThoroughfareDesc"
                id="txtThoroughfareDesc"
                className="form-control address hero-input"
                dataId="txtThoroughfareDesc"
                autoComplete="off"
                validation={props.validation()}
                value={state.getDetails?.RawAddress?.ThoroughfareDesc}
                readonly={true}
              />
            </div>
            <div className="d-none">
              <AddressTextField
                type="text"
                name="txtOrganisation"
                id="txtOrganisation"
                value={state.getDetails?.RawAddress?.Organisation}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name="txtDepartment"
                id="txtDepartment"
                value={state.getDetails?.RawAddress?.Department}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name="txtAddressKey"
                id="txtAddressKey"
                value={state.getDetails?.RawAddress?.AddressKey}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name="txtOrganisationKey"
                id="txtOrganisationKey"
                value={state.getDetails?.RawAddress?.OrganisationKey}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name="txtPostcodeType"
                id="txtPostcodeType"
                value={state.getDetails?.RawAddress?.PostcodeType}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name="txtDependentThoroughfareName"
                id="txtDependentThoroughfareName"
                value={state.getDetails?.RawAddress?.DependentThoroughfareName}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name="txtDependentThoroughfareDesc"
                id="txtDependentThoroughfareDesc"
                value={state.getDetails?.RawAddress?.DependentThoroughfareDesc}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name="txtDoubleDependentLocality"
                id="txtDoubleDependentLocality"
                value={state.getDetails?.RawAddress?.DoubleDependentLocality}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name="txtDependentLocality"
                id="txtDependentLocality"
                value={state.getDetails?.RawAddress?.DependentLocality}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name="txtDps"
                id="txtDps"
                value={state.getDetails?.RawAddress?.Dps}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name="txtPoBox"
                id="txtPoBox"
                value={state.getDetails?.RawAddress?.PoBox}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name="txtPostalCounty"
                id="txtPostalCounty"
                value={state.getDetails?.RawAddress?.PostalCounty}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name="txtTraditionalCounty"
                id="txtTraditionalCounty"
                value={state.getDetails?.RawAddress?.TraditionalCounty}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name="txtAdministrativeCounty"
                id="txtAdministrativeCounty"
                value={state.getDetails?.RawAddress?.AdministrativeCounty}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name="txtCountryISO2"
                id="txtCountryISO2"
                value={state.getDetails?.RawAddress?.CountryISO2}
                validation={props.validation()}
              />
            </div>
            {Object.keys(state.getDetails).length > 0 ? <HiddenFields /> : null}
          </div>
        </div>
      </div>



    </>
  );
}

export default FollowUpPostcode;