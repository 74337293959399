
import React, {useState} from 'react';
import SelectBox from '../../../UI/SelectBox';
import { DobYear, DobMonth, DobDay } from '../../../../Constants/Constants';
import Button from "../../../UI/Button";
import { slideValidation, dobValidation, calculateAge } from '../../../../Utility/Validations';
import GTMDataLayer from "../../../../Utility/GTMDataLayer";

const DateOfBirth = (props) => {
  const [valids, setValids] = useState('');
  const handleDateOfBirthSubmit = async (e) => {

    const validationArr = ['DobDay', 'DobMonth', 'DobYear'];
    const values = props.getValues();
    const validationCheck = await slideValidation(validationArr, props.trigger);
      if (validationCheck == 'Success') {
        var validDate = dobValidation(values.DobDay, values.DobMonth, values.DobYear);
        var ageCalculate = calculateAge(values.DobDay+ '-' + values.DobMonth + '-' + values.DobYear );
        if(validDate == false){
          setValids('Please enter a valid date');
        }else if(ageCalculate < 20){
          setValids('Sorry – you need to be over 20 to use our services');
        }
        else{
          setValids('');
          props.slideChange(e);
        }
    }
  }
  return (

    <div className="drop-in row">

      <div className="col-lg-4 mb-lg-2 mb-md-3 mb-sm-0">
        <SelectBox
          className="form-select"
          OptionValue={DobDay}
          name="DobDay"
          id="DobDay"
          myRef={props.validation({
            required: "Please Select Date"
          })}
          validationMsg={props.validationMsg.DobDay && props.validationMsg.DobDay.message}
          onChange={() => {
            props.clearErrors(`DobDay`);
          }}
        />

      </div>

      <div className="col-lg-4 mb-lg-2 mb-md-3 mb-sm-0">
        <SelectBox
          className="form-select"
          OptionValue={DobMonth}
          name="DobMonth"
          id="DobMonth"
          myRef={props.validation({
            required: "Please Select Month"
          })}
          validationMsg={props.validationMsg.DobMonth && props.validationMsg.DobMonth.message}
          onChange={() => {
            props.clearErrors(`DobMonth`);
          }}
        />
      </div>

      <div className="col-lg-4 mb-lg-2 mb-md-3 mb-sm-0">
        <SelectBox
          className="form-select"
          OptionValue={DobYear}
          name="DobYear"
          id="DobYear"
          myRef={props.validation({
            required: "Please Select Year"
          })}
          validationMsg={props.validationMsg.DobYear && props.validationMsg.DobYear.message}
          onChange={() => {
            props.clearErrors(`DobYear`);
          }}
        />
      </div>
      {valids != '' && (<span className="error_msg">{valids}</span>)}

      <div className="col-12 row">
        <div className="col-4">
          <Button
            type="button"
            name="back1"
            className="btn-back  mt-4 prev-slide1"
            value="Back"
            onClick={props.previousSlideChange}
            buttonText="< Back"
          />
        </div>
        <div className="col-8 pr-0">
          <Button
            type="button"
            name="slide2"
            className="btn-banner btn-fullwidth mt-4 next-slide3"
            value="Next >"
            onClick={handleDateOfBirthSubmit}
            buttonText="Next >"
          />
        </div>
      </div>
    </div>
  )
};

export default DateOfBirth;
