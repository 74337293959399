import React from 'react'
import review from '../../../../assets/img/TCGL_V_A1/review.4e871c00.svg'
import strTy from '../../../../assets/img/TCGL_V_A1/str-ty.png'
import strHalf from '../../../../assets/img/TCGL_V_A1/str-half.png'
import LogoBasedOnDomain from '../Common/LogoBasedOnDomain'

const CommonHeader = () => {
  return (
    <>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-5 d-flex align-items-center ">
              <a>
                <LogoBasedOnDomain className="header-logo" bg="black" height={45} />
              </a>
            </div>
            <div className="col-lg-6 col-7 justify-content-end d-flex align-items-center">
              <div className="reviews-io-rating">
                <div className="reviews-io-rating--link">
                  <img
                    className="reviews-io-rating--logo"
                    src={review}
                    alt="REVIEWS.io Logo"
                    fetchpriority="hight"
                  />
                </div>
                <div className="reviews-io-stars">
                  <img src={strTy} alt="" fetchpriority="hight" />
                  <img src={strTy} alt="" fetchpriority="hight" />
                  <img src={strTy} alt="" fetchpriority="hight" />
                  <img src={strTy} alt="" fetchpriority="hight" />
                  <img src={strHalf} alt="" fetchpriority="hight" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default CommonHeader