import fBotIcon from "../../../../assets/img/TCGL_V_A1/f-bot-icon.png";

const VerificationMessage = () => {
  return (
    <div className="col-12 form-caption d-flex align-items-center">
      <img src={fBotIcon} alt="" />
      <p> Your personal information is vital for verifying your identity and finance agreement, allowing us to assess your PCP claim accurately.</p>
    </div>
  );
};

export default VerificationMessage;
