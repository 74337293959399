import React, { useEffect } from 'react'
import Footer from '../../Includes/Layouts/TCGL_V_A1/Footer'
// import Logo_blue from "../../../assets/img/TCGL_V_A1/Logo_blue.png"
import "../../../assets/css/TCGL_V_A1/thankyou.scss"
import review from "../../../assets/img/TCGL_V_A1/review.4e871c00.svg"
import str from "../../../assets/img/TCGL_V_A1/str-ty.png"
import thankyou from "../../../assets/img/TCGL_V_A1/thankyou.png"
import strhalf from "../../../assets/img/TCGL_V_A1/str-half.png"
import LogoBasedOnDomain from '../../Includes/Layouts/Common/LogoBasedOnDomain'

const TCGL_V_A1_Thankyou = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [])
    localStorage.clear();
    return (
        <div>
            <div className='TCGL_V_A1'>
                <div className="supagebg1">
                    <header>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-5 d-flex align-items-center ">
                                    {/* <a href="index.html">
                                        <img
                                            className="header-logo"
                                            src={Logo_blue}
                                            height={35}
                                        />
                                    </a> */}
                                    <LogoBasedOnDomain bg="black" height={35}/>
                                </div>
                                <div className="col-lg-6 col-7 justify-content-end d-flex  align-items-center">
                                    <div className="reviews-io-rating">
                                        <div className="reviews-io-rating--link">
                                            <img
                                                className="reviews-io-rating--logo"
                                                src={review}
                                                alt="REVIEWS.io Logo"
                                                fetchpriority="hight"
                                            />
                                        </div>
                                        <div className="reviews-io-stars">
                                            <img src={str} alt="" fetchpriority="hight" />
                                            <img src={str} alt="" fetchpriority="hight" />
                                            <img src={str} alt="" fetchpriority="hight" />
                                            <img src={str} alt="" fetchpriority="hight" />
                                            <img src={strhalf} alt="" fetchpriority="hight" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <section className="banner banner-subpage nobg thankyou">
                        <div className="container">
                            <div className="row">
                                <div className="drop-in col-12">
                                    <img src={thankyou} height={120} alt="" />
                                    <h2>Your Registration is Complete</h2>
                                    <h3>
                                        We will be in touch about your potential claims
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* End Banner */}
                    <div className="btm_sec">
                        <Footer />

                    </div>
                </div>
                {/* id upload popup */}
                <div
                    className="modal fade"
                    id="uploadidentity"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex={-1}
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-md modal-dialog-centered">
                        <div className="modal-content carregform-modal">
                            <div className="modal-body">
                                <div id="uploarea" style={{}}>
                                    <div
                                        className="fw-bold mb-2 fs-5 backupload"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        {" "}
                                        <i className="bi bi-arrow-left" /> Back
                                    </div>
                                    <div className="up-wrap p-1">
                                        <div className="uploaded" style={{ display: "none" }}>
                                            <img
                                                className="img-fluid"
                                                src="dist/img/upload-demo.jpg"
                                                alt=""
                                            />
                                        </div>
                                        <div className="uploadbox">
                                            <div>
                                                <div>
                                                    <i className="bi bi-cloud-upload" />
                                                </div>
                                                <div className="upload-text"> Upload image </div>
                                            </div>
                                            <div
                                                className="text-center imgloader "
                                                style={{ display: "none" }}
                                            >
                                                <div
                                                    className="progress"
                                                    role="progressbar"
                                                    aria-label="Animated striped example"
                                                    aria-valuenow={75}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                >
                                                    <div
                                                        className="progress-bar progress-bar-striped progress-bar-animated bg-warning"
                                                        style={{ width: "100%" }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3 upactionbtn">
                                        <div className="col-6">
                                            <button
                                                type="button"
                                                className="upl-btn img-uploadbtn w-100 fw-bold"
                                            >
                                                {" "}
                                                <i className="bi bi-camera" /> Take Photo
                                                <input type="file" />
                                            </button>
                                        </div>
                                        <div className="col-6">
                                            <button className="upl-btn  img-uploadbtn w-100 fw-bold  ">
                                                <i className="bi bi-image" /> Upload image
                                                <input type="file" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row retake mt-3" style={{ display: "none" }}>
                                        <div className="col-12 col-lg-6 ">
                                            <button className="upl-btn w-100 fw-bold ">
                                                <i className="bi bi-arrow-repeat" />
                                                &nbsp;&nbsp; Retake
                                            </button>
                                        </div>
                                        <div
                                            className="mt-lg-0  mt-3 col-12 col-lg-6  gonext"
                                            style={{ display: "none" }}
                                        >
                                            <button
                                                id="next2"
                                                className=" idButton  green_color_but w-100 mb-3 py-3"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3 text-center">
                                    <button
                                        type="button"
                                        className="regButton  w-100 green_color_but mt-3"
                                        id=""
                                        value="Next"
                                        name=""
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        {" "}
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default TCGL_V_A1_Thankyou