import claimfinder from "../../../../assets/img/TCGL_V_A1/Cars.mp4";
import thepcplist from "../../../../assets/img/TCGL_V_A1/thepcplist1.mp4";
import therefundsnow from "../../../../assets/img/TCGL_V_A1/therefundsnow1.mp4";
const VideoBasedOnDomain = (props) => {
    const { className, bg, style = null, verticalAlign = null, width = null, height = null } = props;
	const domainName = window.location.hostname;
	let video;
    if (domainName === 'localhost' || domainName === 'dev.claimfinder.co.uk'
        || domainName === 'pre.claimfinder.co.uk' || domainName === 'claimfinder.co.uk') {
        video = claimfinder;
    } else if (domainName === 'dev.refundsnow.co.uk' || domainName === 'pre.refundsnow.co.uk'
        || domainName === 'refundsnow.co.uk') {
        video = therefundsnow;
    } else if (domainName === 'dev.thepcplist.co.uk' || domainName === 'pre.thepcplist.co.uk'
        || domainName === 'thepcplist.co.uk') {
        video = thepcplist;
    } else {
        video = claimfinder;
    }

	return (
        <video
        className={className}
        style={style}
        width={width}
        height={height}
        autoPlay 
        muted 
        loop
        playsInline
      >
        <source src={video} type="video/mp4" />
      </video>
	)
}

export default VideoBasedOnDomain;