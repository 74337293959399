import React from "react";

const AddressEditSelectBox = ({
  className,
  OptionValue,
  selectedValue,
  onChange,
  name,
  id,
  myRef,
  validationMsg,
}) => {
  const OptionData = OptionValue.map((addressData, index) => {
    return (
      <option value={addressData.id} key={index}  selected={addressData.id == selectedValue.id}>
        {addressData.address}
      </option>
    );
   
  });
  return (
    <>
      <select
        className={className}
        name={name}
        ref={myRef}
        onChange={onChange}
        id={id}
      >
        {OptionData}
      </select>
      {validationMsg && (
        <>
          <span className="error_msg">{validationMsg}</span>
        </>
      )}
    </>
  );
};

export default AddressEditSelectBox;
