import { useEffect, useState } from "react";
import security from "../../../../assets/img/TCGL_V_A1/security-lock.svg";
import "../../../../assets/css/TCGL_V_A1/loader.scss";
import { useHistory } from "react-router-dom";
import LogoBasedOnDomain from "../../../Includes/Layouts/Common/LogoBasedOnDomain";
import { useFollowUpPendingDetails } from "../../../../Hooks/useFollowUpPendingDetails";
import { getQueryString } from "../../../../Utility/QueryString";
import { handleCreditCheckFailureFollowup } from '../../../../Utility/Validations';
import GetLenders from "../../../../Utility/GetLenders";

const FollowupLoader = () => {
 
  const urlParams = new URLSearchParams(window.location.search);
  let current_page = urlParams.get('current_page') ?? localStorage.getItem("current_page");
  const uuid = urlParams.get('uuid') || '';
  const splitName = urlParams.get('splitName') || '';
  const history = useHistory();
  const queryString = getQueryString();
  const { followUpPendingDetails } = useFollowUpPendingDetails();
  const [pending,setPending] = useState('');
  const atp_sub2 = localStorage.getItem("atp_sub2") ??  new URLSearchParams(queryString).get("atp_sub2");
  const [pendingLocalData,setPendingLocalData] = useState('');
  const atp_sub6 = localStorage.getItem("atp_sub6") ??  new URLSearchParams(queryString).get("atp_sub6");
  const { getLenderList } = GetLenders();

  useEffect(() => {
    const fetchPendingDetails = async () => {
      let pendingArrayData = null;
      const localData = JSON.parse(localStorage.getItem('pendingLocalData')) ?? {};
      setPendingLocalData(localData);
      if(current_page == "followup-credit-analysis-v2" || current_page == "followup-lenders-v2" || current_page =="followup-edit-credit-check"){
        pendingArrayData = await fetchPendingData();
        localStorage.setItem('pendingArrayData', JSON.stringify(pendingArrayData));
      }else{
         pendingArrayData = JSON.parse(localStorage.getItem('pendingArrayData')) ?? {};
        //if (!Object.keys(pendingArrayData).length) {
        if(current_page !='followup-id-upload-v2'){
          pendingArrayData = await fetchPendingData();
          localStorage.setItem('pendingArrayData', JSON.stringify(pendingArrayData));
        }
       // }
      }
      setPending(pendingArrayData);
    };

    fetchPendingDetails();

  }, []);


  const fetchPendingData = async () => {
      const response = await followUpPendingDetails(queryString, null);
      if (response && response.data && response.data.response) {
        const followupDetails = response.data.response.followup_data;
        localStorage.setItem("followup_data", JSON.stringify(followupDetails));
        localStorage.setItem('user_info', JSON.stringify(response.data.response.user_info));
        const allData = response.data.response.pending_details;
        const pending_details = {
          is_user_complete: allData.is_user_complete,
          is_user_sign: allData.is_user_sign,
          is_credit_check: allData.is_credit_check,
          is_bank_complete: allData.is_bank_complete,
          is_doc_complete: allData.is_doc_complete,
          is_credit_check_failed: allData.is_credit_check_failed
        };
       
        if (
          allData.is_credit_check === 1 &&
          allData.is_credit_check_failed === 1 &&
          allData.failed_response &&
          Object.keys(allData.failed_response).length > 0
        ) {
          const creditCheckApiResponseDetails = {
            vendor: allData?.failed_response?.vendor || "",
            status: allData?.failed_response?.failed_error_code || "",
            message: allData?.failed_response?.failed_error_message || "",
          };
          localStorage.setItem('creditCheckApiResponse', JSON.stringify(creditCheckApiResponseDetails));
          var userBanks = response.data.response.user_info.user_banks;
          const bankList = await getLenderList();
          const getUserBanks = bankList.filter((bank) =>
            userBanks.includes(bank.id)
          );
          localStorage.setItem('userBankList', JSON.stringify(getUserBanks));
        }
        return pending_details;
      }
  };

  // const fetchPendingUserData = async () => {
  //   const response = await followUpPendingDetails(queryString, null);
  //   if (response && response.data && response.data.response) {
  //     const userInfo = response.data.response.user_info;;
  //     const monthNames = [
  //       "January", "February", "March", "April", "May", "June",
  //       "July", "August", "September", "October", "November", "December"
  //     ];
  //     const dobMonth = userInfo.DobMonth;
  //     const monthNumber = parseInt(dobMonth, 10);
  //     const monthName = monthNumber > 0 && monthNumber <= 12 ? monthNames[monthNumber - 1] : "Invalid Month";

  //     const userData = {
  //       lstSalutation: userInfo.salutation,
  //       txtFName: userInfo.first_name,
  //       txtLName: userInfo.last_name,
  //       DobDay: userInfo.DobDay,
  //       DobMonth: monthName,
  //       DobYear: userInfo.DobYear,
  //       txtPostCode: userInfo.post_code,
  //       address1: userInfo.address1,
  //       txtHouseNumber: userInfo.house_number,
  //       txtAddress2: userInfo.address2,
  //       txtAddress3: userInfo.address3,
  //       txtCounty: userInfo.county,
  //       txtTown: userInfo.town,
  //       txtCountry: userInfo.country,
  //       txtDistrict: userInfo.district,
  //       txtPhone: userInfo.telephone
  //     };
  //     localStorage.setItem("formData", JSON.stringify(userData));
  //   }
  // };
  useEffect(()=> {
    const timer = setInterval(() => {
        pushPage();
    }, 3000);
     return() => clearInterval(timer);
  },[pending]);
  const pushPage = () => {
    if (pending) {
      let nextPath = "";
      let validCreditCheck = handleCreditCheckFailureFollowup();
        switch (true) {
          case pending['is_user_sign'] == 0 && pendingLocalData['is_user_sign'] == 0:
            nextPath = "followup-signature-v2"
            break;
          case pending['is_credit_check'] == 0 && pending['is_credit_check_failed'] == 0:
            nextPath = "followup-credit-analysis-v2"
            break;
          case pending['is_bank_complete'] == 0 :
            nextPath = "followup-lenders-v2"
            break;
          case pending['is_credit_check_failed'] == 1 && validCreditCheck === 1:
            nextPath = "followup-edit-details"
            break;
          case pending['is_doc_complete'] == 0 :
            nextPath = "followup-id-upload-v2"
            break;
          default:
            nextPath = "followup-thankyou"
            break;
        }
      history.push("/" + nextPath + "?uuid=" + uuid + "&atp_sub2=" + atp_sub2 + "&atp_sub6=" +
        atp_sub6 + "&splitName=" + splitName);
    }
  };
  return (
<>
  <div className="bg_preldr">
    <div className="container" style={{ marginTop: "-150px" }}>
      <div>
        <div className="brand">
          <div className="logo pt-3 text-center">
            <a>
              <LogoBasedOnDomain  className="header-logo"  bg="white"  height={50}/>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="preloader">
      <div className="box">
        <div className="logoloader">
          <img height={40} src={security} alt="" />
        </div>
      </div>
      <div className="box" />
      <div className="box" />
      <div className="box" />
      <div className="box" />
    </div>
    <div className="loadtext">
      Please wait while we save your information...
    </div>
  </div>
</>


  );
};

export default FollowupLoader;