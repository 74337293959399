import upload_demo from '../../../../assets/img/TCGL_V_A1/upload-demo.jpg';
import Button from '../../../UI/Button';

const IdUploadModal = ({
  isModalVisible,
  handleCloseModal,
  isFileSelected,
  userfile,
  progressbar,
  handleFileChange,
  retakeClick,
  handleSubmit,
  btnLoader,
  errorMessageUpload,
  errorMessage,
  handleNextClickModal,
  idType,
  isFormSubmitted
}) => {

  const handleButtonClick = (e) => {
    if (e.target.name == 'photo') {
      document.getElementById(`${idType}_cam`).click();
    }
    if (e.target.name == 'image') {
      document.getElementById(`${idType}_doc`).click();
    }
  };
  return (
    <div className={`modal bg-blk fade${isModalVisible.show ? ' show d-block' : ''}`} id="uploadidentity" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog modal-md modal-dialog-centered">
        <div className="modal-content carregform-modal">
          <div className="modal-body">
            <div id="uploarea">
              <div className="fw-bold mb-2 fs-5 backupload" data-bs-dismiss="modal" onClick={handleCloseModal} aria-label="Close">
                <i className="bi bi-arrow-left"></i> Back
              </div>
              <div className="up-wrap p-1">
                {isFileSelected ? (
                  <div className="uploaded">
                    <img className="img-fluid" style={{ maxWidth: '100%', height: '190px' }} src={userfile.type === 'application/pdf' ? upload_demo : userfile.preview} alt={userfile.title} />
                    {userfile.type === 'application/pdf' ? (<p>{userfile.title}</p>) : ('')}
                  </div>
                ) : (
                  <div className="uploadbox">
                    <div>
                      <div><i className="bi bi-cloud-upload"></i></div>
                      <div className="upload-text"> Upload image </div>
                    </div>
                    {progressbar && (
                      <div className="text-center imgloader">
                        <div className="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                          <div className="progress-bar progress-bar-striped progress-bar-animated bg-warning" style={{ width: "100%" }}></div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>

              {!isFileSelected ? (
                <div className="row mt-3 upactionbtn">
                  <div className="col-6">
                    <Button
                      className="upl-btn img-uploadbtn w-100 fw-bold"
                      id=""
                      type="button"
                      onClick={(e) => handleButtonClick(e)}
                      name="photo"
                      buttonText={
                        <>
                          <span style={{ pointerEvents: 'none' }}>
                            <i className="bi bi-camera-fill me-2 ms-0" /> Take Photo
                            <input
                              type="file"
                              className="iput-file d-none"
                              accept=".jpg,.jpeg,.png,.pdf"
                              id={`${idType}_cam`}
                              name={`${idType}_cam`}
                              onChange={handleFileChange}
                            />
                          </span>
                        </>
                      }
                    />
                  </div>
                  <div className="col-6">
                    <Button
                      className="upl-btn img-uploadbtn w-100 fw-bold"
                      id=""
                      type="button"
                      onClick={(e) => handleButtonClick(e)}
                      name="photo"
                      buttonText={
                        <>
                          <span style={{ pointerEvents: 'none' }}>
                            <i className="bi bi-image" /> Upload image
                            <input
                              type="file"
                              className="upl-btn img-uploadbtn w-100 fw-bold"
                              accept=".jpg,.jpeg,.png,.pdf"
                              id={`${idType}_doc`}
                              name={`${idType}_doc`}
                              onChange={handleFileChange}
                            />
                          </span>
                        </>
                      }
                    />
                  </div>
                  {(!isFileSelected && errorMessage) && (
                    <div
                      className="validation-error mt-3 text-center"
                      style={{
                        background: "red",
                        borderRadius: "4px",
                        width: "100%",
                        marginLeft: "0px",
                        marginTop: "5px",
                        padding: "5px",
                        color: "white",
                      }}
                    >
                      {errorMessage}
                    </div>
                  )}
                </div>
              ) : (
                <div className="row retake mt-3">
                  <div className="col-12 col-lg-6">
                    <Button
                      className="upl-btn w-100 fw-bold"
                      onClick={(e) => retakeClick(e)}
                      name="image"
                      buttonText={
                        <>
                          <i className="bi bi-arrow-repeat" />
                          &nbsp; Retake
                        </>
                      }
                    />
                  </div>
                  <div className="mt-lg-0  mt-3 col-12 col-lg-6 gonext">
                    <Button
                      type="button"
                      id="fileuplSubmit"
                      className="idButton  green_color_but w-100 mb-3 py-3"
                      onClick={(e) => (!btnLoader ? handleSubmit(e) : null)}
                      buttonText={
                        <>
                          <span className="">
                            {btnLoader ? (
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : (
                              "Submit"
                            )}
                          </span>
                        </>
                      }
                    />
                  </div>
                  {errorMessageUpload && (
                    <div className="validation-error mt-3 text-center" style={{ background: "red", borderRadius: "4px", width: "100%", padding: "5px", color: "white" }}>
                      {errorMessageUpload}
                    </div>
                  )}
                  {(!isFormSubmitted && errorMessage) && (
                    <div
                      className="validation-error mt-3 text-center"
                      style={{
                        background: "red",
                        borderRadius: "4px",
                        width: "100%",
                        marginLeft: "0px",
                        marginTop: "5px",
                        padding: "5px",
                        color: "white",
                      }}
                    >
                      {errorMessage}
                    </div>
                  )}
                  {!isFileSelected && (
                    <div
                      className="validation-error mt-3 text-center"
                      style={{
                        background: "red",
                        borderRadius: "4px",
                        width: "100%",
                        marginLeft: "0px",
                        marginTop: "5px",
                        padding: "5px",
                        color: "white",
                      }}
                    >
                      {errorMessage}
                    </div>
                  )}
                </div>
              )}
              {/* </div> */}
            </div>
            {!isFileSelected && (
              <div className="mb-3 text-center">
                <Button
                  className="w-100 green_color_but mt-3"
                  type="button"
                  value="Next"
                  onClick={handleNextClickModal}
                  buttonText="Continue         >"
                />
              </div>)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IdUploadModal;
