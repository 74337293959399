import React from 'react'
import LogoBasedOnDomain from '../Common/LogoBasedOnDomain';
import { getBrandName } from '../Common/BrandBasedOnDomain';

const Footer = ({ showMoreContent }) => {
	const brandName = getBrandName();
	return (
		<>
			<footer className="_29ahV">
				<div className="container-md">
					<div className="row justify-content-between g-3">
						<div className="col-12 col-md-3 col-sm-12 col-xl-3 text-center">
							<LogoBasedOnDomain className="img-fluid" bg="white" height={142} width={836} />
						</div>
						<div className="col-12 col-md-9 col-sm-12 col-xl-9">
							<p className="fot_cont m-0 text-lg-start text-center">
								Copyright ©2024 {brandName}. All rights reserved
							</p>
							<p className="fot_cont m-0 text-lg-start text-center">
								All rights reserved. {brandName}is a trading style of The Claims
								Guys Legal. The Claims Guys Legal Limited is authorised and
								regulated by the Solicitors Regulation Authority (SRA Number:
								642517).
							</p>
							<p className="fot_cont mb-0 text-lg-start text-center">
										Source:{" "}
										<a href="https://cardealermagazine.co.uk/publish/car-finance-scandal-could-cost-lenders-30bn-warns-leading-ratings-agency/310122" target='_blank'>
											https://cardealermagazine.co.uk/publish/car-finance-scandal-could-cost-lenders-30bn-warns-leading-ratings-agency/310122
										</a>
									</p>
							<p className="fot_cont mb-0 text-lg-start text-center">
								*The Financial Conduct Authority, confirmed in their publication
								Our work on motor finance – final finding – March 2019 that on a
								typical motor finance agreement of £10,000, a Discretionary
								Commission Arrangement typically resulted in consumers being
								overcharged £1,100 over a four-year agreement. See:
								<a
									href="https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf"
									target="_blank"
								>
									https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf
								</a>
								s2.15, Pg9
							</p>
							{showMoreContent &&
								<>
									<p className="fot_cont mb-0 text-lg-start text-center">
									<sup>#</sup> Free Agreement Check refers only to the credit agreement review completed to identify your car finance agreements. If you instruct us to proceed with a claim(s) on your behalf, by providing your signature, our Success Fee will apply
									</p>
								</>
							}
							<p className="fot_cont text-lg-start text-center mb-4">
								<a
									href="https://theclaimsguyslegal.com/privacy-policy/"
									target="_blank"
								>
									Privacy Policy
								</a>
								&nbsp;
							</p>
						</div>
					</div>
				</div>
				<div className="container-md">
					<div className="row">
						<div className="col-12 text-center fot_cont pt-3 fw-bold br-top">
							Copyright ©2024 {brandName}.
						</div>
					</div>
				</div>
			</footer>
		</>
	)
}

export default Footer