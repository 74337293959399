import React, { lazy } from 'react';
const TCG_L_A = lazy(() => import('./Components/Pages/Splits/TCG_L_A'));
const TCG_L_B = lazy(() => import('./Components/Pages/Splits/TCG_L_B'));
const TCG_L_C = lazy(() => import('./Components/Pages/Splits/TCG_L_C'));
const TCGL_V_A1 = lazy(() => import('./Components/Pages/Splits/TCGL_V_A1'));
const TCGL_V_A2 = lazy(() => import('./Components/Pages/Splits/TCGL_V_A2'));
const TCGL_V_A3 = lazy(() => import('./Components/Pages/Splits/TCGL_V_A3'));

const AppSplitList = (props) => {

    const SplitListMap = {
        'pcp/TCG_L_A ':TCG_L_A,
        'pcp/TCG_L_B': TCG_L_B,
        'pcp/TCG_L_C': TCG_L_C,
        'TCGL_V_A1'  : TCGL_V_A1,
        'TCGL_V_A2'  : TCGL_V_A2,
        'TCGL_V_A3'  : TCGL_V_A3
    }
    const SplitComponent = SplitListMap[props.splitName];
    return (
        <>
            {SplitComponent && <SplitComponent />}
        </>
    )

}

export default AppSplitList;