import { useEffect } from "react";
import security from "../.././../../assets/img/TCGL_V_A1/security-lock.svg";
import "../../../../assets/css/TCGL_V_A1/loader.scss";
import { useHistory } from "react-router-dom";
import LogoBasedOnDomain from "../Common/LogoBasedOnDomain";
import GetVisitorsParams from "../../../../Utility/GetVisitorsParams";

const Loader = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const nextPage = urlParams.get('nextPage') || '';
  // const uuid = urlParams.get('uuid') || '';
  // const splitName = urlParams.get('splitName') || '';
  const splitName = urlParams.get('splitName') || localStorage.getItem('split_name') || '';
	const uuid = urlParams.get('uuid') || localStorage.getItem('uuid') || '';
  const history = useHistory();
  useEffect(()=> {
    const timer = setInterval(() => {
      history.push("/" + nextPage + "?uuid=" + uuid + "&splitName=" + splitName);
    }, 3000);
    return () => clearInterval(timer);
  },[splitName]);
  return (
<>
<GetVisitorsParams />
  <div className="bg_preldr">
    <div className="container" style={{ marginTop: "-150px" }}>
      <div>
        <div className="brand">
          <div className="logo pt-3 text-center">
            <a>
              <LogoBasedOnDomain  className="header-logo"  bg="white"  height={50}/>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="preloader">
      <div className="box">
        <div className="logoloader">
          <img height={40} src={security} alt="" />
        </div>
      </div>
      <div className="box" />
      <div className="box" />
      <div className="box" />
      <div className="box" />
    </div>
    <div className="loadtext">
      Please wait while we save your information...
    </div>
  </div>
</>

   
  );
};

export default Loader;