import React from 'react';
import { Carousel } from 'react-bootstrap';

const ReviewSliderCarousel = (props) => {
  // Initial review data
  const initialReviews = [
    {
      stars: 5,
      comment: 'Thanks 😊',
      name: 'Rastislav from reviews.io',
    },
    {
      stars: 5,
      comment:
        'The claims firm is brilliant. I was able to start my claim in 60 seconds! They kept me updated each step of the way and were super easy to contact throughout. Highly recommend checking to see if you have a claim!',
      name: 'Harry S from reviews.io',
    },
    {
      stars: 5,
      comment: 'Straightforward and easy to do!',
      name: 'Edwin from reviews.io',
    },
  ];

  return (
    <div className="review-slider">
      <div className="reviews-io-logo-wrapper">
        <img className="reviews-io-logo" src={props.review_img} alt="REVIEWS.io Logo" />
      </div>
      <Carousel>
        {initialReviews.map((review, index) => (
          <Carousel.Item key={index}>
            <div className="review-io-item">
              <div className="review-io-item--rating">
                <div className="reviews-io-stars">
                  {Array(review.stars)
                    .fill()
                    .map((_, i) => (
                      <i key={i} className="bi bi-star-fill" />
                    ))}
                </div>
              </div>
              <p className="review-io-item--comment">{review.comment}</p>
              <p className="review-io-item--name">{review.name}</p>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default ReviewSliderCarousel;
