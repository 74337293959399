import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import excl_mark from "../../../../assets/img/TCGL_V_A1/exclamation-mark.png";

const LenderSelectModal = ({ showstatus, hidepopup }) => {
  return (
    <>
      <Modal show={showstatus} onHide={hidepopup} size="md" aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body>
          <div className="modal-body text-center">
            <img src={excl_mark} alt="" />
            <div className='modal-font'>To move forward with your claim, please select at least one lender from the list to help us understand which loans you’d like us to review as part of your claim process</div>
            <p className='modal-p-tag'>You can choose multiple lenders if needed</p>
            <Button className='modal-btn' variant="secondary" onClick={hidepopup}>
              {"< "}Back to Lender List
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default LenderSelectModal;