import React, { useEffect, useReducer, useState, useContext } from "react";
import "../../../assets/css/TCGL_V_A1/personal-details.scss";
import "../../../assets/css/TCGL_V_A1/custom.scss";
import { useForm } from "react-hook-form";
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import { getQueryString } from '../../../Utility/QueryString';
import { useHistory } from "react-router-dom";
import * as EnvConstants from "../../../Constants/EnvConstants";
import GetVisitorsParams from "../../../Utility/GetVisitorsParams";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import personalIcon from "../../../assets/img/TCGL_V_A1/personal-icon.png";
import dobIcon from "../../../assets/img/TCGL_V_A1/dob-icon.png";
import addressIcon from "../../../assets/img/TCGL_V_A1/address-icon.png";
import contaIcon from "../../../assets/img/TCGL_V_A1/conta-icon.png";
import ProgressBar from "../../Includes/Layouts/TCGL_V_A1/ProgressBar";
import SubHeading from "../../Includes/Layouts/TCGL_V_A1/SubHeading";
import NameWithSalutation from "../../Includes/Layouts/TCGL_V_A1/NameWithSalutation";
import DateOfBirth from "../../Includes/Layouts/TCGL_V_A1/DateOfBirth";
import PostCode from "../../Includes/Layouts/TCGL_V_A1/PostCode";
import ContactDetails from "../../Includes/Layouts/TCGL_V_A1/ContactDetails";
import VerificationMessage from "../../Includes/Layouts/TCGL_V_A1/VerificationMessage";
import Footer from "../../Includes/Layouts/TCGL_V_A1/Footer";
import CommonHeader from "../../Includes/Layouts/Header/CommonHeader";
import CommonIcons from "../../Includes/Layouts/TCGL_V_A1/CommonIcons";
import { useCreditCheckCheckboard } from "../../../Hooks/useCreditCheckCheckboard";
import { UUID } from "../../../Utility/UUID";
import GTMDataLayer from "../../../Utility/GTMDataLayer";

const initialState = {
	showSlide1: "show",
	showSlide2: "hide",
	showSlide3: "hide",
	showSlide4: "hide",
};
const FormReducer = (state, action) => {
	switch (action.type) {
		case "showSlide": {
			if (action.payload.clickedSlide.slide === "slide1") {
				return {
					...state,
					showSlide1: "hide",
					showSlide2: "show",
				};
			} else if (action.payload.clickedSlide.slide === "slide2") {
				return {
					...state,
					showSlide2: "hide",
					showSlide3: "show",
				};
			} else if (action.payload.clickedSlide.slide === "slide3") {
				return {
					...state,
					showSlide3: "hide",
					showSlide4: "show",
				};
			} else {
				return {
					...state,
				};
			}
		}
		case 'previousSlides': {
			if (action.payload.previousSlide.slide == 'back1') {
				return {
					...state,
					showSlide1: "show",
					showSlide2: "hide"
				}
			} else if (action.payload.previousSlide.slide == 'back2') {
				return {
					...state,
					showSlide2: "show",
					showSlide3: "hide"
				}
			} else if (action.payload.previousSlide.slide == 'back3') {
				return {
					...state,
					showSlide3: "show",
					showSlide4: "hide"
				}
			}
		}
		default:
			return state;
	}
};

const TCGL_V_A3_PersonalDetails = () => {

	const { register, errors, trigger, setError, clearErrors, getValues, setValue } =
		useForm({
			mode: "onBlur",
			reValidateMode: "onChange",
			defaultValues: {},
			resolver: undefined,
			context: undefined,
			criteriaMode: "firstError",
			shouldFocusError: true,
			shouldUnregister: true,
		});
	const [state, dispatch] = useReducer(FormReducer, initialState);
	const [clickedSlide, setClickedSlide] = useState([]);
	const [previousSlide, setPreviousSlide] = useState([]);
	const [contentIcon, setContentIcon] = useState(personalIcon);
	const [heading, setHeading] = useState({ text: "Legal Name", subHeading: "Please enter your full name as it appears on your finance agreements", slide: "" });
	const [progressBarWidth, setProgressBarWidth] = useState('25');
	const { visitorParameters } = useContext(VisitorParamsContext);
	const { saveDataIngestion, isLoading } = useDataIngestion();
	const { creditCheckCheckboard } = useCreditCheckCheckboard()
	const history = useHistory();
	const queryString = getQueryString();
	const field = "pid";
	const [postcodeval, setPostcodeval] = useState(null);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	// const splitName = new URLSearchParams(queryString).get('splitName');
	const urlParams = new URLSearchParams(window.location.search);
	const splitName = urlParams.get('splitName') ? urlParams.get('splitName') : localStorage.getItem('split_name');
	const nextPage = 'TCGL_V_A3/lenders';
	const {fetchUuid} = UUID()
	const uuid = fetchUuid()
	const slideChange = (e) => {
		setClickedSlide({ slide: e.target.name });
		contentUpdate(e.target.name);
		if (e.target.name == "slide4") {
			formSubmit();
		}
		window.scrollTo({
			top: 0,
			behavior: 'smooth', // Enables smooth scrolling
		});

		// DataLayer
		const slideNameMap = {
			"slide1": "legal name",
			"slide2": "dob",
			"slide3": "postcode",
			"slide4": "communication details"
		};
		const question = slideNameMap[e.target.name];
		if (question) {
			GTMDataLayer({
				clickEvent: 'slideclick',
				question,
				answer: '',
			});
		}
	}
	const previousSlideChange = (e) => {
		setPreviousSlide({ slide: e.target.name });
		contentUpdate(e.target.name);
	};
	useEffect(() => {
		dispatch({ type: "showSlide", payload: { clickedSlide } })
	}, [clickedSlide]);
	useEffect(() => {
		if (previousSlide) {
			dispatch({ type: 'previousSlides', payload: { previousSlide } });
		}
	}, [previousSlide]);
	const contentUpdate = (name) => {
		if (name == "slide1" || name == "back2") {
			setProgressBarWidth('50');
			setContentIcon(dobIcon);
			setHeading({ text: "Date of Birth", subHeading: "To confirm your identity", slide: name });
		} else if (name == "slide2" || name == "back3") {
			setProgressBarWidth('75');
			setContentIcon(addressIcon);
			setHeading({ text: "Current Address", subHeading: "To help identify your car finance agreements", slide: name });
		} else if (name == "slide3") {
			setProgressBarWidth('75');
			setContentIcon(contaIcon);
			setHeading({ text: "Help us keep you updated to communicate with you about your potential refund amount", slide: name });
		} else if (name == "back1") {
			setProgressBarWidth('25');
			setContentIcon(personalIcon);
			setHeading({ text: "Legal Name", subHeading: "Please enter your full name as it appears on your finance agreements", slide: "" });
		}
	}
	const formSubmit = async () => {
		const values = getValues();
		// const formData = values;
		const formData = { ...values, txtPostCode: postcodeval };
		formData.page_name = splitName;
		formData.check_status = "checked";
		visitorParameters.visitor_parameters.uuid = uuid;
		if (
			visitorParameters.data !== null ||
			visitorParameters.data !== undefined
		) {
			console.log(splitName,66);
			localStorage.setItem('formData', JSON.stringify(formData));
			const formSUbmitResult = await saveDataIngestion(
				visitorParameters.visitor_parameters,
				formData,
				'split_form_submit',
				splitName,
				queryString,
				visitorParameters.data
			);
    //   const res = creditCheckCheckboard(visitorParameters.visitor_parameters, formData, 'credit_check_address');
			if (formSUbmitResult.data.status === "Success") {
				setIsButtonDisabled(false);
				if (queryString != null) {
					if (queryString.indexOf("&" + field + "=") !== -1) {
						if (EnvConstants.AppConversionPixel === "true") {
							// history.push(
							//   "/fbpixel?"+ queryString + "&next_page=loader" + "&nextPage=" + nextPage
							// );
							history.push(
								"/loader?" + queryString + "&next_page=loader" + "&nextPage=" + nextPage
							);
						} else {
							window.scrollTo(0, 0);
							history.push(
								"/loader" +
								"?uuid=" +
								visitorParameters.visitor_parameters.uuid +
								"&splitName=" +
								splitName + "&nextPage=" + nextPage
							);
						}
					} else {
						window.scrollTo(0, 0);
						history.push(
							"/loader" +
							"?uuid=" +
							visitorParameters.visitor_parameters.uuid +
							"&splitName=" +
							splitName + "&nextPage=" + nextPage
						);
					}
				} else {
					window.scrollTo(0, 0);
					history.push(
						"/loader" +
						"?uuid=" +
						visitorParameters.visitor_parameters.uuid +
						"&splitName=" +
						splitName + "&nextPage=" + nextPage
					);
				}
			}else if(formSUbmitResult.data.duplicate && formSUbmitResult.data.duplicate == true)
			{
				history.push(
					"/duplicate" +
					"?uuid=" +
					uuid +
					"&splitName=" +
					splitName +
					"&type=" + formSUbmitResult.data.type +
					"&key=" + formSUbmitResult.data.key
				);
			}else if(formSUbmitResult.data.duplicate == false)
				{
					visitorParameters.visitor_parameters.uuid = formSUbmitResult.data.uuid
					
					// if (queryString.indexOf("&" + field + "=") !== -1 && EnvConstants.AppConversionPixel === "true") {
					// history.push("/duplicate-fbpixel?" + queryString + "&splitName=" + splitName);
					// }else{
					history.push(
							"/followupv2" +
							"?uuid=" +
							formSUbmitResult.data.uuid +
							"&splitName=" +
							splitName + 
							"&atp_sub6="+ "FLP_DUP" 
						);
					// }
				}
		}
	};

	useEffect(() => {
		  const handlePopState = (event) => {
        localStorage.clear();
        history.push("/" + splitName + "?uuid=" + uuid);
		  };
		  window.addEventListener("popstate", handlePopState);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
	}, [])

	return (
		<>
			<GetVisitorsParams />
			<div className='PersonalDetails TCGL_V_A1'>
				<CommonHeader />
				<section className="banner banner-subpage">
					<div className="container">
						<div className="row">
							<div className="col-lg-6 col-md-12 col-sm-12 col-12  align-self-center order-lg-1 order-2 offset-lg-3  ">
								<form>
									<ProgressBar progressBarWidth={progressBarWidth} />
									<SubHeading contentIcon={contentIcon} heading={heading} />


									<div className={`slide1 ${state.showSlide1}`}>
										<NameWithSalutation
											slideChange={slideChange}
											validation={register}
											validationMsg={errors}
											trigger={trigger}
											clearErrors={clearErrors}
											getValues={getValues}
											setError={setError}
										/>
									</div>

									<div className={`slide2 ${state.showSlide2}`}>
										<DateOfBirth
											slideChange={slideChange}
											validation={register}
											validationMsg={errors}
											trigger={trigger}
											clearErrors={clearErrors}
											getValues={getValues}
											previousSlideChange={previousSlideChange}
										/>
									</div>

									<div className={`slide3 ${state.showSlide3}`}>
										<PostCode
											slideChange={slideChange}
											validation={register}
											validationMsg={errors}
											trigger={trigger}
											clearErrors={clearErrors}
											getValues={getValues}
											setError={setError}
											previousSlideChange={previousSlideChange}
											setPostcodeval={setPostcodeval}
										/>
									</div>

									<div className={`slide4 ${state.showSlide4}`}>
										<ContactDetails
											slideChange={slideChange}
											validation={register}
											validationMsg={errors}
											trigger={trigger}
											clearErrors={clearErrors}
											getValues={getValues}
											setError={setError}
											previousSlideChange={previousSlideChange}
											formSubmit={formSubmit}
											isButtonDisabled={isButtonDisabled}
											setIsButtonDisabled={setIsButtonDisabled}
										/>
									</div>
									<VerificationMessage />
								</form>
							</div>
						</div>
					</div>
				</section>
				<CommonIcons />
				<div className="btm_sec">
					<Footer showMoreContent={true} />
				</div>

			</div>
		</>

	)
}

export default TCGL_V_A3_PersonalDetails