
import React, { useReducer, useState, useContext, useRef } from "react";
import AddressTextField from "../../../UI/AddressTextField";
import AddressSelectBox from "../../../UI/AddressSelectBox";
import { usePostCodeAddress } from "../../../../Hooks/usePostCodeAddress";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import Button from "../../../UI/Button";
import GTMDataLayer from "../../../../Utility/GTMDataLayer";

const initialState = {
  lookUp: true,
  nextClick: false,
  checkSelect: false,
  getDetails: [],
  getAddress: [],
  showManualAddress: "hide",
  getPostcode: "#",
};
const postcodeReducer = (state, action) => {
  switch (action.type) {
    case "validationSucess": {
      return {
        ...state,
        lookUp: action.payload.lookup,
        nextClick: action.payload.next_click,
        getPostcode: action.payload.postcode,
        getAddress: action.payload.get_address,
        checkSelect: action.payload.check_select,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "getExtraAddressDetails": {
      return {
        ...state,
        getDetails: action.payload.get_details,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "showManualDiv": {
      return {
        ...state,
        showManualAddress: "show",
        lookUp: false,
        nextClick: true,
        checkSelect: false,
        clickManualLink: action.payload.manual_click,
      };
    }
    case "resetaddress": {
      return {
        ...state,
        getDetails: [],
        getAddress: [],
        showManualAddress: "hide",
        lookUp: true,
        nextClick: false,
        checkSelect: false,
      };
    }
    default:
      return state;
  }
};
const PostCode = (props) => {
  const { getPostcodeAddress, getSplitPostcodeAddress } =
    usePostCodeAddress();
  const [state, dispatch] = useReducer(postcodeReducer, initialState);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const scrollToEvent = useRef(null);
  const checkValidation = async (e) => {
    e.preventDefault();
    dispatch({
      type: "resetaddress",
      get_address: [],
    });
    const txtPostCode = await props.trigger("txtPostCode");
    if (txtPostCode) {
      await getPostcodeAddress(e.target.value, visitorParameters.visitor_parameters.uuid).then(res => {
        if (res.data.status === "0") {
          props.setError("txtPostCode", {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });
        }
        if (res.data.length > 0) {
          dispatch({
            type: "validationSucess",
            payload: {
              lookup: false,
              next_click: true,
              postcode: e.target.value,
              get_address: res.data,
              check_select: true,
              manual_address: "hide",
            },
          });
          // props.setPostcodeval(e.target.value);
        }
      }).catch(
        function () {
          props.setError("txtPostCode", {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });
        }
      );
    }
  };
  const getValue = async (e) => {
    e.preventDefault();
    props.clearErrors([
      "txtHouseNumber",
      "txtAddress3",
      "txtCounty",
      "txtTown",
      "txtCountry",
    ]);
    if (e.target.value != "") {
      const getData = await getSplitPostcodeAddress(
        e.target.value,
        visitorParameters.visitor_parameters.uuid,
        // state.getPostcode,
      );
      const postcodeval = getData?.postCode || '';
      console.log("postcode", postcodeval);
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: getData, manual_address: "show" },
      });
      props.clearErrors([
        'address1',
        "txtHouseNumber",
        "txtAddress3",
        "txtCounty",
        "txtTown",
        "txtCountry",
      ]);
      setTimeout(() => {
        scrollToEvent.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }, 50);
      props.setPostcodeval(postcodeval);
    }
    else {
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: [], manual_address: "hide" },
      });
    }
  };

  const lookupValidation = async (e) => {
    await props.trigger("txtPostCode");
  };
  const addressValidation = async (e) => {
    let errorFlag = 0;
    const txtPostCode = await props.trigger("txtPostCode");
    var txtAddress;
    if (txtPostCode) {
      txtAddress = await props.trigger("address1");
    } else {
      document.getElementById("txtPostCode").focus();
      return false;
    }
    if (!txtAddress) {
      document.getElementById("address1").focus();
      return false;
    }
    if (
      !txtPostCode ||
      !txtAddress
    ) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      props.slideChange(e);
    } else {
      return false;
    }
  };
  return (


    <div className="drop-in ">

      <div className="col-12 d-flex mb-3">
        <div className="col-6">
          <AddressTextField
            type="text"
            placeholder="Postcode"
            name="txtPostCode"
            id="txtPostCode"
            className="form-control"
            dataId="txtPostCode"
            onBlur={(e) => { e.target.value = e.target.value.trim(); checkValidation(e); }}
            autoComplete="off"
            validation={props.validation({
              required: "Please Enter Postcode",
              pattern: {
                // value: /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i,
                value: /^[A-Za-z0-9 ]+$/,
                message: "Please Enter a Valid Postcode",
              },
              minLength: {
                value: 5,
                message: "Please Enter a Valid Postcode",
              },
            })}
            validationMsg={
              props.validationMsg.txtPostCode && props.validationMsg.txtPostCode.message
            }
          />
        </div>

      <div className="col-6 ms-1">
          <Button
            type="button"
            className="btn-lookup add-btn-shadow"
            value="Look Up Address"
            buttonText="Look Up Address"
            onClick={lookupValidation}
          />
        </div>
      </div>
      <div id="currentAddressCollapse" style={state.checkSelect ? { display: "block" } : { display: "none" }}>

      <div className="col-lg-12 mb-3">

        <AddressSelectBox
          className="form-select"
          OptionValue={state.getAddress}
          name="address1"
          id="address1"
          onChange={getValue}
          myRef={props.validation({ required: "Please select address" })}
          validationMsg={
            props.validationMsg.address1 && props.validationMsg.address1.message
          }
        />
      </div>

      <div className={`slide8_add row ${state.showManualAddress}`}>
      <div className={`col-lg-12 mb-3 form-group required-field adj_width`} style={{ display: state.getDetails.line_1 ? 'block' : 'none' }}>
          <AddressTextField
            type="text"
            placeholder="Address Line 1 *"
            name="txtHouseNumber"
            id="txtHouseNumber"
            className="form-control"
            dataId="txtHouseNumber"
            autoComplete="off"
            readonly={true}
            validation={props.validation({
              required: "Please Enter Address Line 1",
            })}
            validationMsg={
              props.validationMsg.txtHouseNumber &&
              props.validationMsg.txtHouseNumber.message
            }
            value={state.getDetails.line_1}
          />
        </div>
        <div className={`col-lg-12 mb-3 form-group adj_width`} style={{ display: state.getDetails.line_2 ? 'block' : 'none' }}>
          <AddressTextField
            type="text"
            placeholder="Address Line 2"
            name="txtAddress2"
            id="txtAddress2"
            className="form-control"
            dataId="txtAddress2"
            autoComplete="off"
            readonly={true}
            validation={props.validation()}
            value={state.getDetails.line_2}
          />
        </div>
        <div className={`col-lg-12 mb-3 form-group adj_width`} style={{ display: state.getDetails.line_3 ? 'block' : 'none' }}>
            <AddressTextField
              type="text"
              placeholder="Address Line 3"
              name="txtAddress3"
              id="txtAddress3"
              className="form-control"
              dataId="txtAddress3"
              autoComplete="off"
              readonly={true}
              validation={props.validation()}
              validationMsg=""
              value={state.getDetails.line_3}
            />
          </div>
        <div className={`col-lg-12 mb-3 form-group adj_width`} style={{ display: state.getDetails.line_4 ? 'block' : 'none' }}>
            <AddressTextField
              type="text"
              placeholder="Address Line 4"
              name="txtAddress4"
              id="txtAddress4"
              className="form-control"
              dataId="txtAddress4"
              autoComplete="off"
              readonly={true}
              validation={props.validation()}
              validationMsg=""
              value={state.getDetails.line_4}
            />
          </div>
        <div className={`col-lg-12 mb-3 form-group adj_width`} style={{ display: state.getDetails.county ? 'block' : 'none' }}>
          <AddressTextField
            type="text"
            placeholder="County"
            name="txtCounty"
            id="txtCounty"
            className="form-control"
            dataId="txtCounty"
            autoComplete="off"
            readonly={true}
            validation={props.validation()}
            validationMsg=""
            value={state.getDetails.county}
          />
        </div>
        <div className={`col-lg-12 mb-3 form-group adj_width`} style={{ display: state.getDetails.town ? 'block' : 'none' }}>
          <AddressTextField
            type="text"
            placeholder="Town *"
            name="txtTown"
            id="txtTown"
            className="form-control  hero-input"
            dataId="txtTown"
            autoComplete="off"
            readonly={true}
            validation={props.validation({ required: "Please Enter Town" })}
            validationMsg={
              props.validationMsg.txtTown && props.validationMsg.txtTown.message
            }
            value={state.getDetails.town}
          />
        </div>
        <div className={`col-lg-12 mb-3 form-group adj_width}`} style={{ display: state.getDetails.country ? 'block' : 'none' }}>
          <AddressTextField
            type="text"
            placeholder="Country *"
            name="txtCountry"
            id="txtCountry"
            className="form-control  hero-input"
            dataId="txtCountry"
            autoComplete="off"
            readonly={true}
            validation={props.validation({ required: "Please Enter Country" })}
            validationMsg={
              props.validationMsg.txtCountry && props.validationMsg.txtCountry.message
            }
            value={state.getDetails.country}
          />
        </div>
          {/* non visible fields */}
          <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
            <AddressTextField
              type="text"
              placeholder="Building Name"
              name="txtBuildingName"
              id="txtBuildingName"
              className="form-control address hero-input"
              dataId="txtBuildingName"
              autoComplete="off"
              validation={props.validation()}
              // validationMsg={
              //   props.validationMsg.txtBuildingName && props.validationMsg.txtBuildingName.message
              // }
              value={state.getDetails?.RawAddress?.BuildingName}
              readonly={true}
            />
          </div>
          <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
            <AddressTextField
              type="text"
              placeholder="Building Number"
              name="txtBuildingNumber"
              id="txtBuildingNumber"
              className="form-control address hero-input"
              dataId="txtBuildingNumber"
              autoComplete="off"
              validation={props.validation()}
              // validationMsg={
              //   props.validationMsg.txtBuildingNumber && props.validationMsg.txtBuildingNumber.message
              // }
              value={state.getDetails?.RawAddress?.BuildingNumber}
              readonly={true}
            />
          </div>
          <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
            <AddressTextField
              type="text"
              placeholder="Thoroughfare"
              name="txtThoroughfare"
              id="txtThoroughfare"
              className="form-control address hero-input"
              dataId="txtThoroughfare"
              autoComplete="off"
              validation={props.validation()}
              // validationMsg={
              //   props.validationMsg.txtThoroughfare && props.validationMsg.txtThoroughfare.message
              // }
              value={state.getDetails?.RawAddress?.ThoroughfareName}
              readonly={true}
            />
          </div>
          <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
            <AddressTextField
              type="text"
              placeholder="Sub building name"
              name="txtSubBuildingName"
              id="txtSubBuildingName"
              className="form-control address hero-input"
              dataId="txtSubBuildingName"
              autoComplete="off"
              validation={props.validation()}
              value={state.getDetails?.RawAddress?.SubBuildingName}
              readonly={true}
            />
          </div>
          <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
            <AddressTextField
              type="text"
              placeholder="Sub building number"
              name="txtSubBuildingNumber"
              id="txtSubBuildingNumber"
              className="form-control address hero-input"
              dataId="txtSubBuildingNumber"
              autoComplete="off"
              validation={props.validation()}
              value={state.getDetails?.RawAddress?.SubBuildingNumber}
              readonly={true}
            />
          </div>
          <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
            <AddressTextField
              type="text"
              placeholder="Locality"
              name="txtLocality"
              id="txtLocality"
              className="form-control address hero-input"
              dataId="txtLocality"
              autoComplete="off"
              validation={props.validation()}
              value={state.getDetails?.locality}
              readonly={true}
            />
          </div>
          <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
            <AddressTextField
              type="text"
              placeholder="District"
              name="txtDistrict"
              id="txtDistrict"
              className="form-control address hero-input"
              dataId="txtDistrict"
              autoComplete="off"
              validation={props.validation()}
              value={state.getDetails?.RawAddress?.Location?.District}
              readonly={true}
            />
          </div>
          <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
            <AddressTextField
              type="text"
              placeholder="Thoroughfare Description"
              name="txtThoroughfareDesc"
              id="txtThoroughfareDesc"
              className="form-control address hero-input"
              dataId="txtThoroughfareDesc"
              autoComplete="off"
              validation={props.validation()}
              value={state.getDetails?.RawAddress?.ThoroughfareDesc}
              readonly={true}
            />
          </div>
          <div className="d-none">
            <AddressTextField
              type="text"
              name="txtOrganisation"
              id="txtOrganisation"
              value={state.getDetails?.RawAddress?.Organisation}
              validation={props.validation()}
            />
            <AddressTextField
              type="text"
              name="txtDepartment"
              id="txtDepartment"
              value={state.getDetails?.RawAddress?.Department}
              validation={props.validation()}
            />
            <AddressTextField
              type="text"
              name="txtAddressKey"
              id="txtAddressKey"
              value={state.getDetails?.RawAddress?.AddressKey}
              validation={props.validation()}
            />
            <AddressTextField
              type="text"
              name="txtOrganisationKey"
              id="txtOrganisationKey"
              value={state.getDetails?.RawAddress?.OrganisationKey}
              validation={props.validation()}
            />
            <AddressTextField
              type="text"
              name="txtPostcodeType"
              id="txtPostcodeType"
              value={state.getDetails?.RawAddress?.PostcodeType}
              validation={props.validation()}
            />
            <AddressTextField
              type="text"
              name="txtDependentThoroughfareName"
              id="txtDependentThoroughfareName"
              value={state.getDetails?.RawAddress?.DependentThoroughfareName}
              validation={props.validation()}
            />
            <AddressTextField
              type="text"
              name="txtDependentThoroughfareDesc"
              id="txtDependentThoroughfareDesc"
              value={state.getDetails?.RawAddress?.DependentThoroughfareDesc}
              validation={props.validation()}
            />
            <AddressTextField
              type="text"
              name="txtDoubleDependentLocality"
              id="txtDoubleDependentLocality"
              value={state.getDetails?.RawAddress?.DoubleDependentLocality}
              validation={props.validation()}
            />
            <AddressTextField
              type="text"
              name="txtDependentLocality"
              id="txtDependentLocality"
              value={state.getDetails?.RawAddress?.DependentLocality}
              validation={props.validation()}
            />
            <AddressTextField
              type="text"
              name="txtDps"
              id="txtDps"
              value={state.getDetails?.RawAddress?.Dps}
              validation={props.validation()}
            />
            <AddressTextField
              type="text"
              name="txtPoBox"
              id="txtPoBox"
              value={state.getDetails?.RawAddress?.PoBox}
              validation={props.validation()}
            />
            <AddressTextField
              type="text"
              name="txtPostalCounty"
              id="txtPostalCounty"
              value={state.getDetails?.RawAddress?.PostalCounty}
              validation={props.validation()}
            />
            <AddressTextField
              type="text"
              name="txtTraditionalCounty"
              id="txtTraditionalCounty"
              value={state.getDetails?.RawAddress?.TraditionalCounty}
              validation={props.validation()}
            />
            <AddressTextField
              type="text"
              name="txtAdministrativeCounty"
              id="txtAdministrativeCounty"
              value={state.getDetails?.RawAddress?.AdministrativeCounty}
              validation={props.validation()}
            />
            <AddressTextField
              type="text"
              name="txtCountryISO2"
              id="txtCountryISO2"
              value={state.getDetails?.CountryISO2}
              validation={props.validation()}
            />
          </div>
      </div>
      </div>
      <div className="col-12 row" ref={scrollToEvent}>
        <div className="col-4">
          <Button
            type="button"
            name="back2"
            className="btn-back  mt-4 prev-slide2"
            value="Back"
            buttonText="< Back"
            onClick={props.previousSlideChange}
          />
        </div>
        <div className="col-8 pr-0">
          <Button
            type="button"
            name="slide3"
            className="btn-banner btn-fullwidth mt-4 next-slide4"
            value="Next"
            onClick={addressValidation}
            buttonText="Next >"
          />
          </div>
      </div>
    </div>
  )
};

export default PostCode;
