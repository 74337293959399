import { useState } from "react";
import { Api } from "../api/Api";
import { useErrorWebhook } from "./useErrorWebhook";

export const useCreditCheckCheckboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { ErrorWebhookTrigger } = useErrorWebhook();
  const creditCheckCheckboard = async (
    visitor_parameters,
    data,
    message_type
  ) => {
    setIsLoading(true);
    const response = await Api.post("v1/credit-check-cb", {
      visitor_parameters,
      data,
      message_type
    }).catch(function (error) {
      const erroMessage = {
        serviceName: `Credit check - ${message_type}`,
        errorReason: error.message,
        errorCode: error.code,
        uuid: visitor_parameters.uuid
      }
      ErrorWebhookTrigger(erroMessage);
      return {
        data: {
          status: "Success"
        }
      }
    });
    setIsLoading(false);
    return response;
  };
  return { creditCheckCheckboard, isLoading };
};