import { useState } from "react";
import { Api } from "../api/Api";
import { useErrorWebhook } from "./useErrorWebhook";

export const useGetCreditAnalysisData_Checkboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { ErrorWebhookTrigger } = useErrorWebhook();
  const getCreditAnalysisData = async (
    visitor_parameters,
    data,
    message_type
  ) => {
    setIsLoading(true);
    const response = await Api.post("v1/credit-check-v8", {
      visitor_parameters,
      data,
      message_type
    }).catch(function (error) {
      const erroMessage = {
        serviceName: `Credit check - ${message_type}`,
        errorReason: error.message,
        errorCode: error.code,
        uuid: visitor_parameters.uuid
      }
      ErrorWebhookTrigger(erroMessage);
      return {
        data: {
          status: "Success"
        }
      }
    });
    setIsLoading(false);

    const responseData = response?.data?.response || response?.data || {};
    const creditCheckApiResponseDetails = {
      vendor: responseData.vendor || "",
      status: responseData.status || "",
      message: responseData.message || "",
    };
    
    localStorage.setItem("creditCheckApiResponse", JSON.stringify(creditCheckApiResponseDetails));
    return response;
  };
  return { getCreditAnalysisData, isLoading };
};