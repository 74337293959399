import React, { useContext, useState,useRef } from 'react';
import EditInfo from "../../assets/img/TCGL_V_A1/editinfo.png";
import PreviousPostCodeEdit from './PreviousPostCodeEdit';
import Button from '../UI/Button';
import { slideValidation } from '../../Utility/Validations';
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import { getQueryString } from '../../Utility/QueryString';
import { useHistory } from "react-router-dom";


const QuestionSection = (props) => {
  const scrollToPrePc = useRef();
  const [valids, setValids] = useState("");
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const queryString = getQueryString();
  const splitName = new URLSearchParams(queryString).get('splitName');
  const history = useHistory();
  const creditCheckStatus = localStorage.getItem("editCreditCheckStatus") ?? "";
  // const [postCode,setPostCode] = useState([props.postCode])
  const [previousPostcodeSection, setPreviousPostcodeSection] = useState({ 'prevPostcode1': false, 'prevPostcode2': false, 'prevPostcode3': false});
  const previousPostCodeCheckArr = { 'prevPostcode2': [1], 'prevPostcode3': [1, 2]};

  const formSubmit = async (selectedOption, nextPage) =>{

      const validationArr = [`txtPrevPostCode_1`, `txtPrevAddress1_1`];
      const values = props.getValues();
      const formData = JSON.parse(localStorage.getItem('formData')) || {};
      let validationCheck;
      if(selectedOption.value != "NO"){
         validationCheck = await slideValidation(validationArr, props.trigger);
      }
      if (selectedOption.value == "Yes" && validationCheck == 'Success'
        || selectedOption.value == "NO" && validationCheck != 'Success'
      ) {
        // setTimeout(() => {
        //   scrollToPrePc.current.scrollIntoView({
        //     behavior: "smooth",
        //     block: 'end'
        //   });
        // }, 50);
        // const updatedFormData = { ...formData, ...values };
        const updatedFormData = {
          ...formData,
          ...values,
          txtPostCode: props.postcodeval,
        };
        if (values.txtPrevPostCode_1) {
          updatedFormData.txtPrevPostCode_1 = values.preRawPostCode_1;
        }
        if (values.txtPrevPostCode_2) {
          updatedFormData.txtPrevPostCode_2 = values.preRawPostCode_2;
        }
        if (values.txtPrevPostCode_3) {
          updatedFormData.txtPrevPostCode_3 = values.preRawPostCode_3;
        }

        localStorage.setItem("formData", JSON.stringify(updatedFormData));
        if (
          visitorParameters.data !== null ||
          visitorParameters.data !== undefined
        ) {
          const formSUbmitResult = await saveDataIngestion(
            visitorParameters.visitor_parameters,
            updatedFormData,
            "edit_form_submit",
            splitName,
            queryString,
            visitorParameters.data
          );
 
           if(formSUbmitResult.data.duplicate && formSUbmitResult.data.duplicate == true)
             {
               history.push(
                 "/duplicate" +
                 "?uuid=" +
                 visitorParameters.visitor_parameters.uuid +
                 "&splitName=" +
                 splitName +
                 "&type=" + formSUbmitResult.data.type +
                 "&key=" + formSUbmitResult.data.key
               );
             }
             if(formSUbmitResult.data.duplicate == false)
             {
               visitorParameters.visitor_parameters.uuid = formSUbmitResult.data.uuid
               history.push(
                   "/followupv2" +
                   "?uuid=" +
                   formSUbmitResult.data.uuid +
                   "&splitName=" +
                   splitName + 
                   "&atp_sub6="+ "FLP_DUP" 
                 );

             }else{
                let Uuid =  visitorParameters.visitor_parameters.uuid??URLSearchParams(queryString).get('uuid');
                   history.push("/" + nextPage + "?uuid=" + Uuid + "&splitName=" + splitName);
             }
        }
        // history.push("/" + nextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName=" + splitName);
      };
  }

  const handlePrevious = async () => {
    const selectedOption = document.querySelector('input[name="Radioshere4date"]:checked');
    let nextPage;
    if (creditCheckStatus == "success") {
      nextPage = "TCGL_V_A1/id-upload";
    } else {
      nextPage = "edit-credit-check"
    }
    if (!selectedOption) {
      setValids("Please select an option.");
    } else {
      setValids("");
      formSubmit(selectedOption, nextPage);
    }
  }

  const PrevAddressClick = (e) => {
    setValids("");
    props.setPostcodeval(props.postCode[0]);
    if (e.target.value == 'NO') {
      console.log("all postcode");
      console.log(props.postCode);
      props.setPostCode([props.postCode[0]]);
      setPreviousPostcodeSection({
        prevPostcode1: false,
        prevPostcode2: false,
        prevPostcode3: false
      });
    } else {
      props.setPostCode([...props.postCode]);
      setPreviousPostcodeSection(prevState => ({
        ...prevState,
        prevPostcode1: true,
      }));
    }
  }

  const AddPrevPostcode = async () => {
    var nextSection = Object.entries(previousPostcodeSection).find(([key, value]) => value === false);
    if (nextSection) {
      var addValidationArr = [];
      previousPostCodeCheckArr[nextSection[0]].forEach(element => {
        addValidationArr.push(`txtPrevPostCode_${element}`, `txtPrevAddress1_${element}`);
      });
      const validationCheck = await slideValidation(addValidationArr, props.trigger);
      if (validationCheck == 'Success') {
        setPreviousPostcodeSection((prevState) => ({
          ...prevState,
          [nextSection[0]]: true,
        }));
        setTimeout(() => {
          scrollToPrePc.current.scrollIntoView({
            behavior: "smooth",
            block: 'end'
          });
        }, 50);
      }
    }
  }


  return (
    <>
      <div className={`slide3 ${(props.showEdit3)}`}>
        <div className="form-header d-flex align-items-center">
          <div className="col-lg-2 col-3">
            <img src={EditInfo} alt="" />
          </div>
          <div className="col-lg-10 col-9 bor-l  align-self-center">
            <h4>
              We couldn't find <br className="d-block d-md-none" />
              <span> your agreements</span>
            </h4>
            <p>
              Don't worry, you can edit your details if they are incorrect,
              or add the lender if you know who provided your finance
              agreement.
            </p>
          </div>
        </div>
        <div className="drop-in ">
          <h5>
            Have you lived anywhere else other than {props.postCode.join("/ ")} in the past 6
            years
          </h5>
          <p>
            This is important information as it helps us find any past
            finance agreements.
          </p>
          <div className="choose_date col-lg-12 col-12">
            <div className="px-0 col-lg-12 d-flex gap-3">
              <div className="form-check ps-0">
                <input
                  className="form-check-input"
                  type="radio"
                  name="Radioshere4date"
                  id="Radios41date"
                  defaultValue="Yes"
                  onClick={(e)=>PrevAddressClick(e)}
                />
                <label
                  className="form-check-label postyes"
                  htmlFor="Radios41date"
                >
                  Yes
                </label>
              </div>
              <div className="form-check ps-0">
                <input
                  className="form-check-input"
                  type="radio"
                  name="Radioshere4date"
                  id="Radios42date"
                  defaultValue="NO"
                  onClick={(e)=>PrevAddressClick(e)}
                />
                <label
                  className="form-check-label postno"
                  htmlFor="Radios42date"
                >
                  No
                </label>
              </div>
            </div>
          </div>
          {previousPostcodeSection.prevPostcode1 && <PreviousPostCodeEdit
            validation={props.validation}
            validationMsg={props.validationMsg}
            trigger={props.trigger}
            clearErrors={props.clearErrors}
            getValues={props.getValues}
            setError={props.setError}
            preCount="1"
            placeholder="Post Code"
            scrollToEvent={scrollToPrePc}
            setPostCode={props.setPostCode}
          />}
          { previousPostcodeSection.prevPostcode2 && <PreviousPostCodeEdit
            validation={props.validation}
            validationMsg={props.validationMsg}
            trigger={props.trigger}
            clearErrors={props.clearErrors}
            getValues={props.getValues}
            setError={props.setError}
            preCount="2"
            placeholder="Post Code"
            scrollToEvent={scrollToPrePc}
            setPostCode={props.setPostCode}
          />}
          { previousPostcodeSection.prevPostcode3 && <PreviousPostCodeEdit
            validation={props.validation}
            validationMsg={props.validationMsg}
            trigger={props.trigger}
            clearErrors={props.clearErrors}
            getValues={props.getValues}
            setError={props.setError}
            preCount="3"
            placeholder="Post Code"
            scrollToEvent={scrollToPrePc}
            setPostCode={props.setPostCode}
          />}
          {valids && <span className="error_msg">{valids}</span>}
          {(previousPostcodeSection.prevPostcode1 && !previousPostcodeSection.prevPostcode3) &&
            <div className="text-end" onClick={AddPrevPostcode}>
              <a className="add-btn text-decoration-underline" >+ Add More Details</a>
            </div>
          }
          <hr />
          <div className="col-12 row justify-content-end" ref={scrollToPrePc}>
            <div className="col-12 col-lg-8 pr-0">
              <Button
                type="button"
                className="btn-banner btn-fullwidth mt-4"
                buttonText={<>
                  Save and Continue &gt;</>}
                onClick={handlePrevious}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default QuestionSection;