import { useHistory } from "react-router-dom";
import { UUID } from "./UUID";

export const CheckUUID = () => {
  const params = new URLSearchParams(window.location.search);
  const history = useHistory();
  const { fetchUuid } = UUID();
  const split_name = params.get("splitName");
  const isCheck = () => {
    var flag = false;
    var localFlag = false;
    if (!params.has("uuid") || (params.has("uuid") && !params.get("uuid")) || (params.has("uuid") && params.get("uuid") === '""')) {
      flag = true;
    }
    if (!localStorage.getItem('uuid')) {
      localFlag = true;
    }
    if (flag && localFlag) {
      history.push("/404");
      return false;
    } else {
      const uuid = fetchUuid();
      window.history.pushState({}, '', '?uuid=' + uuid + '&splitName=' + split_name );
      return true;
    }
  }
  return { isCheck }
}