import React, { useContext,useEffect } from 'react';
import EditInfo from "../../../../assets/img/TCGL_V_A1/editinfo.png"
import Button from '../../../UI/Button';
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { getQueryString } from "../../../../Utility/QueryString";

const FollowUpDisplayDetails = (props) => {
  const { visitorParameters } = useContext(VisitorParamsContext);
  const queryString = getQueryString();
  const history = useHistory();
  const splitName = new URLSearchParams(queryString).get('splitName');
  const creditCheckStatus = localStorage.getItem("editCreditCheckStatus") ?? "";

  const handleChange = () => {
    let nextPage = "followup-edit-credit-check"
    
    history.push("/" + nextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName=" + splitName
    );
    window.scrollTo(0, 0);
  }

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  return (
    <>
      <div className={`slide1 ${(props.showEdit)}`}>
        <div className="form-header mb-2 mt-0 d-flex align-items-center">
          <div className="col-lg-2 col-3">
            <img src={EditInfo} alt="" />
          </div>
          <div className="col-lg-10 col-9 bor-l  align-self-center">
            <h4>
              We couldn't find <br className="d-block d-md-none" />
              <span> your agreements</span>
            </h4>
            <p>
            Based on the information you have provided we have been unable to identify any car finance agreements. Please check your details so we can check again.
            </p>
          </div>
        </div>
        <div className="drop-in">
          <div className="col-lg-12">
            <h5>Your Details</h5>
            <hr />
          </div>
          {/* <div className="d-lg-flex flex-lg-nowrap gap-3 editcol-details">
            <div className="col-lg-6 col-12">
              <p>
                <strong> Full Name</strong>
              </p>
              {props.firstName} {props.LastName}
            </div>
            <div className="col-lg-6 col-12 mt-3 mt-lg-0">
              <p>
                <strong> Date of birth</strong>
              </p>
              {props.dob}
            </div>
          </div> */}
          <div className="d-lg-flex flex-lg-nowrap gap-3 editcol-details">
            <div className="col-lg-6 col-12">
              <p>
                <strong> Email</strong>
              </p>
              {props.email}
            </div>
            <div className="col-lg-6 col-12 mt-3 mt-lg-0">
              <p>
                <strong> Phone Number</strong>
              </p>
              {props.phone}
            </div>
          </div>
          <div className="d-flex flex-nowrap gap-3 editcol-details">
            <div className="col-lg-12 col-12">
              <p>
                <strong> Current Address</strong>
              </p>
              {[
                props.houseNumber,
                props.address2,
                props.address3,
                props.address4,
                props.county,
                props.district,
                props.country,
                props.postCode,
              ]
                .filter((field) => field)
                .join(", ")}
            </div>
          </div>
          <div className='sticky-btnwrap bg-white'>
            <div className="d-flex flex-nowrap editcol-details justify-content-center border-0 m-0 pb-0">
              <p className="m-0">
                <strong> Please confirm your details are correct</strong>
              </p>
            </div>
            <div className="col-12 d-flex flex-nowrap flex-row gap-4 gap-lg-4 ">
              <Button
                type="button"
                // className="btn-back mt-lg-4 mt-0 next-slide2 order-2 order-lg-1"
                className="btn-banner blue-btn  mt-lg-4 mt-2  mb-2"
                buttonText="Edit"
                onClick={props.setShowEdit2}
              />
              <Button
                type="button"
                // className="btn-banner  mt-lg-4 mt-2  order-1  order-lg-2"
                className="btn-back mt-lg-4 mt-2 next-slide2 mb-2"
                buttonText="Skip"
                onClick={handleChange}
              />

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FollowUpDisplayDetails;