import React, { useContext, useEffect, useState } from "react";
import CommonHeader from "../../Includes/Layouts/Header/CommonHeader";
import "../../../assets/css/TCGL_V_A1/custom.scss";
import "../../../assets/css/TCGL_V_A1/lenders.scss";
import Footer from "../../Includes/Layouts/TCGL_V_A1/Footer";
import GetVisitorsParams from "../../../Utility/GetVisitorsParams";
import Button from "../../UI/Button";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { getQueryString } from "../../../Utility/QueryString";
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import GTMDataLayer from "../../../Utility/GTMDataLayer";
import GetLenders from "../../../Utility/GetLenders";
import lenders_icon from "../../../assets/img/TCGL_V_A1/lenders-icon.png";
import LenderSelectModal from "../../Includes/Layouts/TCGL_V_A1/LenderSelectModal";
import LenderAccordion from "../../Includes/Layouts/TCGL_V_A1/LenderAccordion";

const TCGL_V_A1_Lenders = () => {
  const [selectedLender, setSelectedLender] = useState([]);
  const [creditCheckLenders, setCreditCheckLenders] = useState({
    creditCheckSelectedLenders: [],
    creditCheckDeSelectedLenders: [],
  });
  const [filteredLenders, setFilteredLenders] = useState([]);
  const [validationError, setValidationError] = useState({
    errorStatus: false,
    message: "",
  });  
  const [showModal, setShowModal] = useState({ errorStatus: false });
  const [blink, setBlink] = useState(false);
  const [hasSkipped, setHasSkipped] = useState(true);
  const [showAllLenders, setShowAllLenders] = useState(true);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [openIndex, setOpenIndex] = useState(null);
  const history = useHistory();
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const { getLenderList } = GetLenders();
  const queryString = getQueryString();
  const urlParams = new URLSearchParams(window.location.search);
	const splitName = urlParams.get('splitName') ? urlParams.get('splitName') : localStorage.getItem('split_name');
	const uuid = urlParams.get('uuid') ? urlParams.get('uuid') : localStorage.getItem('uuid');
  //const splitName = new URLSearchParams(queryString).get("splitName");
  //const uuid = new URLSearchParams(queryString).get("uuid");
  const message_type = "lender_store";
  visitorParameters.visitor_parameters.uuid = uuid;
  let nextPage = "TCGL_V_A1/signature";
  const skipPage = "TCGL_V_A1/signature";

  // Load selected banks from localStorage on page load
  const setAlreadySelectedLendersFunction = () => {
    // const storedBanks = localStorage.getItem("selectedBanks");
    // const LenderDB_Count = localStorage.getItem("LenderDB_Count");
    const creditCheckLendersLocal = localStorage.getItem("creditCheckLenders");
    const deSelectedLendersLocal =
      JSON.parse(localStorage.getItem("deSelectedLenders")) || [];
    setCreditCheckLenders((prevState) => ({
      ...prevState,
      creditCheckSelectedLenders: JSON.parse(creditCheckLendersLocal),
      creditCheckDeSelectedLenders: deSelectedLendersLocal,
    }));
    // if (creditCheckLendersLocal.length > 1 || storedBanks.length > 1) {
    //   setBlink(true);
    // }
    // if (storedBanks) {
    //   setSelectedLender(JSON.parse(storedBanks));
    // }
  };

  useEffect(() => {
    (async () => {
      const response = await getLenderList(
        visitorParameters.visitor_parameters.uuid,
        setHasSkipped
      );
      setFilteredLenders(response);

      // for setting all lenders as selected by default
      const selectedLenders = response.map(item => {
        const { sublender, ...selectedLender } = item;
        return selectedLender;
      });
      setSelectedLender(selectedLenders);
      // end
      setAlreadySelectedLendersFunction();
    })();
  }, []);

  useEffect(() => {
    if (
      // creditCheckLenders.creditCheckSelectedLenders.length !=
      // creditCheckLenders.creditCheckDeSelectedLenders.length ||
      selectedLender.length > 0
    ) {
      setBlink(true);
    } else if (
      // creditCheckLenders.creditCheckSelectedLenders.length ==
      // creditCheckLenders.creditCheckDeSelectedLenders.length &&
      selectedLender.length < 1
    ) {
      setBlink(false);
    }
  }, [creditCheckLenders, selectedLender]);

  const clickEvent = (e, id, bankcode, label, value) => {
    //e.stopPropagation();
    // Check if the selected lender and sublender already exist in the state
    const exists = selectedLender.some((item) => item.id === id);

    // If exists, filter it out, otherwise add the new lenderId and sublenderId
    const updatedData = exists
      ? selectedLender.filter((item) => !(item.id === id))
      : [...selectedLender, { id, bankcode, label, value }];

    setSelectedLender(updatedData);
    setValidationError({
      errorStatus:
        updatedData.length < 1
      // && creditCheckLenders?.creditCheckDeSelectedLenders.length ==
      // creditCheckLenders?.creditCheckSelectedLenders.length
      ,
      message: "Please Select Your Finance provider",
    });
  };
  const handleToggle = (event,index) => {
    // event.stopPropagation();
    setOpenIndex(openIndex === index ? null : index); 
  };
  const LenderSection1 = [];
  const LenderSection2 = [];
  filteredLenders.map((value, index) => {
    const element = (
      <div key={`${index}`} style={{ position:"relative" }}>
        <input
          type="checkbox"
          className={`test`}
          value={value.value}
          id={`lender_${index}`}
          name={`lender_${index}`}
          onChange={(e) => {
            clickEvent(
              e,
              value.id,
              value.bank_code,
              value.label,
              value.value
            );
          }}
          checked={
            selectedLender.some((item) => item.id === value.id) ? true : false
          }
           
        />
       
        <label htmlFor={`lender_${index}`} className="selected-false">
          <span className="option_title title_1">{value.label}</span>
        </label>
        {value.sublender && value.sublender.length > 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                position: "absolute",
                right: "1%",
                cursor:"pointer"
              }}
              // className={`px-2` ${(openIndex == index) ? `top-div` : `top-sub`} }
              className={`px-2 ${openIndex === index ? 'top-sub' : 'top-div'}`}
              onClick={(e) =>
                value.sublender && value.sublender.length > 0
                  ? handleToggle(e, index)
                  : null
              } // Toggle the accordion on click if sublenders exist
            >
              <span className="arrow-icon">Group ▼</span>
            </div>
          )}
       {openIndex == index && 
       <div className="lender-div">
       <ul className="lender-list">
                      {value.sublender &&
                        value.sublender.length > 0 &&
                        value.sublender.map((subLender, subIndex) => (
                          <li key={subIndex}>
                            {subLender.label}
                            <hr />
                          </li>
                        ))}
                    </ul>
                    </div>
  }
      </div>
      
    );

    if (index <= 10) {
      LenderSection1.push(element);
    } else {
      LenderSection2.push(element);
    }
  });

  const handleLenders = async (e) => {
    if (
      selectedLender.length < 1
      // && creditCheckLenders.creditCheckSelectedLenders.length ==
      // creditCheckLenders.creditCheckDeSelectedLenders.length
    ) {
      setValidationError({
        errorStatus: true,
        message: "Please Select Your Finance provider",
      });
      setShowModal({ errorStatus: true });
    } else {
      // Retrieve existing selected banks from localStorage
      let existingSelectedBanks =
        JSON.parse(localStorage.getItem("selectedBanks")) || [];

      // Remove banks from existingSelectedBanks if they are not in selectedLender
      let updatedSelectedBanks = existingSelectedBanks.filter((existingBank) =>
        selectedLender.some((newBank) => newBank.id === existingBank.id)
      );

      // Filter out values from selectedLender that are not already present in updatedSelectedBanks (to add new ones)
      let newBanksToAdd = selectedLender.filter(
        (newBank) =>
          !updatedSelectedBanks.some(
            (existingBank) => existingBank.id === newBank.id
          )
      );

      // Append only the new banks that are not already in updatedSelectedBanks
      updatedSelectedBanks = [...updatedSelectedBanks, ...newBanksToAdd];

      // Store the updated list back in localStorage
      localStorage.setItem(
        "selectedBanks",
        JSON.stringify(updatedSelectedBanks)
      );

      const newCreditSelectedLenders =
        creditCheckLenders.creditCheckSelectedLenders.map((item) => item.id);
      const totalLenderList = creditCheckLenders.creditCheckSelectedLenders
        .filter(
          (lender) =>
            !creditCheckLenders.creditCheckDeSelectedLenders.includes(
              lender.id.toString()
            )
        )
        .map(({ img, ...rest }) => rest);

      localStorage.setItem(
        "userBankList",
        JSON.stringify([...totalLenderList, ...updatedSelectedBanks])
      );
      let lender_data = {
        userSelectedLender: selectedLender,
        creditCheckDeSelectedLenders:
          creditCheckLenders.creditCheckDeSelectedLenders || [],
        creditCheckSelectedLenders: newCreditSelectedLenders,
      };
      setValidationError({ errorStatus: false, message: "" });
      const formSUbmitResult = await saveDataIngestion(
        visitorParameters.visitor_parameters,
        lender_data,
        message_type,
        splitName,
        queryString,
        visitorParameters.data
      );
      GTMDataLayer({
        clickEvent: "slideclick",
        question: "select lenders",
        answer: "",
      });
      // history.push(
      //   "/loader?uuid=" +
      //   visitorParameters.visitor_parameters.uuid +
      //   "&splitName=" +
      //   splitName +
      //   "&nextPage=" +
      //   nextPage
      // );
      history.push(
        "/" +
        nextPage +
        "?uuid=" +
        visitorParameters.visitor_parameters.uuid +
        "&splitName=" +
        splitName
      );
    }
  };

  const hidepopup = () => {
    setShowModal({ errorStatus: false });
  };

  const triggerShowAllLenders = () => {
    setShowAllLenders(!showAllLenders);
    const el = document.getElementById("more-lenders");
    if (el) {
      el.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, [])

  const [fullSelectedLenders, setFullSelectedLenders] = useState(null);
  useEffect(() => {
    const matched = selectedLender.map(slender =>
      filteredLenders.find(lender => lender.id === slender.id)
    ).filter(lender => lender !== undefined);
    setFullSelectedLenders(matched);
  }, [selectedLender, filteredLenders]);

  return (
    <>
      <GetVisitorsParams />
      <div className="TCGL_V_A1_Lenders TCGL_V_A1">
        <div className="supagebg11">
          <CommonHeader />
          <section className="banner banner-subpage nobg">
            <div className="container">
              <div
                className="progress"
                style={{ height: "5px", margin: "25px 0 25px 0" }}
              >
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "85%" }}
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12 align-self-center order-lg-1 order-2 offset-lg-3">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="slide1carreg">
                      <div className="form-header d-flex align-items-center mb-3">
                        <div className="col-lg-2 col-3">
                          <img src={lenders_icon} alt="" />
                        </div>
                        <div className="col-lg-10 col-9 bor-l align-self-center py-0">
                          <h4
                            className="fs-20"
                            style={{ fontSize: "20px", color: "black", textAlign: "left" }}
                          >
                            Your Car Finance Lenders
                            <br />
                          </h4>
                          <p
                            className="mb-0 mt-2"
                            style={{
                              fontSize: "12px",
                              color: "rgb(0, 0, 0)",
                              margin: "0px",
                              textAlign: "left",
                            }}
                          >
                            <i>
                              We have pre-selected the lenders you most likely to have had car finance with. You could claim up to £1,100* for each finance agreement you held.
                            </i>
                          </p>
                        </div>
                      </div>
                      <div className="col-12 lenders">
                        <div className="selectoptions">
                          {LenderSection1}

                          {blink && !showAllLenders ?
                            <div className="col-12">
                              <Button
                                buttonText="View More Lenders"
                                className="btn vbtn btn-primary w-100"
                                onClick={() => triggerShowAllLenders()}
                              />
                            </div> : <></>
                          }
                          <div id='more-lenders' className="additional-lenders">
                            {showAllLenders &&
                              LenderSection2
                            }
                          </div>
                          {/* {validationError.errorStatus && (
                            <span
                              className="error_msg "
                              id="txtEmail_err"
                            >
                              <label id="error" className="error_msg" > {validationError.message}</label>
                            </span>
                          )} */}
                        </div>
                      </div>
                      <p style={{ fontWeight: "normal", fontSize: "14px" }}>
                      Your lender may be part of a group - to view grouped lenders use the dropdown next to the lender name. By proceeding you are giving us instruction to investigate and submit claims to all lenders in each group. Please only proceed with the lenders who did not tell you about the commission. If you need to remove a lender please unselect.
                </p>
                <p className="text-center">
                        Reclaim Up To £1,100* per vehicle!
                      </p>
                      {/* <LenderAccordion
                        fullSelectedLenders={fullSelectedLenders}
                        clickEvent={clickEvent}
                      /> */}
                      <div className="row mob_sticky">
                        {(!blink && !showAllLenders) ?
                          <div className="col-12">
                            <Button
                              type="button"
                              name={`Next_bank`}
                              id={`Next_bank`}
                              className={`btn-banner btn-fullwidth mt-4 see-more-btn`}
                              buttonText={`See more Lenders`}
                              onClick={() => triggerShowAllLenders()}
                            />
                          </div> :
                          <div className={`col-12`}>
                            <Button
                              type="button"
                              name={`Next_bank`}
                              id={`Next_bank`}
                              className={`btn-banner btn-fullwidth mt-4 ${blink ? "pulse-anime success-color-v1c" : 'new-color grey-btn'} `}
                              buttonText={`Start Claims`}
                              onClick={handleLenders}
                            />
                          </div>
                        }
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <LenderSelectModal
              showstatus={showModal.errorStatus}
              hidepopup={hidepopup}
            />
          </section>
          <div className="btm_sec">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};
export default TCGL_V_A1_Lenders;
