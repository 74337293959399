export const vehiclequestionaire = {
    questionId: 3,
    question: "When did you purchase the vehicle?",
    answers: [
        {
            label: "On or before 27th January 2021",
            value: "6"
        },
        {
            label: "On or after 28th January 2021",
            value: "7"
        }
    ]
};

export const vehiclequestion = {
    questionId:4,
    question: "Did you use this lender to finance another vehicle?",
    answers: [
        {
            label: "Yes",
            value: "8"
        },
        {
            label: "No",
            value: "9"
        }
    ]
}

