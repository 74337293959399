import React, { useEffect } from 'react'
import "../../../assets/css/TCGL_V_A1/try_again.scss"
import "../../../assets/css/TCGL_V_A1/credit-analysis.scss"
import Logo_white from "../../../assets/img/TCGL_V_A1/Logo_white.png"
import chat from "../../../assets/img/TCGL_V_A1/chat.png"
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import LogoBasedOnDomain from '../../Includes/Layouts/Common/LogoBasedOnDomain'

const TryAgain = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const nextPage = urlParams.get('nextPage') || '';
  const uuid = urlParams.get('uuid') || '';
  const splitName = urlParams.get('splitName') || '';
  const atp_sub2 = urlParams.get('atp_sub2') || '';
  const history = useHistory();
  useEffect(()=> {
    const timer = setInterval(() => {
      if(atp_sub2){
        history.push("/" + nextPage + "?uuid=" + uuid + "&splitName=" + splitName + "&atp_sub2=" + atp_sub2);
      }else{
        history.push("/" + nextPage + "?uuid=" + uuid + "&splitName=" + splitName);
      }
    }, 3000);
    return () => clearInterval(timer);
  },[splitName]);
  return (
    <div className="try_again_page TCGL_V_A1_creditanalysis">
      <div className="supagebg">
        <header className="credheader">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                {/* <a href="index.html">
                  <img
                    className="header-logo"
                    src={Logo_white}
                    height={45}
                  />
                </a> */}
                <LogoBasedOnDomain  height={45}/>
              </div>
            </div>
          </div>
        </header>
        <section className="creditanalysis custom-vh">
          <div className="container">
            <div className="row">
              <div className="drop-in col-12 tryagain text-center">
                <div className="tryagain-slide2 col-12 col-lg-8 offset-lg-2">
                  <img src={chat} alt="" />
                  <h4 className='primary-text'>
                  Sorry, we have been unable to verify the information you have provided, at this stage. We will further review the information provided and will be in touch if we can support you further.{" "}
                  </h4>
                  {/* <h4 className='secondary-text'>
                    Rest assured, we’ll explore alternative solutions to help you move
                    forward with your claim.
                  </h4> */}
                  <h4>&nbsp;</h4>
                </div>
                <div className="loader-try">
                  <div className="loading-spinner-try">
                    {/* <img src={logo} alt="" className="loader_logo" /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default TryAgain