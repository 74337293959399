import React from "react";
import SignatureCanvas from "react-signature-canvas";
import { MobileView, BrowserView } from "react-device-detect";

const SignatureCanvasComponent = (props) => {
  return (
    <div className={`signaturepad ${props.extraClass}`}>
    {(props.deviceType === "mobile" || props.deviceType === "tablet") && (
      <MobileView>
        <SignatureCanvas
          canvasProps={{
            className: "writing-line",
            id: "canvas_signature",
          }}
          clearOnResize={false}
          ref={props.signPadRef}
          onEnd={() => {
            if (!props.signPadRef.current.isEmpty()) {
              props.dispatch({
                type: "signPadChange",
                payload: { value: false },
              });
              props.dispatch({
                type: "validSignature",
                payload: { value: false },
              });

            }
          }}
        />
      </MobileView>
    )}
    {props.deviceType === "browser" && (
      <BrowserView>
        <SignatureCanvas
          canvasProps={{
            className: "writing-line",
            id: "canvas_signature",
          }}
          clearOnResize={false}
          ref={props.signPadRef}
          onEnd={() => {
            if (!props.signPadRef.current.isEmpty()) {
              props.dispatch({
                type: "signPadChange",
                payload: { value: false },
              });
              props.dispatch({
                type: "validSignature",
                payload: { value: false },
              });

            }
          }}
        />
      </BrowserView>
    )}
  </div>
  );
};

export default SignatureCanvasComponent;
