import React from 'react';
import icon1 from '../../../../assets/img/TCGL_V_A1/icon-1.png';
import icon2 from '../../../../assets/img/TCGL_V_A1/icon-2.png';
import icon3 from '../../../../assets/img/TCGL_V_A1/icon-3.png';
import icon4 from '../../../../assets/img/TCGL_V_A1/icon-4.png';
import "../../../../assets/css/TCGL_V_A1/custom.scss";
const CommonIcons = () => {
  return (
    <section className="stepsec py-5 ">
    <div className="container ">
      <div className="row">
        <div className="col-lg-4 col-md-4 col-6 text-center mb-3">
          <img src={icon1} alt="" />
          <h2 className="mt-3">Get started in under a minute</h2>
        </div>
        <div className="col-lg-4 col-md-4 col-6 text-center mb-3">
          <img src={icon2} alt="" />
          <h2 className="mt-3">Free Car Finance Agreement Check#</h2>
        </div>
        {/* <div className="col-lg-3 col-md-3 col-6 text-center mb-3">
          <img src={icon3} alt="" />
          <h2 className="mt-3">100% online process</h2>
        </div> */}
        <div className="col-lg-4 col-md-4 col-12 text-center mb-3">
          <img src={icon4} alt="" />
          <h2 className="mt-3">No agreement documents required</h2>
        </div>
      </div>
    </div>
  </section>
  );
};

export default CommonIcons;
