import React, { useState, useReducer, useContext, useEffect } from 'react';
import AddressTextField from "../../../UI/AddressTextField";
import AddressSelectBox from "../../../UI/AddressSelectBox";
import InputButton from "../../../UI/InputButton";
import HiddenField from "../../../UI/HiddenField";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { usePostCodeAddress } from '../../../../Hooks/usePostCodeAddress';

const initialState = {
  lookUp: true,
  skip: true,
  nextClick: false,
  checkSelect: false,
  getDetails: [],
  getAddress: [],
  showManualAddress: "d-none",
  getPostcode: "#",
  clickManualLink: 0,
};
const postcodeReducer = (state, action) => {
  switch (action.type) {
    case "validationSucess": {
      return {
        ...state,
        lookUp: action.payload.lookup,
        skip: action.payload.skip,
        nextClick: action.payload.next_click,
        getPostcode: action.payload.postcode,
        getAddress: action.payload.get_address,
        checkSelect: action.payload.check_select,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "getExtraAddressDetails": {
      return {
        ...state,
        getDetails: action.payload.get_details,
        showManualAddress: action.payload.manual_address,
        nextClick: action.payload.next_click,
      };
    }
    case "showManualDiv": {
      return {
        ...state,
        showManualAddress: "d-block",
        lookUp: false,
        skip: false,
        nextClick: true,
        checkSelect: false,
        clickManualLink: action.payload.manual_click,
      };
    }
    case "resetaddress": {
      return {
        ...state,
        getDetails: [],
        getAddress: [],
        showManualAddress: "d-none",
        lookUp: true,
        skip: true,
        nextClick: false,
        checkSelect: false,
      };
    }
    default:
      return state;
  }
};
const PreviousPostCode = (props) => {
  const {  getPostcodeAddress, getSplitPostcodeAddress } = usePostCodeAddress();
  const [state, dispatch] = useReducer(postcodeReducer, initialState);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [postCodeAddress, setPostCodeAddress] = useState([]);
  const [getAddressDetails, setGetAddressDetails] = useState([]);
  const checkValidation = async (e) => {
    e.preventDefault();
    dispatch({
      type: "resetaddress",
      get_address: [],
    });
    const txtPrevPostCode = await props.trigger(`txtPrevPostCode_${props.preCount}`);
    if (txtPrevPostCode) {
      await getPostcodeAddress(e.target.value)
        .then((response) => {
          if (response.data.length > 0) {
            setGetAddressDetails(response.data);
            dispatch({
              type: "validationSucess",
              payload: {
                lookup: false,
                skip: false,
                next_click: false,
                postcode: e.target.value,
                get_address: response.data,
                check_select: true,
                manual_address: "d-none",
              },
            });
          } else {
            props.setError(`txtPrevPostCode_${props.preCount}`, {
              type: "manual",
              message: "Please Enter Valid Postcode",
            });
          }
        })
        .catch(function () {
          props.setError(`txtPrevPostCode_${props.preCount}`, {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });
        });
    }
  };
  const getValue = async (e) => {
    e.preventDefault();
    props.clearErrors([
      `txtPrevHouseNumber_${props.preCount}`,
      `txtPrevAddress3_${props.preCount}`,
      `txtPrevCounty_${props.preCount}`,
      `txtPrevTown_${props.preCount}`,
      `txtPrevCountry_${props.preCount}`,
    ]);

    // check for duplicate address
    const { name, value } = e.target;
    let storedData = JSON.parse(localStorage.getItem('userSelectedAddress')) || {};

    for (let key in storedData) {
      if (storedData[key] === value && key !== name) {
        props.setError(name, {
          type: "manual",
          message: "The Address cannot be the same as previously entered",
        });
        dispatch({
          type: "getExtraAddressDetails",
          payload: { get_details: [], manual_address: "d-none" },
        });
        setPostCodeAddress([]);

        delete storedData[name];
        localStorage.setItem('userSelectedAddress', JSON.stringify(storedData));
        e.target.value = "";

        return;
      }
    }

    props.clearErrors(name);
    storedData[name] = value;
    localStorage.setItem('userSelectedAddress', JSON.stringify(storedData));
    ///////////////

    if (e.target.value != "") {
      const getData = await getSplitPostcodeAddress(
        e.target.value,
        visitorParameters.visitor_parameters.uuid,
      );
      props.setShowNextButton(true);
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: getData, manual_address: "show", next_click: true },
      });
      setPostCodeAddress(getData);
      props.clearErrors([
        `txtPrevHouseNumber_${props.preCount}`,
        `txtPrevAddress3_${props.preCount}`,
        `txtPrevCounty_${props.preCount}`,
        `txtPrevTown_${props.preCount}`,
        `txtPrevCountry_${props.preCount}`,
      ]);
      setTimeout(() => {
        props.scrollToEvent.current.scrollIntoView({
          behavior: "smooth",
          block: 'end'
        });
      }, 50);
    } else {
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: [], manual_address: "d-none" },
      });
      setPostCodeAddress([]);
    }
  };
  const lookupValidation = async (e) => {
    await props.trigger(`txtPrevPostCode_${props.preCount}`);
  };
  const HiddenFields = () => (
    <>
      <HiddenField
        name={`txtPrevUdprn_${props.preCount}`}
        inputId={`txtPrevUdprn_${props.preCount}`}
        inputValue={state.getDetails !== "#" ? state.getDetails.Udprn : ""}
      />
      <HiddenField
        name={`txtPrevDeliveryPointSuffix_${props.preCount}`}
        inputId={`txtPrevDeliveryPointSuffix_${props.preCount}`}
        inputValue={
          state.getDetails !== "#" ? state.getDetails.deliverypointsuffix : ""
        }
      />
      <HiddenField
        name={`txtPrevPz_mailsort_${props.preCount}`}
        inputId={`txtPrevPz_mailsort_${props.preCount}`}
        inputValue={
          state.getDetails !== "#" ? state.getDetails.pz_mailsort : ""
        }
      />
      <HiddenField
        name={`txtPrevStreet_${props.preCount}`}
        inputId={`txtPrevStreet_${props.preCount}`}
        inputValue={
          state.getDetails.line_3 !== "#" ? state.getDetails.line_3 : ""
        }
      />
    </>
  );

  useEffect(() => {
    dispatch({
      type: "resetaddress",
      get_address: [],
    });
  }, [props.skipClickStatus]);


  return (
    <>
      <fieldset className='field-set mb-3'>
        <legend className='mb-1 fs-6'>{props.prevAddrs}</legend>
        <div className="form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
          {/* <label className="mb-1 fs-6">Enter Postcode</label> */}
          <AddressTextField
            type="text"
            placeholder="Post Code"
            name={`txtPrevPostCode_${props.preCount}`}
            id={`txtPrevPostCode_${props.preCount}`}
            className="form-control  hero-input"
            dataId={`txtPrevPostCode_${props.preCount}`}
            onBlur={(e)=>{e.target.value = e.target.value.trim();checkValidation(e);}}
            autoComplete="off"
            validation={props.validation({
              required: "Please Enter Postcode",
              pattern: {
                // value:
                //   /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i,
                value: /^[A-Za-z0-9 ]+$/,
                message: "Please Enter a Valid Postcode",
              },
              minLength: {
                value: 5,
                message: "Please Enter a Valid Postcode",
              },
            })}
            validationMsg={
              props.validationMsg[`txtPrevPostCode_${props.preCount}`] && props.validationMsg[`txtPrevPostCode_${props.preCount}`].message
            }
            onChange={() => {
              props.clearErrors(`txtPrevPostCode_${props.preCount}`);
            }}
          />
        </div>
        <div className="form-navigation pt-3 animate__animated animate__fadeInUp animate__delay-.5s" id="find_btn1">
          <ul>
            <li>
              {state.lookUp && (
                <InputButton
                  name="submit0"
                  className="btn center-block next-but sctop"
                  id="findADD1"
                  value="Find Address"
                  btnType="button"
                  style={{ float: "none" }}
                  onClick={lookupValidation}
                />
              )}
            </li>
          </ul>
        </div>
        {state.checkSelect && <div id="currentAddressCollapse1" >
          <div className="form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s" id="selectADD1">
            <label className="mb-1 fs-6">Select Address</label>
            <AddressSelectBox
              className="form-control"
              OptionValue={state.getAddress}
              name={`txtPrevAddress1_${props.preCount}`}
              id={`txtPrevAddress1_${props.preCount}`}
              onChange={getValue}
              myRef={props.validation({ required: "Please Select Address", message: "Please Select Address" })}
              validationMsg={
                props.validationMsg[`txtPrevAddress1_${props.preCount}`] && props.validationMsg[`txtPrevAddress1_${props.preCount}`].message
              }
            />
          </div>
          <div id="fullAddress" className={`col-lg-12 ${state.showManualAddress}`}>
            {state.getDetails.line_1 != '' &&
              <div className="form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
                <AddressTextField
                  type="text"
                  placeholder="Address Line 1 *"
                  name={`txtPrevHouseNumber_${props.preCount}`}
                  id={`txtPrevHouseNumber_${props.preCount}`}
                  className="form-control address hero-input"
                  dataId={`txtPrevHouseNumber_${props.preCount}`}
                  autoComplete="off"
                  validation={props.validation({
                    required: "Please Enter Address Line 1",
                  })}
                  validationMsg={
                    props.validationMsg[`txtPrevHouseNumber_${props.preCount}`] &&
                    props.validationMsg[`txtPrevHouseNumber_${props.preCount}`].message
                  }
                  value={state.getDetails.line_1}
                  readonly={true}
                />
              </div>}
            {state.getDetails.line_2 != '' &&
              <div className="form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
                <AddressTextField
                  type="text"
                  placeholder="Address Line 2"
                  name={`txtPrevAddress2_${props.preCount}`}
                  id={`txtPrevAddress2_${props.preCount}`}
                  className="form-control  address hero-input"
                  dataId={`txtPrevAddress2_${props.preCount}`}
                  autoComplete="off"
                  validation={props.validation({
                    required: "Select Address",
                    message: "Please Select Address",
                  })}
                  value={state.getDetails.line_2}
                  readonly={true}
                />
              </div>}
            {state.getDetails.line_3 != '' &&
              <div className="form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
                <AddressTextField
                  type="text"
                  placeholder="Address Line 3"
                  name={`txtPrevAddress3_${props.preCount}`}
                  id={`txtPrevAddress3_${props.preCount}`}
                  className="form-control  address hero-input"
                  dataId={`txtPrevAddress3_${props.preCount}`}
                  autoComplete="off"
                  validation={props.validation({
                    required: "Select Address",
                    message: "Please Select Address",
                  })}
                  value={state.getDetails.line_3}
                  readonly={true}
                />
              </div>}
            {state.getDetails.county != '' &&
              <div className="form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
                <AddressTextField
                  type="text"
                  placeholder="County"
                  name={`txtPrevCounty_${props.preCount}`}
                  id={`txtPrevCounty_${props.preCount}`}
                  className="form-control  address hero-input"
                  dataId={`txtPrevCounty_${props.preCount}`}
                  autoComplete="off"
                  validation={props.validation()}
                  validationMsg=""
                  value={state.getDetails.county}
                  readonly={true}
                />
              </div>}
            {state.getDetails.town != '' &&
              <div className="form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
                <AddressTextField
                  type="text"
                  placeholder="Town *"
                  name={`txtPrevTown_${props.preCount}`}
                  id={`txtPrevTown_${props.preCount}`}
                  className="form-control  address hero-input"
                  dataId={`txtPrevTown_${props.preCount}`}
                  autoComplete="off"
                  validation={props.validation({ required: "Please Enter Town" })}
                  validationMsg={
                    props.validationMsg[`txtPrevTown_${props.preCount}`] && props.validationMsg[`txtPrevTown_${props.preCount}`].message
                  }
                  value={state.getDetails.town}
                  readonly={true}
                />
              </div>}
            {state.getDetails.country != '' &&
              <div className="form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
                <AddressTextField
                  type="text"
                  placeholder="Country *"
                  name={`txtPrevCountry_${props.preCount}`}
                  id={`txtPrevCountry_${props.preCount}`}
                  className="form-control address hero-input"
                  dataId={`txtPrevCountry_${props.preCount}`}
                  autoComplete="off"
                  validation={props.validation({ required: "Please Enter Country" })}
                  validationMsg={
                    props.validationMsg[`txtPrevCountry_${props.preCount}`] && props.validationMsg[`txtPrevCountry_${props.preCount}`].message
                  }
                  value={state.getDetails.country}
                  readonly={true}
                />
              </div>}
            {/* non visible fields */}
            <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Building Name"
                name={`txtPrevBuildingName_${props.preCount}`}
                id={`txtPrevBuildingName_${props.preCount}`}
                className="form-control address hero-input"
                dataId={`txtPrevBuildingName_${props.preCount}`}
                autoComplete="off"
                validation={props.validation()}
                // validationMsg={
                //   props.validationMsg[`txtPrevBuildingName_${props.preCount}`] && props.validationMsg[`txtPrevBuildingName_${props.preCount}`].message
                // }
                value={state.getDetails?.RawAddress?.BuildingName}
                readonly={true}
              />
            </div>
            <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Building Number"
                name={`txtPrevBuildingNumber_${props.preCount}`}
                id={`txtPrevBuildingNumber_${props.preCount}`}
                className="form-control address hero-input"
                dataId={`txtPrevBuildingNumber_${props.preCount}`}
                autoComplete="off"
                validation={props.validation()}
                // validationMsg={
                //   props.validationMsg[`txtPrevBuildingNumber_${props.preCount}`] && props.validationMsg[`txtPrevBuildingNumber_${props.preCount}`].message
                // }
                value={state.getDetails?.RawAddress?.BuildingNumber}
                readonly={true}
              />
            </div>
            <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Thoroughfare"
                name={`txtPrevThoroughfare_${props.preCount}`}
                id={`txtPrevThoroughfare_${props.preCount}`}
                className="form-control address hero-input"
                dataId={`txtPrevThoroughfare_${props.preCount}`}
                autoComplete="off"
                validation={props.validation()}
                // validationMsg={
                //   props.validationMsg[`txtPrevThoroughfare_${props.preCount}`] && props.validationMsg[`txtPrevThoroughfare_${props.preCount}`].message
                // }
                value={state.getDetails?.RawAddress?.ThoroughfareName}
                readonly={true}
              />
            </div>
            <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Sub building name"
                name={`txtPrevSubBuildingName_${props.preCount}`}
                id={`txtPrevSubBuildingName_${props.preCount}`}
                className="form-control address hero-input"
                dataId={`txtPrevSubBuildingName_${props.preCount}`}
                autoComplete="off"
                validation={props.validation()}
                // validationMsg={
                //   props.validationMsg[`txtPrevSubBuildingName_${props.preCount}`] && props.validationMsg[`txtPrevSubBuildingName_${props.preCount}`].message
                // }
                value={state.getDetails?.RawAddress?.SubBuildingName}
                readonly={true}
              />
            </div>
            <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Sub building number"
                name={`txtPrevSubBuildingNumber_${props.preCount}`}
                id={`txtPrevSubBuildingNumber_${props.preCount}`}
                className="form-control address hero-input"
                dataId={`txtPrevSubBuildingNumber_${props.preCount}`}
                autoComplete="off"
                validation={props.validation()}
                // validationMsg={
                //   props.validationMsg[`txtPrevSubBuildingNumber_${props.preCount}`] && props.validationMsg[`txtPrevSubBuildingNumber_${props.preCount}`].message
                // }
                value={state.getDetails?.RawAddress?.SubBuildingNumber}
                readonly={true}
              />
            </div>
            <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
                <AddressTextField
                  type="text"
                  placeholder="Address Line 4"
                  name={`txtPrevAddress4_${props.preCount}`}
                  id={`txtPrevAddress4_${props.preCount}`}
                  className="form-control  address hero-input"
                  dataId={`txtPrevAddress4_${props.preCount}`}
                  autoComplete="off"
                  validation={props.validation({
                    required: "Select Address",
                    message: "Please Select Address",
                  })}
                  value={state.getDetails.line_4}
                  readonly={true}
                />
              </div>
              <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Locality"
                name={`txtPrevLocality_${props.preCount}`}
                id={`txtPrevLocality_${props.preCount}`}
                className="form-control address hero-input"
                dataId={`txtPrevLocality_${props.preCount}`}
                autoComplete="off"
                validation={props.validation()}
                value={state.getDetails?.RawAddress?.Locality}
                readonly={true}
              />
            </div>
            <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="District"
                name={`txtPrevDistrict_${props.preCount}`}
                id={`txtPrevDistrict_${props.preCount}`}
                className="form-control address hero-input"
                dataId={`txtPrevDistrict_${props.preCount}`}
                autoComplete="off"
                validation={props.validation()}
                value={state.getDetails?.RawAddress?.Location?.District}
                readonly={true}
              />
            </div>
            <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Thoroughfare Description"
                name={`txtPrevThoroughfareDesc_${props.preCount}`}
                id={`txtPrevThoroughfareDesc_${props.preCount}`}
                className="form-control address hero-input"
                dataId={`txtPrevThoroughfareDesc_${props.preCount}`}
                autoComplete="off"
                validation={props.validation()}
                value={state.getDetails?.RawAddress?.ThoroughfareDesc}
                readonly={true}
              />
            </div>
            <div className="d-none">
              <AddressTextField
                type="text"
                name={`txtPrevOrganisation_${props.preCount}`}
                id={`txtPrevOrganisation_${props.preCount}`}
                value={state.getDetails?.RawAddress?.Organisation}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name={`txtPrevDepartment_${props.preCount}`}
                id={`txtPrevDepartment_${props.preCount}`}
                value={state.getDetails?.RawAddress?.Department}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name={`txtPrevAddressKey_${props.preCount}`}
                id={`txtPrevAddressKey_${props.preCount}`}
                value={state.getDetails?.RawAddress?.AddressKey}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name={`txtPrevOrganisationKey_${props.preCount}`}
                id={`txtPrevOrganisationKey_${props.preCount}`}
                value={state.getDetails?.RawAddress?.OrganisationKey}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name={`txtPrevPostcodeType_${props.preCount}`}
                id={`txtPrevPostcodeType_${props.preCount}`}
                value={state.getDetails?.RawAddress?.PostcodeType}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name={`txtPrevDependentThoroughfareName_${props.preCount}`}
                id={`txtPrevDependentThoroughfareName_${props.preCount}`}
                value={state.getDetails?.RawAddress?.DependentThoroughfareName}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name={`txtPrevDependentThoroughfareDesc_${props.preCount}`}
                id={`txtPrevDependentThoroughfareDesc_${props.preCount}`}
                value={state.getDetails?.RawAddress?.DependentThoroughfareDesc}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name={`txtPrevDoubleDependentLocality_${props.preCount}`}
                id={`txtPrevDoubleDependentLocality_${props.preCount}`}
                value={state.getDetails?.RawAddress?.DoubleDependentLocality}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name={`txtPrevDependentLocality_${props.preCount}`}
                id={`txtPrevDependentLocality_${props.preCount}`}
                value={state.getDetails?.RawAddress?.DependentLocality}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name={`txtPrevDps_${props.preCount}`}
                id={`txtPrevDps_${props.preCount}`}
                value={state.getDetails?.RawAddress?.Dps}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name={`txtPrevPoBox_${props.preCount}`}
                id={`txtPrevPoBox_${props.preCount}`}
                value={state.getDetails?.RawAddress?.PoBox}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name={`txtPrevPostalCounty_${props.preCount}`}
                id={`txtPrevPostalCounty_${props.preCount}`}
                value={state.getDetails?.RawAddress?.PostalCounty}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name={`txtPrevTraditionalCounty_${props.preCount}`}
                id={`txtPrevTraditionalCounty_${props.preCount}`}
                value={state.getDetails?.RawAddress?.TraditionalCounty}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name={`txtPrevAdministrativeCounty_${props.preCount}`}
                id={`txtPrevAdministrativeCounty_${props.preCount}`}
                value={state.getDetails?.RawAddress?.AdministrativeCounty}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name={`txtPrevCountryISO2_${props.preCount}`}
                id={`txtPrevCountryISO2_${props.preCount}`}
                value={state.getDetails?.CountryISO2}
                validation={props.validation()}
              />
            </div>
            {Object.keys(state.getDetails).length > 0 ? <HiddenFields /> : null}
          </div>
        </div>}
      </fieldset>
    </>
  );
}

export default PreviousPostCode;