import React, { useContext, useState,useRef } from 'react';
import SelectBox from '../UI/SelectBox';
import { Salutation } from "../../Constants/Constants";
import { DobYear, DobMonth, DobDay } from '../../Constants/Constants';
import Telephone from '../UI/Telephone';
import TextField from '../UI/TextField';
import Email from '../UI/Email';
import { numberValidation, nameValidate, slideValidation, firstNameValidate,calculateAge,profanityValidation } from '../../Utility/Validations';
import { usePhoneValidation } from "../../Hooks/usePhoneValidation";
import { useEmailValidation } from "../../Hooks/useEmailValidation";
import PostCodeEdit from './PostCodeEdit';
import Button from '../UI/Button';
import EditInfo from "../../assets/img/TCGL_V_A1/editinfo.png";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { monthMapping } from '../../Constants/Constants';
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import { getQueryString } from '../../Utility/QueryString';
import { dobValidation } from "../../Utility/Validations";
 
const EditSection = (props) => {
console.log(props,"propssss");
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const [contactValidation, setContactValidation] = useState({ 'txtEmail': false, 'txtPhone': false });
  const errorFocus = useRef();
  const queryString = getQueryString();
  const splitName = new URLSearchParams(queryString).get('splitName');
  const [valids, setValids] = useState('');
  const [dobValues, setDobValues] = useState({
    DobDay: props.dobDay,
    DobMonth: props.dobMonth,
    DobYear: props.dobYear,
  });
  const [salutation , setStalutation] = useState(props.salutation)
  const emailValidCheck = async () => {
    const values = props.getValues();
    const email = values["txtEmail"];
    if (email !== "") {
      const getEmailValidation = await emailValidation(
        email,
        visitorParameters.visitor_parameters.uuid
      );
      if (getEmailValidation.data.status_code === 1) {
        setContactValidation((prevState) => ({
          ...prevState,
          txtEmail: true,
        }));
        props.clearErrors("txtEmail");
        return 1;
      } else {
        setContactValidation((prevState) => ({
          ...prevState,
          txtEmail: false,
        }));
        props.setError("txtEmail", {
          type: "manual",
          message: "Invalid Email",
        });
      }
    } else {
      props.setError("txtEmail", {
        type: "manual",
        message: "Please Enter Valid Email",
      });
    }
  };

  const phoneValidCheck = async () => {
    const values = props.getValues();
    const phone = values["txtPhone"].replace(/^0+/, '');
    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(
        phone,
        visitorParameters.visitor_parameters.uuid
      );
      if (getPhoneValidation.data.status_code === 0) {
        setContactValidation((prevState) => ({
          ...prevState,
          txtPhone: false,
        }));
        props.setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 2) {
        setContactValidation((prevState) => ({
          ...prevState,
          txtPhone: false,
        }));
        props.setError("txtPhone", {
          type: "manual",
          message: "Phone Number Already Exist",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 3) {
        setContactValidation((prevState) => ({
          ...prevState,
          txtPhone: false,
        }));
        props.setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        return 0;
      } else {
        setContactValidation((prevState) => ({
          ...prevState,
          txtPhone: true,
        }));
        props.clearErrors("txtPhone");
        return 1;
      }
    } else {
      props.setError("txtPhone", {
        type: "manual",
        message: "Please Enter Valid Phone Number",
      });
      return 0;
    }
  };

  const eventOnchange = (e) => {
    var field = e.target.id;
    setContactValidation((prevState) => ({
      ...prevState,
      [field]: false,
    }));
    if ((e.target.name = "txtphone" && e.target.value.length >= 11))
      phoneValidCheck();
  };

  // const handleFormSubmit = async (e) => {
  //   alert("validationCheck1")
  //   let validationStatus = 0;

  //   const validationArr = ["txtPostCode", "address1",
  //     //  "txtEmail",
  //      "txtPhone",'DobDay', 'DobMonth', 'DobYear'];
  //   const values = props.getValues();

  //   const validationCheck1 = await slideValidation(validationArr, props.trigger);
  //   console.log(validationCheck1);
  //     if (validationCheck1 == 'Success') {
  //       var validDate = dobValidation(values.DobDay, values.DobMonth, values.DobYear);
  //       var ageCalculate = calculateAge(values.DobDay+ '-' + values.DobMonth + '-' + values.DobYear );
  //       console.log(validDate,ageCalculate);

  //       if(validDate == false){
  //         setValids('Please enter a valid date');
  //       }else if(ageCalculate < 20){
  //         setValids('Sorry – you need to be over 20 to use our services');
  //       }
  //       else{
  //         setValids('');
  //         props.slideChange(e);
  //       }
  //   }
  
  //   props.setPostCode([values['txtPostCode']]);
  //   setValids('');
  //   const validationCheck = await slideValidation(validationArr, props.trigger);

  //   if (validationCheck == 'Success') {

  //     // let emailResponse = await emailValidCheck();
  //     let phoneResponse = await phoneValidCheck();
  //     if (phoneResponse != 1) {
  //       document.getElementById("txtPhone").focus();
  //     }
  //     if (
  //       // emailResponse == 1 && 
  //       phoneResponse == 1) {
  //       validationStatus = 1;
  //     } else {
  //       validationStatus = 0;
  //     }

  //     if (validationStatus == 1) {
  //       const storedFormData = JSON.parse(localStorage.getItem("formData")) || {};
  //       const formData = {...storedFormData, ...values};
  //       localStorage.setItem("formData", JSON.stringify(formData));
  //       if (
  //         visitorParameters.data !== null ||
  //         visitorParameters.data !== undefined
  //       ) {
  //         // const formSUbmitResult = await saveDataIngestion(
  //         //   visitorParameters.visitor_parameters,
  //         //   formData,
  //         //   "edit_form_submit",
  //         //   splitName,
  //         //   queryString,
  //         //   visitorParameters.data
  //         // );
  //         props.setShowEdit3();
  //         window.scrollTo(0, 0);
  //       }
  //     }
  //   };
  // }

  // const updateDobValue = (e) => {
  //   const values = props.getValues();
  //   setDobValues((prevValues) => ({
  //     ...prevValues,
  //     [e.target.name]: e.target.value,
  //   }));

  //   if (
  //     values.DobDay !== "" &&
  //     values.DobMonth !== "" &&
  //     values.DobYear !== ""
  //   ) {
  //     var validDate = dobValidation(
  //       values.DobDay,
  //       values.DobMonth,
  //       values.DobYear
  //     );
  //     if (!validDate) {
  //       props.setError(e.target.name, {
  //         type: "manual",
  //         message: "Please Enter Valid Date",
  //       });
  //       setDobValues((prevValues) => ({
  //         ...prevValues,
  //         [e.target.name]: "",
  //       }));
  //     } else if (age < 20) {
  //       props.setError(e.target.name, {
  //         type: "manual",
  //         message: "You need to be at least 20 years old",
  //       });
  //       setDobValues((prevValues) => ({
  //         ...prevValues,
  //         [e.target.name]: "",
  //       }));
  //     } else {
  //       setValids("");
  //     }
  //   }
    
  // };


  const handleFormSubmit = async (e) => {
    const validationArr = ["txtPostCode", "address1", "txtPhone", "DobDay", "DobMonth", "DobYear"];
    const values = props.getValues();
    if (!values.txtFName) {
      document.getElementById("txtFName").focus();
      props.setError("txtFName", {
        type: "manual",
        message: "Please Enter First Name",
      });
      return;
    }
    if (!values.txtLName) {
      document.getElementById("txtLName").focus(); 
      props.setError("txtLName", {
        type: "manual",
        message: "Please Enter Last Name",
      });
      return;
    }
  
    if (!values.lstSalutation) {
      document.getElementById("lstSalutation").focus(); 
      props.setError("lstSalutation", {
        type: "manual",
        message: "Please Select Title",
      });
      return;
    }
  
    const validationCheck1 = await slideValidation(validationArr, props.trigger);
    const profinityCheck = profanityValidation(['txtFName', 'txtLName'],  props.getValues)
    if(profinityCheck != 'success'){
      props.setError(profinityCheck, {
        type: "manual",
        message: "Please enter a valid name!.",
      })
      return;
    }
    if (validationCheck1 === "Success") {
      const dobString = `${values.DobYear}-${values.DobMonth}-${values.DobDay}`;
      const validDate = dobValidation(values.DobDay, values.DobMonth, values.DobYear);
      const age = calculateAge(dobString);
  
      if (!validDate) {
        setValids("Please enter a valid date");
        return;
      } else if (age < 20) {
        setValids("Sorry – you need to be over 20 to use our services");
        return;
      }else {
        setValids("");
        if (props.slideChange) {
          props.slideChange(e); // Call slideChange if it exists
        } else {
          console.warn("slideChange is not defined");
        }
      }
    }

    let updatedPostCode = (props.postcodeval) ? props.postcodeval : values["txtPostCode"];
    props.setPostCode([updatedPostCode]);
    props.setPostcodeval(updatedPostCode);
    setValids("");
  
    const validationCheck = await slideValidation(validationArr, props.trigger);
    if (validationCheck === "Success") {
      const phoneResponse = await phoneValidCheck();
      if (phoneResponse !== 1) {
        document.getElementById("txtPhone").focus();
        return;
      }
  
      const storedFormData = JSON.parse(localStorage.getItem("formData")) || {};
      // Add 'dobChanged' to determine whether the dob has been modified on the edit page.
      values['dobChanged'] = parseInt(values.DobDay, 10) !== parseInt(storedFormData.DobDay, 10) || values.DobMonth !== storedFormData.DobMonth || values.DobYear !== storedFormData.DobYear ? "true" : "false";
      console.log("dob changed");
      console.log(values['dobChanged']);
      
      const formData = { ...storedFormData, ...values };
      localStorage.setItem("formData", JSON.stringify(formData));
      if (visitorParameters.data) {
        props.setShowEdit3();
        window.scrollTo(0, 0);
      }
    }
  };


  const updateDobValue = (e) => {
    const values = props.getValues();
    setDobValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));

    if (values.DobDay && values.DobMonth && values.DobYear) {
      const dobString = `${values.DobYear}-${values.DobMonth}-${values.DobDay}`;
      const validDate = dobValidation(values.DobDay, values.DobMonth, values.DobYear);
      const age = calculateAge(dobString);
  
      if (!validDate) {
        setValids("Please enter a valid date");
        setDobValues((prevValues) => ({
          ...prevValues,
          [e.target.name]: "",
        }));
      } else if (age < 20) {
        setValids("Sorry – you need to be over 20 to use our services");
        setDobValues((prevValues) => ({
          ...prevValues,
          [e.target.name]: "",
        }));
      } else {
        setValids(""); // Clear the error message if the input is valid
      }
    }
  };
  
  return (
    <>
      <div className={`slide2  ${(props.showEdit2)}`} >
        <div className="form-header d-flex align-items-center">
          <div className="col-lg-2 col-3">
            <img src={EditInfo} alt="" />
          </div>
          <div className="col-lg-10 col-9 bor-l  align-self-center">
            <h4>
              We couldn’t find {" "}
              <br className="d-block d-md-none" />
              <span>your agreements</span>
            </h4>
            <p>Based on the information you have provided we have been unable to identify any car finance agreements. Please check your details so we can check again.</p>
          </div>
        </div>
        <div className="col-12">
          <div className="row  ps-0 pe-0">
            <p>
              <strong>Edit your Name</strong>
            </p>
            <p style={{ fontSize: "14px" }}>This should be your name as recorded on your identification documentation. Please do not use any nicknames.</p>
            <div className="col-lg-6 mb-2">
              <SelectBox
                className="form-select select1"
                OptionValue={Salutation}
                name="lstSalutation"
                id="lstSalutation"
                myRef={props.validation({
                  required: "Select Title",
                  message: "Please Select Title",
                })}
                validationMsg={props.validationMsg.lstSalutation?.message}
                onChange={(e) => {
                  props.clearErrors(`lstSalutation`);
                  setStalutation(e.target.value);
                }}
                value={salutation}
              />
            </div>
            <div className="col-lg-6 mb-2">
              <TextField
                type="text"
                className="form-control"
                placeholder="First Name"
                name="txtFName"
                id="txtFName"
                autocomplete="off"
                onkeypress={(e) => firstNameValidate(e)}
                validation={props.validation({
                  required: "Please Enter First Name",
                  minLength: {
                    value: 3,
                    message: "Please Enter Valid First Name",
                  },
                  pattern: {
                    value: /^[a-zA-Z\s]+$/,
                    message: "Your Name is Invalid",
                  },
                })}
                validationMsg={props.validationMsg.txtFName?.message}
                onChange={() => {
                  props.clearErrors(`txtFName`);
                }}
                value={props.firstName}
              />
            </div>
            {/* <div className="col-lg-6 mb-2">
              <TextField
                type="text"
                className="form-control  "
                placeholder="Middle Name"
                name="txtMName"
                id="txtMName"
                autocomplete="off"
                onkeypress={(e) => nameValidate(e)}
                validation={props.validation({
                  required: "Please Enter Middle Name",
                  minLength: {
                    value: 3,
                    message: "Please Enter Valid Middle Name",
                  },
                  pattern: {
                    value: /^[a-zA-Z\s]+$/,
                    message: "Your Name is Invalid",
                  },
                })}
                onChange={() => {
                  props.clearErrors(`txtMName`);
                }}
                value={props.middleName}
              />
            </div> */}
            <div className="col-lg-6 mb-2">
              <TextField
                type="text"
                className="form-control "
                placeholder="Last Name"
                name="txtLName"
                id="txtLName"
                autocomplete="off"
                onkeypress={(e) => nameValidate(e)}
                validation={props.validation({
                  required: "Please Enter Last Name",
                  minLength: {
                    value: 3,
                    message: "Please Enter Valid Last Name",
                  },
                  pattern: {
                    value: /^[a-zA-Z\s]+$/,
                    message: "Your Name is Invalid",
                  },
                })}
                validationMsg={props.validationMsg.txtLName?.message}
                onChange={() => {
                  props.clearErrors(`txtLName`);
                }}
                value={props.lastName}
              />
            </div>
          </div>
          <hr />
          <div className="row  ps-0 pe-0"  ref={errorFocus}>
            <p>
              <strong>Edit your Date of Birth</strong>
            </p>
            <div className="col-lg-4">
              <SelectBox
                className="form-select mb-3"
                OptionValue={DobDay}
                name="DobDay"
                id="DobDay"
                myRef={props.validation({
                  required: "Please Select Date"
                })}
                validationMsg={props.validationMsg.DobDay && props.validationMsg.DobDay.message}
                onChange={(e) => {
                  props.clearErrors(`DobDay`);
                  updateDobValue(e);
                }}
                value={Number(dobValues.DobDay)}
              />
            </div>
            <div className="col-lg-4">
              <SelectBox
                className="form-select mb-3"
                OptionValue={DobMonth}
                name="DobMonth"
                id="DobMonth"
                myRef={props.validation({
                  required: "Please Select Month"
                })}
                validationMsg={props.validationMsg.DobMonth && props.validationMsg.DobMonth.message}
                onChange={(e) => {
                  props.clearErrors(`DobMonth`);
                  updateDobValue(e);
                }}
                value={dobValues.DobMonth}
              />
            </div>
            <div className="col-lg-4">
              <SelectBox
                className="form-select mb-3"
                OptionValue={DobYear}
                name="DobYear"
                id="DobYear"
                myRef={props.validation({
                  required: "Please Select Year"
                })}
                validationMsg={props.validationMsg.DobYear && props.validationMsg.DobYear.message}
                onChange={(e) => {
                  props.clearErrors(`DobYear`);
                  updateDobValue(e);
                }}
                value={dobValues.DobYear}
              />
            </div>
            {valids != '' && (<span className="error_msg">{valids}</span>)}
          </div>
          <hr />
          {/* <div className="col-lg-12 mb-3  ">
            <p>
              <strong>Email Address</strong>
            </p>
            <Email
              readOnly={true}
              name="txtEmail"
              className="form-control mb-0 privacyg pr-guard"
              id="txtEmail"
              placeholder="Enter Email Address"
              onBlur={emailValidCheck}
              onKeyPress={(e) => {
                if (e.key === " ") e.preventDefault();
              }}
              onChange={(e) => {
                props.clearErrors("txtEmail");
                eventOnchange(e);
              }}
              validation={props.validation({
                required: "Please Enter Valid Email Address",
              })}
              validationMsg={
                props.validationMsg.txtEmail &&
                props.validationMsg.txtEmail.message
              }
              value={props.email}
            ></Email>
          </div> */}
          {/* <hr /> */}
          <div className="col-lg-12 mb-3  ">
            <p>
              <strong>Edit your Mobile Number</strong>
            </p>
            <Telephone
              name="txtPhone"
              id="txtPhone"
              className="form-control privacyg mb-0 pr-guard"
              placeholder="Enter Mobile Number"
              onBlur={phoneValidCheck}
              onChange={(e) => {
                props.clearErrors("txtPhone");
                eventOnchange(e);
              }}
              onKeyPress={(e) => {
                numberValidation(e);
              }}
              maxlength="11"
              minLength="11"
              validation={props.validation({
                pattern: {
                  value: /^[0-9]*$/i,
                  message: "Please Enter Valid Phone Number",
                },
                required: "Please Enter Phone Number",
              })}
              validationMsg={
                props.validationMsg.txtPhone &&
                props.validationMsg.txtPhone.message
              }
              value={props.phone}
            ></Telephone>
          </div>
          <hr />

          <div className="row mb-3   ps-0 pe-0">
            <p>
              <strong>Edit your Current Address</strong>
            </p>
            <PostCodeEdit
              validation={props.validation}
              validationMsg={props.validationMsg}
              trigger={props.trigger}
              clearErrors={props.clearErrors}
              getValues={props.getValues}
              setError={props.setError}
              setPostcodeval={props.setPostcodeval}
              postcodeval={props.postcodeval}
            />
          </div>
          <hr />
        </div>
        <div className="col-12 row justify-content-end">
          <div className="col-12 col-lg-8 pr-0">
            <Button
              type="button"
              className="btn-banner btn-fullwidth mt-4 next-slide3"
              buttonText={
                <>
                  Submit &gt;
                </>
              }
              // onClick={props.setShowEdit3}
              onClick={handleFormSubmit}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default EditSection;