import React, { useReducer, useState } from 'react';
import "../../assets/css/TCGL_V_A1/edit-details.scss";
import "../../assets/css/TCGL_V_A1/custom.scss";
import "../../assets/css/TCGL_V_A1/personal-details.scss"
import DisplayDetails from './DisplayDetails';
import EditSection from './EditSection';
import QuestionSection from './QuestionSection';
import { useForm } from "react-hook-form";
import Header from '../Includes/Layouts/Header/CommonHeader';
import Footer from '../Includes/Layouts/TCGL_V_A1/Footer';
import GetVisitorsParams from '../../Utility/GetVisitorsParams';
import CommonIcons from '../Includes/Layouts/TCGL_V_A1/CommonIcons';

const EditDetails = () => {
    const [postcodeval, setPostcodeval] = useState(null);
    console.log(postcodeval,"postcodeval")
    const initialState = {
        showEdit: "d-none",
        showEdit2: "d-block",
        showEdit3: "d-none",
        showEdit4: "d-none",
    };
    const reducer = (state, action) => {
        switch (action.type) {
            case "SHOW_EDIT":
                return {
                    ...state,
                    showEdit: "d-block",
                    showEdit2: "d-none",
                    showEdit3: "d-none",
                    showEdit4: "d-none",
                };
            case "SHOW_EDIT2":
                return {
                    ...state,
                    showEdit: "d-none",
                    showEdit2: "d-block",
                    showEdit3: "d-none",
                    showEdit4: "d-none",
                };
            case "SHOW_EDIT3":
                return {
                    ...state,
                    showEdit: "d-none",
                    showEdit2: "d-none",
                    showEdit3: "d-block",
                    showEdit4: "d-none",
                };
            case "SHOW_EDIT4":
                return {
                    ...state,
                    showEdit: "d-none",
                    showEdit2: "d-none",
                    showEdit3: "d-block",
                    showEdit4: "d-block",
                };
            default:
                return state;
        }
    };
    const [state, dispatch] = useReducer(reducer, initialState);
    const { register, errors, trigger, setError, clearErrors, getValues, setValue } =
        useForm({
            mode: "onBlur",
            reValidateMode: "onChange",
            defaultValues: {},
            resolver: undefined,
            context: undefined,
            criteriaMode: "firstError",
            shouldFocusError: true,
            shouldUnregister: true,
        });
    const formData = JSON.parse(localStorage.getItem('formData'));
    const [postCode,setPostCode] = useState([formData?.txtPostCode]);
    console.log(postCode,"postCodeggggg")
     const monthMapping = {
        January: 1,
        February: 2,
        March: 3,
        April: 4,
        May: 5,
        June: 6,
        July: 7,
        August: 8,
        September: 9,
        October: 10,
        November: 11,
        December: 12,
      };
    const dobMonth = formData?.DobMonth;
    const monthNumber = monthMapping[dobMonth];
    const dobDay = formData?.DobDay;
    const dobYear = formData?.DobYear;
    let formattedDay = dobDay && !isNaN(dobDay) ? String(parseInt(dobDay, 10)).padStart(2, '0') : "";
    let formattedMonth = monthNumber && !isNaN(monthNumber) ? String(monthNumber).padStart(2, '0') : "";
    let formattedYear = dobYear && !isNaN(dobYear) ? String(parseInt(dobYear, 10)) : "";
    // const dateOfBirth = `${formattedDay}-${formattedMonth}-${formattedYear}`;
    const dateOfBirth = formattedDay && formattedMonth && formattedYear
    ? `${formattedDay}-${formattedMonth}-${formattedYear}`
    : "";
    return (
        <>
            <GetVisitorsParams />
            <div className='edit-page TCGL_V_A1'>
                <Header />
                <section className="editpage-banner PersonalDetails">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12  align-self-center order-lg-1 order-2 offset-lg-3  ">
                                <form >
                                    <DisplayDetails
                                        firstName={formData?.txtFName}
                                        LastName={formData?.txtLName}
                                        dob={dateOfBirth}
                                        email={formData?.txtEmail}
                                        phone={formData?.txtPhone}
                                        houseNumber={formData?.txtHouseNumber}
                                        address2={formData?.txtAddress2}
                                        address3={formData?.txtAddress3}
                                        address4={formData?.txtAddress4}
                                        county={formData?.txtCounty}
                                        country={formData?.txtCountry}
                                        district={formData?.txtDistrict}
                                        postCode={formData?.txtPostCode}
                                        showEdit={state.showEdit}
                                        setShowEdit2={() => dispatch({ type: "SHOW_EDIT2" })}
                                    />
                                    {state.showEdit2 == "d-block" && <EditSection
                                        showEdit2={state.showEdit2}
                                        validation={register}
                                        validationMsg={errors}
                                        trigger={trigger}
                                        clearErrors={clearErrors}
                                        getValues={getValues}
                                        setError={setError}
                                        setShowEdit3={() => dispatch({ type: "SHOW_EDIT3" })}
                                        firstName={formData?.txtFName}
                                        lastName={formData?.txtLName}
                                        dobDay={formData?.DobDay}
                                        dobMonth={formData?.DobMonth}
                                        dobYear={formData?.DobYear}
                                        email={formData?.txtEmail}
                                        phone={formData?.txtPhone}
                                        address={formData?.txtHouseNumber}
                                        salutation={formData?.lstSalutation}
                                        setPostCode={setPostCode}
                                        middleName={formData?.txtMName}
                                        setPostcodeval={setPostcodeval}
                                        postcodeval={postcodeval}
                                        postCode={postCode}
                                    />}
                                    <QuestionSection
                                        showEdit3={state.showEdit3}
                                        validation={register}
                                        validationMsg={errors}
                                        trigger={trigger}
                                        clearErrors={clearErrors}
                                        getValues={getValues}
                                        setError={setError}
                                        setPostCode={setPostCode}
                                        postCode={postCode}
                                        postcodeval={postcodeval}
                                        setPostcodeval={setPostcodeval}
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <CommonIcons />
                <Footer showMoreContent={true} />
            </div>

        </>
    )
}

export default EditDetails;