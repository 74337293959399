
import SelectBox from '../../../UI/SelectBox';
import { Salutation } from "../../../../Constants/Constants";
import TextField from "../../../UI/TextField";
import Button from "../../../UI/Button";
import { nameValidate, slideValidation, firstNameValidate, profanityValidation } from '../../../../Utility/Validations';
import GTMDataLayer from "../../../../Utility/GTMDataLayer";


const NameWithSalutation = (props) => {
  const handleBasicDetails = async (e) => {
    const validationArr = ['lstSalutation', 'txtFName', 'txtLName'];
    const validationCheck = await slideValidation(validationArr, props.trigger);
    const profinityCheck = profanityValidation(['txtFName', 'txtLName'], props.getValues)
    console.log(profinityCheck,5);
    if(profinityCheck != 'success'){
      props.setError(profinityCheck, {
        type: "manual",
        message: "Please enter a valid name!.",
      })
      return;
    }
    if (validationCheck == 'Success') {
      props.slideChange(e);
    };
  }
  return (

    <div className="drop-in row">
    
      <div className="col-lg-4 mb-lg-2 mb-md-3 mb-sm-0">
        <SelectBox
          className="form-select select1"
          OptionValue={Salutation}
          name="lstSalutation"
          id="lstSalutation"
          myRef={props.validation({
            required: "Select Title",
            message: "Please Select Title",
          })}
          validationMsg={props.validationMsg.lstSalutation?.message}
          onChange={() => {
            props.clearErrors(`lstSalutation`);
          }}
        />
      </div>
      <div className="col-lg-4 ps-lg-0 mb-lg-2 mb-md-3 mb-sm-0">
        <TextField
          type="text"
          className="form-control"
          placeholder="First Name"
          name="txtFName"
          id="txtFName"
          autocomplete="off"
          onkeypress={(e) => firstNameValidate(e)}
          validation={props.validation({
            required: "Please Enter First Name",
            minLength: {
              value: 3,
              message: "Please Enter Valid First Name",
            },
            pattern: {
              value: /^[a-zA-Z\s]+$/,
              message: "Your Name is Invalid",
            },
          })}
          validationMsg={props.validationMsg.txtFName?.message}
          onChange={() => {
            props.clearErrors(`txtFName`);
          }}
        />
      </div>
      <div className="col-lg-4 ps-lg-0 mb-lg-2 mb-md-3 mb-sm-0">
        <TextField
          type="text"
          className="form-control"
          placeholder="Last Name"
          name="txtLName"
          id="txtLName"
          autocomplete="off"
          onkeypress={(e) => nameValidate(e)}
          validation={props.validation({
            required: "Please Enter Last Name",
            minLength: {
              value: 3,
              message: "Please Enter Valid Last Name",
            },
            pattern: {
              value: /^[a-zA-Z\s]+$/,
              message: "Your Name is Invalid",
            },
          })}
          validationMsg={props.validationMsg.txtLName?.message}
          onChange={() => {
            props.clearErrors(`txtLName`);
          }}
        />
      </div>
      

      <div className="col-12 d-flex justify-content-end">
        <Button
          type="button"
          name="slide1"
          className="btn-banner mt-4 next-slide2"
          value="Next"
          onClick={handleBasicDetails}
          buttonText="Next >"
        />
      </div>
    </div>
  )
};

export default NameWithSalutation;
