import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { getQueryString } from '../../Utility/QueryString';
import { getParameterByName } from "../../Utility/Helper";
import ReactPixel from "react-facebook-pixel";
import VisitorParamsContext from "../../Contexts/VisitorParams";

const DuplicateFbPixel = () => {
  const history = useHistory();
  const queryString = getQueryString();
  const field = "pid";
  const { visitorParameters } = useContext(VisitorParamsContext);
  let current_domain = visitorParameters.data.existingdomain;
  const query = new URLSearchParams(window.location.search);
  const splitName = query.get("splitName");
  console.log("dsdssd");
  console.log(queryString);
  if (queryString != null) {
    if (queryString.indexOf("&" + field + "=") !== -1) {
      const pid = getParameterByName(field, queryString);
      const advancedMatching = { em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
      const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      ReactPixel.init(pid, advancedMatching, options);
      ReactPixel.pageView();
      ReactPixel.track("Lead");
      history.push("/followupv2" + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName=" + splitName+ "&atp_sub6="+ "FLP_DUP");
    } else {
      console.log("else case");
    }
  }
  return <></>;
};

export default DuplicateFbPixel;