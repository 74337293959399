import React, { useRef, useState, useReducer, useContext, useEffect } from "react";
import sign_icon from '../../../../assets/img/TCGL_V_A1/sign-icon.png';
import arrow_down from '../../../../assets/img/TCGL_V_A1/arrow-down.png';
import ssl_sec from '../../../../assets/img/TCGL_V_A1/ssl_sec.png';
import "../../../../assets/css/TCGL_V_A1/signature.scss";
import "bootstrap-icons/font/bootstrap-icons.css";
import InputButton from '../../../UI/InputButton';
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { BrowserView, MobileView, deviceType } from "react-device-detect";
import GTMDataLayer from "../../../../Utility/GTMDataLayer";
import { useHistory } from "react-router-dom";
import { CheckUUID } from "../../../../Utility/CheckUUID";
import { useSignature } from "../../../../Hooks/useSignature";
import CommonHeader from "../../../Includes/Layouts/Header/CommonHeader";
import Footer from "../../../Includes/Layouts/TCGL_V_A1/Footer";
import CommonIcons from "../../../Includes/Layouts/TCGL_V_A1/CommonIcons";
import GetVisitorsParams from "../../../../Utility/GetVisitorsParams";
import { getQueryString } from '../../../../Utility/QueryString';
import Button from "../../../UI/Button";
import SignatureCanvasComponent from "../../../Includes/Layouts/Signature/SignatureCanvasComponent";
import CheckBox from "../../../../Components/UI/CheckBox";
import { useFollowupUserSignature } from "../../../../Hooks/useFollowupUserSignature";
import { Anchor } from "react-bootstrap";
import { getBrandName } from "../../../Includes/Layouts/Common/BrandBasedOnDomain";
import * as EnvConstants from "../../../../Constants/EnvConstants";
import axios from "axios";

const initialState = {
  isChecked: false,
  isAgreed: false,
  isCanvasEmpty: false,
  isValid: false,
};

const signatureReducer = (state, action) => {
  switch (action.type) {
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
      };
    }
    case "validSignature": {
      return {
        ...state,
        isValid: action.payload.value,
      };
    }
    default:
      return state;
  }
};

const FollowUpSignatureV2 = () => {

  const { isCheck } = CheckUUID();

  const focusRef = useRef(null);
  const checkboxRef = useRef(null);
  const brandName = getBrandName();
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const signPadRef = useRef();
  const { saveFollowupUserSignature, isLoading } = useFollowupUserSignature();
  const followupData = JSON.parse(localStorage.getItem('followup_data'));  const { saveSignature } = useSignature();
  const message_type = "followup_user_signature";
  const { visitorParameters } = useContext(VisitorParamsContext);
  const queryString = getQueryString();
  const history = useHistory();
  const splitName = new URLSearchParams(queryString).get("splitName");
  const [progressBarWidth, setProgressBarWidth] = useState('90');
  const nextPage = 'followup-credit-analysis-v2';
  const [checkBox, setCheckBox] = useState(true);
  const [confirmBox, setConfirmBox] = useState('');
  const atp_sub2 = localStorage.getItem("atp_sub2") ??  new URLSearchParams(queryString).get("atp_sub2");
  const uuid = visitorParameters.visitor_parameters.uuid ?? localStorage.getItem("uuid") ?? new URLSearchParams(queryString).get("uuid");
  const atp_sub6 = localStorage.getItem("atp_sub6") ??  new URLSearchParams(queryString).get("source");
  const currentPage = "followup-signature-v2";
  const [followupPendingData, setFollowupPendingData] = useState({});
  
  if(!uuid){
    isCheck();
  }
  let pendingLocalData = localStorage.getItem("pendingLocalData");
  pendingLocalData = pendingLocalData ? JSON.parse(pendingLocalData) : {};
  pendingLocalData.is_user_sign = 1;
  const updatedLocalDetails = JSON.stringify(pendingLocalData);

  let pendingArrayData = localStorage.getItem("pendingArrayData");
  pendingArrayData = pendingArrayData ? JSON.parse(pendingArrayData) : {};
  pendingArrayData.is_user_sign = 1;
  const updatedDetails = JSON.stringify(pendingArrayData);

  const handleClear = (e) => {
    e.preventDefault();
    const signPad = signPadRef.current;
    signPad.clear();
  };
  const pageForward = async (buttonClicked) => {
    try {
      const signPad = signPadRef.current;
      if (signPad.isEmpty()) {
        focusRef.current.focus();
        return dispatch({ type: "signPadChange", payload: { value: true } });
      }
      const rpdata = signPad.toData();
      let signLength = 0;
      for (let i = 0; i < rpdata.length; i++) {
        signLength += Object.keys(rpdata[i]).length;
      }
      if (signLength <= 13) {
        focusRef.current.focus();
        return dispatch({ type: "validSignature", payload: { value: true } });
      }
      if(checkBox == false){
        checkboxRef.current.focus();
        setConfirmBox("Please tick to accept in order to proceed");
      }else{

      const response = await saveFollowupUserSignature(
        signPad.getCanvas().toDataURL("image/png"),
        followupData ?? followupPendingData,
        'followup_user_signature',
        visitorParameters.visitor_parameters.uuid
      );

      if (buttonClicked === "nextSign") {

        GTMDataLayer({
          clickEvent: 'slideclick',
          question: 'signature',
          answer: '',
        });
        localStorage.setItem("pendingLocalData", updatedLocalDetails);
        localStorage.setItem("pendingArrayData", updatedDetails);
        // history.push("/" + nextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName=" + splitName);
        history.push(
          "/followup-loader?uuid=" +
          uuid +
          "&current_page=" + currentPage + "&atp_sub2=" +
          atp_sub2 + "&atp_sub6=" +
          atp_sub6 + "&splitName=" + splitName
        );
      }
    }
    } catch (error) {
      console.error("Error submitting signature:", error);
    }
  };

  const handleCheckbox = () => {
    const newCheckBoxState = !checkBox;
    setCheckBox(newCheckBoxState);
    setConfirmBox(newCheckBoxState ? '' : 'Please tick to accept in order to proceed');
  };

  useEffect(() => {
    setFollowupPendingData(followupData);
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, [])

  const viewtcgdoc = async (e) => {
    e.preventDefault();
    try {
      const url  = EnvConstants.AppTCGL_URL;
      const response = await axios.get(url);
      window.open(response.data.url, '_blank');
    } catch (error) {
      console.error('Error fetching the PDF URL:', error);
    }
  };

  return (
    <>
      <GetVisitorsParams />
      <div className="SignatureV6 TCGL_V_A1">
        <CommonHeader />
        <section className="signpage">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12  align-self-center order-lg-1 order-2 offset-lg-3  ">
                <form>
                  <div className="position-relative">
                    <div className="progress" style={{ height: "5px", margin: "5px 0 25px 0" }}>
                      <div className="progress-bar" role="progressbar" style={{ width: "90%" }} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>

                    <div className="form-header d-flex align-items-center">
                      <div className="col-lg-2 col-3"><img src={sign_icon} alt="" /></div>
                      <div className="col-lg-8 col-6 bor-l  align-self-center">
                        <h4>Your<br className="d-block d-md-none" />
                          <span>Signature</span></h4>
                        <p>Please sign below, as close to your handwritten signature as possible</p>
                        <span className="arrow-down " >
                          <img className="a-d" src={arrow_down} height="75px" alt="" />
                        </span>


                      </div>
                      <div className="col-3">
                        <img src={ssl_sec} height="40px" alt="" />


                      </div>
                    </div>

                    <p className="sigp">{brandName} is a trading style of The Claims Guys Legal, who will handle and process your claim.</p>

                    <div className="position-relative signat-text">
                    </div>



                    <div >

                      <div className=" signature-wraper">
                        {/* <canvas className="signaturepad"></canvas> */}
                        <SignatureCanvasComponent deviceType={deviceType} dispatch={dispatch} signPadRef={signPadRef} />
                        {/* <button className="clear-button" type="button"> <i className="bi bi-x-lg"></i> Clear Signature </button> */}
                        <Button
                          name="clear"
                          className="clear-button"
                          id="clear"
                          itag={<><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                          </svg></>}
                          buttonText="X Clear Signature"
                          btnType="button"
                          onClick={(e) => handleClear(e)}
                        />
                      </div>
                        <span ref={focusRef} tabIndex={0}></span>
                        {state.isCanvasEmpty && (
                        <div className="error_msg col-12 text-center sign_msg signature-validation" id="signatures_required">
                          Signature is required!
                        </div>
                      )}
                      {state.isValid && (
                        <div className="error_msg col-12 text-center sign_msg signature-validation" id="signatures_valid">
                          Draw valid signature!
                        </div>
                      )}

                      <div className="col-12 signaturetext">

                        <p>Please read carefully the <Anchor
                          className="text-decoration-none"
                          href=""
                          target="_blank"
                          onClick={viewtcgdoc}
                        >car finance claim documents</Anchor> and statements below before proceeding</p>
                        <ul className="ulclass">
                          <li className="liclass"> I was not fully informed about the commission the above lenders, and their grouped
                            lenders, paid to the car dealerships who arranged my car finance. I authorise The
                            Claims Guy Legal to make claims on my behalf about all car finance agreements I
                            held.</li>
                          <li className="liclass">
                            I authorise The Claims Guys Legal to:
                            <ul className="sub_ul mt-2">
                              <li className="sub_li">raise an information request to the lenders identified to request information to
                                support my claim, including Vehicle and Credit Agreement details, and
                                information about any add-on products. I agree for this information to be released
                                to The Claims Guys Legal; and</li>
                              <li className="sub_li">refer my claim to the Financial Ombudsman Service (FOS), where a satisfactory
                                outcome is not provided; and</li>
                              <li className="sub_li">receive payment of any compensation due on my behalf.</li>
                            </ul>
                          </li>
                          <li className="liclass">I further authorise The Claims Guys Legal to access my credit report through
                            Checkboard Limited, Valid 8 Ltd or another such provider. I understand that by
                            continuing this application, I agree to Checkboard Limited’s{" "}  
                           <Anchor  href="https://products.privasee.io/privacy-portal/65df3edbb7528b00135e84b9"
                              target="_blank">Privacy Policy</Anchor>  and the
                            {" "}<Anchor
                              href="https://checkboard-assets.fra1.cdn.digitaloceanspaces.com/company-policies/terms-and-conditions-59947621.pdf"
                              target="_blank"
                            >
                              Terms and Conditions
                              </Anchor>. I understand that this will not affect my credit score. I agree
                            for my credit report to be used to:
                            <ul
                              className="sub_ul mt-2">
                              <li className="sub_li">confirm the lenders that I held car finance with; and</li>
                              <li className="sub_li">provide evidence to support my claims.</li>
                            </ul>
                          </li><br />
                          {/* <!-- <p>By signing you are confirming that the lenders you have told us about did not inform you of commission.</p> --> */}
                        </ul>
                        <p className="form-check  d-flex mt-3">
                          <CheckBox
                            className="form-check-input pulse-anime"
                            type="checkbox"
                            value=""
                            id="checkboxNoLabel"
                            name="checkboxNoLabel"
                            checked={checkBox}
                            //onChange={() => { setCheckBox(!checkBox); }}
                            onChange={() => {handleCheckbox()}}
                            labelClassName="pe-2"
                          />
                          <label className="pe-2"></label>
                          <span style={{ fontSize: "12px", textAlign: "justify" }}>I have read the car finance claim documents, including the Client Care Letter,
                            Terms of Engagement and Letter of Authority, and the statements above and agree to be
                            bound by them. I agree for my signature to be applied to a Letter of Authority and FOS
                            Complaint Declaration for each lender.
                          </span>

                        </p>
                        {confirmBox && (<span className="error_msg">{confirmBox}</span>)}
                          <span ref={checkboxRef} tabIndex={0}></span>

                      </div>


                      <div className="col-12 row">

                        <div className="col-12 mob_sticky1">
                          {/* <button type="button" className="btn-banner btn-fullwidth mt-4  pulse-anime nextlenderspage">Sign & Submit </button> */}
                          <InputButton
                            className="btn-banner btn-fullwidth mt-4  pulse-anime nextlenderspage next-css"
                            id="nextSign"
                            value="Sign & Submit >"
                            btnType="button"
                            onClick={() => pageForward("nextSign")}
                            name="subRegNo1"
                            aria-label="submitid"
                            style={{ background: '#0c6c0a', border: 'none' }}
                          />
                        </div>
                        <div className="col-12 mb-4 mt-2 text-center">
                          <img src={ssl_sec} height="30px" alt="" />
                        </div>

                      </div>



                    </div>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </section>
        <CommonIcons />
        <div className="btm_sec">
        <Footer  showMoreContent={true} />
        </div>
      </div>

    </>
  )
};

export default FollowUpSignatureV2;
