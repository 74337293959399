import React, { useState, useReducer, useContext, useEffect } from 'react';
import AddressTextField from "../../../UI/AddressTextField";
import AddressSelectBox from "../../../UI/AddressSelectBox";
import InputButton from "../../../UI/InputButton";
import HiddenField from "../../../UI/HiddenField";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import ProgressBar from "../../../Includes/Layouts/TCG_L_C/ProgressBar";
import GTMDataLayer from '../../../../Utility/GTMDataLayer';
import { usePostCodeAddress } from '../../../../Hooks/usePostCodeAddress';
import { slideValidation } from '../../../../Utility/Validations';

const initialState = {
  lookUp: true,
  nextClick: false,
  checkSelect: false,
  getDetails: [],
  getAddress: [],
  showManualAddress: "d-none",
  getPostcode: "#",
  clickManualLink: 0,
};
const postcodeReducer = (state, action) => {
  switch (action.type) {
    case "validationSucess": {
      return {
        ...state,
        lookUp: action.payload.lookup,
        nextClick: action.payload.next_click,
        getPostcode: action.payload.postcode,
        getAddress: action.payload.get_address,
        checkSelect: action.payload.check_select,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "getExtraAddressDetails": {
      return {
        ...state,
        getDetails: action.payload.get_details,
        showManualAddress: action.payload.manual_address,
        nextClick: action.payload.next_click,
      };
    }
    case "showManualDiv": {
      return {
        ...state,
        showManualAddress: "d-block",
        lookUp: false,
        nextClick: true,
        checkSelect: false,
        clickManualLink: action.payload.manual_click,
      };
    }
    case "resetaddress": {
      return {
        ...state,
        getDetails: [],
        getAddress: [],
        showManualAddress: "d-none",
        lookUp: true,
        nextClick: false,
        checkSelect: false,
      };
    }
    default:
      return state;
  }
};
const Postcode = (props) => {
  const { getPostcodeAddress, getSplitPostcodeAddress } = usePostCodeAddress();
  const [state, dispatch] = useReducer(postcodeReducer, initialState);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [postCodeAddress, setPostCodeAddress] = useState([]);
  const [getAddressDetails, setGetAddressDetails] = useState([]);
  const checkValidation = async (e) => {
    e.preventDefault();
    dispatch({
      type: "resetaddress",
      get_address: [],
    });
    const txtPostCode = await props.trigger("txtPostCode");
    if (txtPostCode) {
      await getPostcodeAddress(e.target.value, visitorParameters.visitor_parameters.uuid)
        .then((res) => {
          if (res.data.status === '0') {
            props.setError('txtPostCode', {
              type: 'manual',
              message: 'Please Enter Valid Postcode',
            });

            //setPostCodeError( "Please Enter Valid Postcode");
          }
          if (res.data.length > 0) {
            dispatch({
              type: 'validationSucess',
              payload: {
                lookup: false,
                next_click: true,
                postcode: e.target.value,
                get_address: res.data,
                check_select: true,
                manual_address: 'd-none',
              },
            });
          }
        })
        .catch(function () {
          props.setError('txtPostCode', {
            type: 'manual',
            message: 'Please Enter Valid Postcode',
          });
          // setPostCodeError("Please Enter Valid Postcode");
        });
    }
  };

  useEffect(() => {
    localStorage.removeItem("userSelectedAddress")
  }, [])
  const getValue = async (e) => {
    //handleChange(e);
    e.preventDefault();
    // document.getElementById("address1").classList.remove("anim_ylw");
    // document.getElementById("NextButton").classList.add("anim_ylw");
    props.clearErrors([
      "txtHouseNumber",
      "address1",
      "txtAddress2",
      "txtAddress3",
      "txtCounty",
      "txtTown",
      "txtCountry"
    ]);
    // setPostCodeError("")
    const { name, value } = e.target;
    let storedData = JSON.parse(localStorage.getItem('userSelectedAddress')) || {};
    for (let key in storedData) {
      if (storedData[key] === value && key !== name) {
        props.setError(name, {
          type: "manual",
          message: "The Address cannot be the same as previously entered",
        });
        dispatch({
          type: "getExtraAddressDetails",
          payload: { get_details: [], manual_address: "d-none" },
        });
        setPostCodeAddress([]);

        delete storedData[name];
        localStorage.setItem('userSelectedAddress', JSON.stringify(storedData));
        e.target.value = "";

        return;
      }
    }
    props.clearErrors(name);
    storedData[name] = value;
    localStorage.setItem('userSelectedAddress', JSON.stringify(storedData));
    if (e.target.value != "") {
      const getData = await getSplitPostcodeAddress(
        e.target.value,
        visitorParameters.visitor_parameters.uuid,
      );
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: getData, manual_address: "show", next_click: true },
      });
      setPostCodeAddress(getData);
      props.clearErrors([
        "txtHouseNumber",
        "txtAddress2",
        "txtCounty",
        "txtTown",
        "address1",
        "txtCountry",
      ]);
    } else {
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: [], manual_address: "d-none" },
      });
      setPostCodeAddress([]);
    }
  };
  const lookupValidation = async (e) => {
    await props.trigger("txtPostCode");
  };
  const handleSubmit = async(e) => {
    GTMDataLayer({
      clickEvent: 'slideclick',
      question: 'postcode',
      answer: '',
    });
    const validationArr = ['txtPostCode', 'address1'];
    const validationCheck = await slideValidation(validationArr, props.trigger);
    if (validationCheck == 'Success') {
      props.slideChange(e);
    }
  };
  const HiddenFields = () => (
    <>
      <HiddenField
        name="txtUdprn"
        inputId="txtUdprn"
        inputValue={state.getDetails !== "#" ? state.getDetails.Udprn : ""}
      />
      <HiddenField
        name="txtDeliveryPointSuffix"
        inputId="txtDeliveryPointSuffix"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.deliverypointsuffix : ""
        }
      />
      <HiddenField
        name="txtPz_mailsort"
        inputId="txtPz_mailsort"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.pz_mailsort : ""
        }
      />
      <HiddenField
        name="txtStreet"
        inputId="txtStreet"
        inputValue={
          state.getDetails.line_3 !== "#" ? state.getDetails.line_3 : ""
        }
      />


    </>
  );
  return (
    <>
      <div className={`slide3 ${props.showSlide3}`} id="slide_3">
        <ProgressBar load="load1" />
        <div className="field animate__animated animate__fadeInUp animate__delay-.5s">
          <label className="animate__animated animate__fadeInUp animate__delay-.5s">
            Enter Your Current Residential Postcode</label>
          <div className="form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
            <label className="mb-1 fs-6">Enter Postcode</label>
            <AddressTextField
              type="text"
              placeholder="Post Code"
              name="txtPostCode"
              id="txtPostCode"
              className="form-control  hero-input"
              dataId="txtPostCode"
              onBlur={(e) => { e.target.value = e.target.value.trim(); checkValidation(e); }}
              autoComplete="off"
              validation={props.validation({
                required: "Please Enter Postcode",
                pattern: {
                  // value:
                  //   /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i,
                  value: /^[A-Za-z0-9 ]+$/,
                  message: "Please Enter a Valid Postcode",
                },
                minLength: {
                  value: 5,
                  message: "Please Enter a Valid Postcode",
                },
              })}
              validationMsg={
                props.validationMsg.txtPostCode && props.validationMsg.txtPostCode.message
              }
              onChange={() => {
                props.clearErrors(`txtPostCode`);
              }}
            />
          </div>
          <div className="form-navigation pt-3 animate__animated animate__fadeInUp animate__delay-.5s" id="find_btn" />
          {state.lookUp && (<ul>
            <li>
              <InputButton
                name="submit0"
                className="btn center-block next-but sctop"
                id="findADD"
                value="Find Address"
                btnType="button"
                style={{ float: "none" }}
                onClick={lookupValidation}
              />
            </li>
          </ul>)}
        </div>
        <div id="currentAddressCollapse" style={state.checkSelect ? { display: "block" } : { display: "none" }}>
          <div className="form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s" id="selectADD">
            <label className="mb-1 fs-6">Select Address</label>
            <AddressSelectBox
              className="form-control"
              OptionValue={state.getAddress}
              name="address1"
              id="address1"
              onChange={getValue}
              myRef={props.validation({ required: "Please Select Address", message: "Please Select Address" })}
              validationMsg={
                props.validationMsg.address1 && props.validationMsg.address1.message
              }
            />
          </div>
          <div id="fullAddress" className={`col-lg-12 ${state.showManualAddress}`}>
            {state.getDetails.line_1 != '' && <div className="form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Address Line 1 *"
                name="txtHouseNumber"
                id="txtHouseNumber"
                className="form-control address hero-input"
                dataId="txtHouseNumber"
                autoComplete="off"
                validation={props.validation({
                  required: "Please Enter Address Line 1",
                })}
                validationMsg={
                  props.validationMsg.txtHouseNumber &&
                  props.validationMsg.txtHouseNumber.message
                }
                value={state.getDetails.line_1}
                readonly={true}
              />
            </div>
            }
            {state.getDetails.line_2 != '' && <div className="form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Address Line 2"
                name="txtAddress2"
                id="txtAddress2"
                className="form-control  address hero-input"
                dataId="txtAddress2"
                autoComplete="off"
                validation={props.validation({
                  required: "Select Address",
                  message: "Please Select Address",
                })}
                value={state.getDetails.line_2}
                readonly={true}
              />
            </div>}
            {state.getDetails.line_3 != '' && <div className="form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Address Line 3"
                name="txtAddress3"
                id="txtAddress3"
                className="form-control  address hero-input"
                dataId="txtAddress3"
                autoComplete="off"
                validation={props.validation({
                  required: "Select Address",
                  message: "Please Select Address",
                })}
                value={state.getDetails.line_3}
                readonly={true}
              />
            </div>
            }

            {state.getDetails.county != '' && <div className="form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="County"
                name="txtCounty"
                id="txtCounty"
                className="form-control  address hero-input"
                dataId="txtCounty"
                autoComplete="off"
                validation={props.validation()}
                validationMsg=""
                value={state.getDetails.county}
                readonly={true}
              />
            </div>
            }

            {state.getDetails.town != '' && <div className="form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Town *"
                name="txtTown"
                id="txtTown"
                className="form-control  address hero-input"
                dataId="txtTown"
                autoComplete="off"
                validation={props.validation({ required: "Please Enter Town" })}
                validationMsg={
                  props.validationMsg.txtTown && props.validationMsg.txtTown.message
                }
                value={state.getDetails.town}
                readonly={true}
              />
            </div>}
            {state.getDetails.country != '' && <div className="form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Country *"
                name="txtCountry"
                id="txtCountry"
                className="form-control address hero-input"
                dataId="txtCountry"
                autoComplete="off"
                validation={props.validation({ required: "Please Enter Country" })}
                validationMsg={
                  props.validationMsg.txtCountry && props.validationMsg.txtCountry.message
                }
                value={state.getDetails.country}
                readonly={true}
              />
            </div>}
            {/* non visible fields */}
            <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Building Name"
                name="txtBuildingName"
                id="txtBuildingName"
                className="form-control address hero-input"
                dataId="txtBuildingName"
                autoComplete="off"
                validation={props.validation()}
                // validationMsg={
                //   props.validationMsg.txtBuildingName && props.validationMsg.txtBuildingName.message
                // }
                value={state.getDetails?.RawAddress?.BuildingName}
                readonly={true}
              />
            </div>
            <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Building Number"
                name="txtBuildingNumber"
                id="txtBuildingNumber"
                className="form-control address hero-input"
                dataId="txtBuildingNumber"
                autoComplete="off"
                validation={props.validation()}
                // validationMsg={
                //   props.validationMsg.txtBuildingNumber && props.validationMsg.txtBuildingNumber.message
                // }
                value={state.getDetails?.RawAddress?.BuildingNumber}
                readonly={true}
              />
            </div>
            <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Thoroughfare"
                name="txtThoroughfare"
                id="txtThoroughfare"
                className="form-control address hero-input"
                dataId="txtThoroughfare"
                autoComplete="off"
                validation={props.validation()}
                // validationMsg={
                //   props.validationMsg.txtThoroughfare && props.validationMsg.txtThoroughfare.message
                // }
                value={state.getDetails?.RawAddress?.ThoroughfareName}
                readonly={true}
              />
            </div>
            <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Sub building name"
                name="txtSubBuildingName"
                id="txtSubBuildingName"
                className="form-control address hero-input"
                dataId="txtSubBuildingName"
                autoComplete="off"
                validation={props.validation()}
                value={state.getDetails?.RawAddress?.SubBuildingName}
                readonly={true}
              />
            </div>
            <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Sub building number"
                name="txtSubBuildingNumber"
                id="txtSubBuildingNumber"
                className="form-control address hero-input"
                dataId="txtSubBuildingNumber"
                autoComplete="off"
                validation={props.validation()}
                value={state.getDetails?.RawAddress?.SubBuildingNumber}
                readonly={true}
              />
            </div>
            <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Address Line 4"
                name="txtAddress4"
                id="txtAddress4"
                className="form-control  address hero-input"
                dataId="txtAddress4"
                autoComplete="off"
                validation={props.validation({
                  required: "Select Address",
                  message: "Please Select Address",
                })}
                value={state.getDetails.line_4}
                readonly={true}
              />
            </div>
            <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Locality"
                name="txtLocality"
                id="txtLocality"
                className="form-control address hero-input"
                dataId="txtLocality"
                autoComplete="off"
                validation={props.validation()}
                value={state.getDetails?.RawAddress?.Locality}
                readonly={true}
              />
            </div>
            <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="District"
                name="txtDistrict"
                id="txtDistrict"
                className="form-control address hero-input"
                dataId="txtDistrict"
                autoComplete="off"
                validation={props.validation()}
                value={state.getDetails?.RawAddress?.Location?.District}
                readonly={true}
              />
            </div>
            <div className="d-none form-group pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <AddressTextField
                type="text"
                placeholder="Thoroughfare Description"
                name="txtThoroughfareDesc"
                id="txtThoroughfareDesc"
                className="form-control address hero-input"
                dataId="txtThoroughfareDesc"
                autoComplete="off"
                validation={props.validation()}
                value={state.getDetails?.RawAddress?.ThoroughfareDesc}
                readonly={true}
              />
            </div>
            <div className="d-none">
              <AddressTextField
                type="text"
                name="txtOrganisation"
                id="txtOrganisation"
                value={state.getDetails?.RawAddress?.Organisation}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name="txtDepartment"
                id="txtDepartment"
                value={state.getDetails?.RawAddress?.Department}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name="txtAddressKey"
                id="txtAddressKey"
                value={state.getDetails?.RawAddress?.AddressKey}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name="txtOrganisationKey"
                id="txtOrganisationKey"
                value={state.getDetails?.RawAddress?.OrganisationKey}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name="txtPostcodeType"
                id="txtPostcodeType"
                value={state.getDetails?.RawAddress?.PostcodeType}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name="txtDependentThoroughfareName"
                id="txtDependentThoroughfareName"
                value={state.getDetails?.RawAddress?.DependentThoroughfareName}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name="txtDependentThoroughfareDesc"
                id="txtDependentThoroughfareDesc"
                value={state.getDetails?.RawAddress?.DependentThoroughfareDesc}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name="txtDoubleDependentLocality"
                id="txtDoubleDependentLocality"
                value={state.getDetails?.RawAddress?.DoubleDependentLocality}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name="txtDependentLocality"
                id="txtDependentLocality"
                value={state.getDetails?.RawAddress?.DependentLocality}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name="txtDps"
                id="txtDps"
                value={state.getDetails?.RawAddress?.Dps}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name="txtPoBox"
                id="txtPoBox"
                value={state.getDetails?.RawAddress?.PoBox}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name="txtPostalCounty"
                id="txtPostalCounty"
                value={state.getDetails?.RawAddress?.PostalCounty}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name="txtTraditionalCounty"
                id="txtTraditionalCounty"
                value={state.getDetails?.RawAddress?.TraditionalCounty}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name="txtAdministrativeCounty"
                id="txtAdministrativeCounty"
                value={state.getDetails?.RawAddress?.AdministrativeCounty}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name="txtCountryISO2"
                id="txtCountryISO2"
                value={state.getDetails?.CountryISO2}
                validation={props.validation()}
              />
            </div>
            {Object.keys(state.getDetails).length > 0 ? <HiddenFields /> : null}
          </div>
        </div>
        {state.nextClick && (
          <div className="form-navigation pt-3 animate__animated animate__fadeInUp animate__delay-.5s" id="nextAdd" ref={props.scrollToEvent}>
            <ul>
              <li>
                <InputButton
                  name="slide3"
                  className="btn center-block next-but sctop"
                  id="next-03"
                  value="Next"
                  btnType="button"
                  style={{ float: "none" }}
                  onClick={handleSubmit}
                  gtm_question='postcode'
                  clickevent='slideclick'
                  gtm_answer=''
                // gtm_trigger={true}
                />
                {/* <input name="submit0" className="btn center-block next-but sctop" id="next-03" value="Next" type="button" data-bs-toggle="modal" data-bs-target="#addPreDetails1" /> */}
              </li>
              <li>
                <InputButton
                  name="back2"
                  className="btn center-block back-but sctop"
                  id="backStep2"
                  value="Back"
                  btnType="button"
                  style={{ float: "none" }}
                  onClick={props.previousSlideChange}
                />
              </li>
            </ul>
          </div>
        )}
      </div>

    </>
  );
}

export default Postcode;