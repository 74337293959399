import React, {
  useReducer,
  useState,
  useContext,
  useRef,
  useEffect,
} from "react";
import AddressTextField from "../../../UI/AddressTextField";
import AddressSelectBox from "../../../UI/AddressSelectBox";
import HiddenField from "../../../UI/HiddenField";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import Button from "../../../UI/Button";
import SelectBox from "../../../UI/SelectBox";
import { slideValidation } from "../../../../Utility/Validations";
import tcglLogo from "../../../../assets/img/tcgl-logo.svg";
import PreviousAddress from "./PreviousAddress";
import PreviousPostCode from "./PreviousPostCode";
import LogoBasedOnDomain from "../../Layouts/Common/LogoBasedOnDomain";
import RadioButtonV2 from "../../../UI/RadioButtonV2";
import PreviousDetailsConfirm from "./PreviousDetailsConfirm";
import GTMDataLayer from "../../../../Utility/GTMDataLayer";
import { usePostCodeAddressCustomApi } from '../../../../Hooks/usePostCodeAddressCustomApi';

const initialState = {
  lookUp: true,
  nextClick: false,
  checkSelect: false,
  getDetails: [],
  getAddress: [],
  showManualAddress: "hide",
  getPostcode: "#",
  clickManualLink: 0,
};
const postcodeReducer = (state, action) => {
  switch (action.type) {
    case "validationSucess": {
      return {
        ...state,
        lookUp: action.payload.lookup,
        nextClick: action.payload.next_click,
        getPostcode: action.payload.postcode,
        getAddress: action.payload.get_address,
        checkSelect: action.payload.check_select,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "getExtraAddressDetails": {
      return {
        ...state,
        getDetails: action.payload.get_details,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "showManualDiv": {
      return {
        ...state,
        showManualAddress: "show",
        lookUp: false,
        nextClick: true,
        checkSelect: false,
        clickManualLink: action.payload.manual_click,
      };
    }
    case "resetaddress": {
      return {
        ...state,
        getDetails: [],
        getAddress: [],
        showManualAddress: "hide",
        lookUp: true,
        nextClick: false,
        checkSelect: false,
      };
    }
    default:
      return state;
  }
};
const PostCode = ({
  validation,
  validationMsg,
  clearErrors,
  setError,
  slideChange,
  trigger,
  className,
  backClick,
  splitForm,
  getValues,
  showSlide7,
  //submitOtherFormDetails,
  formSubmit,
  previousSlideChange,
  setValue
}) => {
  const { getPostcodeAddressApi } = usePostCodeAddressCustomApi();
  const [state, dispatch] = useReducer(postcodeReducer, initialState);
  const [postCodeAddress, setPostCodeAddress] = useState([]);
  const [postCodeEntry, setPostCodeEntry] = useState("0");
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [manualAddress, setManualAddress] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [previousDetailsPopData, setPreviousDetailsPopData] = useState(false);
  const [getAddressDetails, setGetAddressDetails] = useState([]);

  const checkValidation = async (e) => {
    e.preventDefault();
    dispatch({
      type: "resetaddress",
      get_address: [],
    });
    setManualAddress(false);
    const txtPostCode = await trigger("txtPostCode");
    if (txtPostCode) {
      try {
        const response = await getPostcodeAddressApi(e.target.value);
        console.log('response', response);
        if (response.length > 0) {
          setGetAddressDetails(response);
          dispatch({
            type: "validationSucess",
            payload: {
              lookup: false,
              next_click: false,
              postcode: e.target.value,
              get_address: response,
              check_select: true,
              manual_address: "d-none",
            },
          });
        } else {
          setError("txtPostCode", {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });
        }
      } catch (error) {
        console.error('Error fetching postcode details:', error);
        setError("txtPostCode", {
          type: "manual",
          message: "Please Enter Valid Postcode",
        });
      }
    }
  };
  const onHandleChange = (event) => {
    setShow1(false);
    setShow2(false);
  };

  const handleNoclick = () =>{
    let data = getValues();
    console.log("asdasdasd",data);
    Object.keys(data).forEach(key => {
      if (key.startsWith('txtPrev') && (! key.startsWith('txtPrevL'))) {
        setValue(key,'');
      }
    });
    formSubmit();
   
  }

  useEffect(() => {
    localStorage.removeItem("userSelectedAddress");
  }, []);

  const getValue = async (e) => {
    if (e.target.name == "address1") {
      setShowNextButton(true);
    }
    e.preventDefault();
    setManualAddress(false);
    dispatch({
      type: "getExtraAddressDetails",
      payload: { get_details: [], manual_address: "hide" },
    });
    setPostCodeAddress([]);
    clearErrors([
      "txtHouseNumber",
      "address1",
      "txtCounty",
      "txtTown",
      "txtCountry",
    ]);

    const { name, value } = e.target;
    let storedData =
      JSON.parse(localStorage.getItem("userSelectedAddress")) || {};

    for (let key in storedData) {
      if (storedData[key] === value && key !== name) {
        setError(name, {
          type: "manual",
          message: "The Address cannot be the same as previously entered",
        });
        dispatch({
          type: "getExtraAddressDetails",
          payload: { get_details: [], manual_address: "d-none" },
        });
        setPostCodeAddress([]);

        delete storedData[name];
        localStorage.setItem("userSelectedAddress", JSON.stringify(storedData));
        e.target.value = "";

        return;
      }
    }

    clearErrors(name);
    storedData[name] = value;
    localStorage.setItem("userSelectedAddress", JSON.stringify(storedData));
    //////////////////
    if (e.target.value != "") {
      const getExtraAddressData = getAddressDetails.find(address => address.id === e.target.value);
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: getExtraAddressData, manual_address: "show", next_click: true },
      });
      setPostCodeAddress(getExtraAddressData);
      clearErrors([
        "txtHouseNumber",
        "address1",
        "txtCounty",
        "txtTown",
        "txtCountry",
      ]);
      setTimeout(() => {
        scrollToBtn.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 100);
    } else {
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: [], manual_address: "hide" },
      });
      setPostCodeAddress([]);
    }
  };

  const lookupValidation = async (e) => {
    await trigger("txtPostCode");
  };

  const continueClick = () => {
    setShow1(false);
    setPreviousDetailsPopData(true);
  };

  const HiddenFields = () => (
    <>
      <HiddenField
        name="txtUdprn"
        inputId="txtUdprn"
        inputValue={state.getDetails !== "#" ? state.getDetails.Udprn : ""}
      />
      <HiddenField
        name="txtDeliveryPointSuffix"
        inputId="txtDeliveryPointSuffix"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.deliverypointsuffix : ""
        }
      />
      <HiddenField
        name="txtPz_mailsort"
        inputId="txtPz_mailsort"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.pz_mailsort : ""
        }
      />
      <HiddenField
        name="txtStreet"
        inputId="txtStreet"
        inputValue={
          state.getDetails.line_3 !== "#" ? state.getDetails.line_3 : ""
        }
      />
      <HiddenField
        name="txtCounty"
        inputId="txtCounty"
        inputValue={
          state.getDetails.county !== "#" ? state.getDetails.county : ""
        }
      />
      <HiddenField
        name="txtTown"
        inputId="txtTown"
        inputValue={state.getDetails.town !== "#" ? state.getDetails.town : ""}
      />
      <HiddenField
        name="txtCountry"
        inputId="txtCountry"
        inputValue={
          state.getDetails.country !== "#" ? state.getDetails.country : ""
        }
      />
    </>
  );

  const handleOnchange = (e) => {
    setManualAddress(false);
    dispatch({
      type: "resetaddress",
      payload: { get_details: [], manual_address: "hide" },
    });
    setPostCodeAddress([]);
  };

  const validatePostcodeSlide = async (e) => {
    var validationArr = ["txtPostCode", "address1", "txtHouseNumber"];
    const validationCheck = await slideValidation(validationArr, trigger);
    if (validationCheck == "Success") {
      slideChange(e);
    }
  };

  const handleNextChange = (e) => {
    setShow1(true);
    setShow(true);
    setShowNextButton(false);
    //GTMDataLayer trigger on next btn
    GTMDataLayer({
      clickEvent: "slideclick",
      question: "address",
      answer: "",
    });
  };

  const scrollToEvent = useRef([]);
  const scrollToBtn = useRef(null);
  const [previousNameSectionCount, setPreviousNameSectionCount] = useState(1);
  const [showNextButton2, setShowNextButton2] = useState(false);
  const [showContinue, setshowContinue] = useState(true);

  const addExtraSection = async () => {
    if (previousNameSectionCount <= 5) {
      // setshowContinue(true)
      setShowNextButton2(false);
      setShow2(true);
      setPreviousNameSectionCount(previousNameSectionCount + 1);
      setTimeout(() => {
        scrollToEvent.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 100);
    }
  };

  return (
    <>
      <div className={`slide6  questionwrapper ${showSlide7}`}>
        <HiddenField
          name="pCode_EntryType"
          inputId="pCode_EntryType"
          inputValue={postCodeEntry}
        />
        <h4 className="contacthead">Enter Your Personal Details</h4>
        <div className="col-lg-12 mt-3 animate__animated animate__fadeInUp">
          <fieldset className="p-2 px-3">
            <legend className="px-2 m-0">Your Current Address</legend>
            <div className="row">
              <div className="col-lg-8  ">
                <div className="mb-3">
                  <AddressTextField
                    type="text"
                    placeholder="Post Code"
                    name="txtPostCode"
                    id="txtPostCode"
                    className="form-control"
                    onBlur={(e)=>{e.target.value = e.target.value.trim();checkValidation(e);}}
                    autoComplete="off"
                    onChange={() => {
                      clearErrors("txtPostCode");
                      handleOnchange();
                    }}
                    validation={validation({
                      required: "Please Enter Postcode",
                      pattern: {
                        // value:
                        //   /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i,
                        value: /^[A-Za-z0-9 ]+$/,
                        message: "Please Enter a Valid Postcode",
                      },
                      minLength: {
                        value: 5,
                        message: "Please Enter a Valid Postcode",
                      },
                    })}
                    validationMsg={
                      validationMsg.txtPostCode &&
                      validationMsg.txtPostCode.message
                    }
                  />
                </div>
              </div>
              <div className="col-lg-4 ps-lg-0 mb-3">
                <Button
                  type="button"
                  name={`postcode`}
                  id={`next_postcode`}
                  className="button next-btn w-100 button_lookup"
                  buttonText="Look Up Address"
                  onClick={lookupValidation}
                />
              </div>
            </div>
            {state.checkSelect && (
              <div
                className="col-lg-12 mb-3 animate__animated animate__fadeInUp"
                id="LookupAddress"
              >
                <AddressSelectBox
                  className="form-select watermark anim_ylw animated-effect"
                  OptionValue={state.getAddress}
                  name="address1"
                  id="address1"
                  onChange={(e) => {
                    getValue(e);
                    clearErrors("address1");
                  }}
                  myRef={validation({ required: "Please select address" })}
                  validationMsg={
                    validationMsg.address1 && validationMsg.address1.message
                  }
                />
              </div>
            )}
            <div
              className={`col-lg-12 animate__animated animate__fadeInUp ${state.showManualAddress}`}
              id="fullAddress"
            >
              <div className=" mb-3">
                <AddressTextField
                  type="text"
                  placeholder="Address Line 1 *"
                  name="txtHouseNumber"
                  id="txtHouseNumber"
                  className="form-control "
                  dataId="txtHouseNumber"
                  autoComplete="off"
                  readonly={true}
                  validation={validation({
                    required: "Please Enter Address Line 1",
                  })}
                  validationMsg={
                    validationMsg.txtHouseNumber &&
                    validationMsg.txtHouseNumber.message
                  }
                  value={state.getDetails.line_1}
                  onChange={() => {
                    clearErrors("txtHouseNumber");
                  }}
                />
              </div>
              <div className=" mb-3">
                <AddressTextField
                  type="text"
                  placeholder="Address Line 2"
                  name="txtAddress2"
                  id="txtAddress2"
                  className="form-control  hero-input"
                  dataId="txtAddress2"
                  autoComplete="off"
                  readonly={true}
                  validation={validation}
                  onChange={() => {
                    clearErrors("txtAddress2");
                  }}
                  value={state.getDetails.line_2}
                />
              </div>
              {state.getDetails.line_3 != "" && (
                <div className="mb-3">
                  <AddressTextField
                    type="text"
                    placeholder="Address Line 3"
                    name="txtAddress3"
                    id="txtAddress3"
                    className="form-control  hero-input"
                    dataId="txtAddress3"
                    autoComplete="off"
                    readonly={true}
                    validation={validation}
                    onChange={() => {
                      clearErrors("txtAddress3");
                    }}
                    value={state.getDetails.line_3}
                  />
                </div>
              )}
              {state.getDetails.county != "" && (
                <div className="mb-3">
                  <AddressTextField
                    type="text"
                    placeholder="County"
                    name="txtCounty"
                    id="txtCounty"
                    className="form-control  hero-input"
                    dataId="txtCounty"
                    autoComplete="off"
                    readonly={true}
                    validation={validation}
                    onChange={() => {
                      clearErrors("txtCounty");
                    }}
                    value={state.getDetails.county}
                  />
                </div>
              )}
              {state.getDetails.town != "" && (
                <div className="mb-3">
                  <AddressTextField
                    type="text"
                    placeholder="Town"
                    name="txtTown"
                    id="txtTown"
                    className="form-control  hero-input"
                    dataId="txtTown"
                    autoComplete="off"
                    readonly={true}
                    validation={validation}
                    onChange={() => {
                      clearErrors("txtTown");
                    }}
                    value={state.getDetails.town}
                  />
                </div>
              )}
              {state.getDetails.country != "" && (
                <div className="mb-3">
                  <AddressTextField
                    type="text"
                    placeholder="Country *"
                    name="txtCountry"
                    id="txtCountry"
                    className="form-control  hero-input"
                    dataId="txtCountry"
                    autoComplete="off"
                    readonly={true}
                    validation={validation}
                    onChange={() => {
                      clearErrors("txtCountry");
                    }}
                    value={state.getDetails.country}
                  />
                </div>
              )}

              <div className="d-none mb-3">
                <AddressTextField
                  type="text"
                  placeholder="Building Name"
                  name="txtBuildingName"
                  id="txtBuildingName"
                  className="form-control address hero-input"
                  dataId="txtBuildingName"
                  autoComplete="off"
                  validation={validation}
                  value={state.getDetails?.RawAddress?.BuildingName}
                  readonly={true}
                />
              </div>
              <div className="d-none mb-3">
                <AddressTextField
                  type="text"
                  placeholder="Building Number"
                  name="txtBuildingNumber"
                  id="txtBuildingNumber"
                  className="form-control address hero-input"
                  dataId="txtBuildingNumber"
                  autoComplete="off"
                  validation={validation}
                  value={state.getDetails?.RawAddress?.BuildingNumber}
                  readonly={true}
                />
              </div>
              <div className="d-none mb-3">
                <AddressTextField
                  type="text"
                  placeholder="Thoroughfare"
                  name="txtThoroughfare"
                  id="txtThoroughfare"
                  className="form-control address hero-input"
                  dataId="txtThoroughfare"
                  autoComplete="off"
                  validation={validation}
                  value={state.getDetails?.RawAddress?.ThoroughfareName}
                  readonly={true}
                />
              </div>
              <div className="d-none mb-3">
                <AddressTextField
                  type="text"
                  placeholder="Sub building name"
                  name="txtSubBuildingName"
                  id="txtSubBuildingName"
                  className="form-control address hero-input"
                  dataId="txtSubBuildingName"
                  autoComplete="off"
                  validation={validation}
                  value={state.getDetails?.RawAddress?.SubBuildingName}
                  readonly={true}
                />
              </div>
              <div className="d-none mb-3">
                <AddressTextField
                  type="text"
                  placeholder="Sub building number"
                  name="txtSubBuildingNumber"
                  id="txtSubBuildingNumber"
                  className="form-control address hero-input"
                  dataId="txtSubBuildingNumber"
                  autoComplete="off"
                  validation={validation}
                  value={state.getDetails?.RawAddress?.SubBuildingNumber}
                  readonly={true}
                />
              </div>
              <div className="d-none mb-3">
                <AddressTextField
                  type="text"
                  placeholder="Address Line 4"
                  name="txtAddress4"
                  id="txtAddress4"
                  className="form-control  hero-input"
                  dataId="txtAddress4"
                  autoComplete="off"
                  readonly={true}
                  validation={validation}
                  onChange={() => {
                    clearErrors("txtAddress4");
                  }}
                  value={state.getDetails.line_4}
                />
              </div>
              <div className="d-none mb-3">
                <AddressTextField
                  type="text"
                  placeholder="Locality"
                  name="txtLocality"
                  id="txtLocality"
                  className="form-control address hero-input"
                  dataId="txtLocality"
                  autoComplete="off"
                  validation={validation}
                  value={state.getDetails?.RawAddress?.Locality}
                  readonly={true}
                />
              </div>
              <div className="d-none mb-3">
                <AddressTextField
                  type="text"
                  placeholder="District"
                  name="txtDistrict"
                  id="txtDistrict"
                  className="form-control address hero-input"
                  dataId="txtDistrict"
                  autoComplete="off"
                  validation={validation}
                  value={state.getDetails?.RawAddress?.Location?.District}
                  readonly={true}
                />
              </div>
              <div className="d-none mb-3">
                <AddressTextField
                  type="text"
                  placeholder="Thoroughfare Description"
                  name="txtThoroughfareDesc"
                  id="txtThoroughfareDesc"
                  className="form-control address hero-input"
                  dataId="txtThoroughfareDesc"
                  autoComplete="off"
                  validation={validation}
                  value={state.getDetails?.RawAddress?.ThoroughfareDesc}
                  readonly={true}
                />
              </div>
              <div className="d-none">
              <AddressTextField
                type="text"
                name="txtOrganisation"
                id="txtOrganisation"
                value={state.getDetails?.RawAddress?.Organisation}
                validation={validation}
              />
              <AddressTextField
                type="text"
                name="txtDepartment"
                id="txtDepartment"
                value={state.getDetails?.RawAddress?.Department}
                validation={validation}
              />
              <AddressTextField
                type="text"
                name="txtAddressKey"
                id="txtAddressKey"
                value={state.getDetails?.RawAddress?.AddressKey}
                validation={validation}
              />
              <AddressTextField
                type="text"
                name="txtOrganisationKey"
                id="txtOrganisationKey"
                value={state.getDetails?.RawAddress?.OrganisationKey}
                validation={validation}
              />
              <AddressTextField
                type="text"
                name="txtPostcodeType"
                id="txtPostcodeType"
                value={state.getDetails?.RawAddress?.PostcodeType}
                validation={validation}
              />
              <AddressTextField
                type="text"
                name="txtDependentThoroughfareName"
                id="txtDependentThoroughfareName"
                value={state.getDetails?.RawAddress?.DependentThoroughfareName}
                validation={validation}
              />
              <AddressTextField
                type="text"
                name="txtDependentThoroughfareDesc"
                id="txtDependentThoroughfareDesc"
                value={state.getDetails?.RawAddress?.DependentThoroughfareDesc}
                validation={validation}
              />
              <AddressTextField
                type="text"
                name="txtDoubleDependentLocality"
                id="txtDoubleDependentLocality"
                value={state.getDetails?.RawAddress?.DoubleDependentLocality}
                validation={validation}
              />
              <AddressTextField
                type="text"
                name="txtDependentLocality"
                id="txtDependentLocality"
                value={state.getDetails?.RawAddress?.DependentLocality}
                validation={validation}
              />
              <AddressTextField
                type="text"
                name="txtDps"
                id="txtDps"
                value={state.getDetails?.RawAddress?.Dps}
                validation={validation}
              />
              <AddressTextField
                type="text"
                name="txtPoBox"
                id="txtPoBox"
                value={state.getDetails?.RawAddress?.PoBox}
                validation={validation}
              />
              <AddressTextField
                type="text"
                name="txtPostalCounty"
                id="txtPostalCounty"
                value={state.getDetails?.RawAddress?.PostalCounty}
                validation={validation}
              />
              <AddressTextField
                type="text"
                name="txtTraditionalCounty"
                id="txtTraditionalCounty"
                value={state.getDetails?.RawAddress?.TraditionalCounty}
                validation={validation}
              />
              <AddressTextField
                type="text"
                name="txtAdministrativeCounty"
                id="txtAdministrativeCounty"
                value={state.getDetails?.RawAddress?.AdministrativeCounty}
                validation={validation}
              />
              <AddressTextField
                type="text"
                name="txtCountryISO2"
                id="txtCountryISO2"
                value={state.getDetails?.RawAddress?.CountryISO2}
                validation={validation}
              />
            </div>
              {Object.keys(state.getDetails).length > 0 ? (
                <HiddenFields />
              ) : null}
            </div>
          </fieldset>
          {showNextButton && (
            <div className="col-lg-12 col-12 text-center text-lg-center mt-3 slide_6_div animate__animated animate__fadeInUp">
              <Button
                type="button"
                className="button next-btn preadrbut"
                id=""
                value="Next"
                name="slide_7"
                data-bs-toggle="modal"
                data-bs-target="#previousaddresses"
                buttonText="Next"
                onClick={(e) => handleNextChange(e)}
              />
            </div>
          )}
        </div>
        {show && (
          <div className="col-lg-12 col-12 text-center text-lg-center mt-3 slide_7_div animate__animated animate__fadeInUp">
            <Button
              type="submit"
              className="button next-btn"
              id="final_Submit"
              value="final_Submit"
              name=""
              buttonText="Submit"
            />
          </div>
        )}
        <div className="text-center" ref={scrollToBtn}>
          <Button
            name="back6"
            className="mt-3 bck-btn"
            id="backStep2"
            buttonText="Back"
            type="button"
            style={{ float: "none" }}
            onClick={previousSlideChange}
          />
        </div>
      </div>
      {/* Modal */}
      <div
        className={`modal fade ${show1 ? "show" : "d-none"}`}
        id="previousaddresses"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content form-modal">
            <div className="modal-header text-center">
              {/* <img src={tcglLogo} alt="" /> */}
              <LogoBasedOnDomain className="" bg="" />
            </div>
            <div className="modal-body">
              <h4>
                Did you have any previous addresses?{" "}
                <em>[for the last 20 years]</em>
              </h4>
              <div className="choose_Y_N col-lg-12 col-12 mt-3">
                <div className="form-check px-0 col-lg-6 col-12">
                  <RadioButtonV2
                    className="form-check-input"
                    type="radio"
                    name="preadr"
                    id="yes_pre_add"
                    defaultValue="optionclick2"
                    labelName="Yes"
                    labelClassName="form-check-label slide_preadr"
                    onClick={() => setShow2(true)}
                  />
                </div>
                <div className="form-check px-0 col-lg-6 col-12">
                  <RadioButtonV2
                    className="form-check-input"
                    type="radio"
                    name="preadr_no"
                    id="no11"
                    defaultValue="optionclick1"
                    labelName="No"
                    labelClassName="form-check-label"
                    onClick={handleNoclick}
                  />
                </div>
              </div>

              {show2 && (
                <>
                  <div
                    className={`pre_address_section ${show2 ? "" : "d-none"}`}
                  >
                    {previousNameSectionCount >= 1 && (
                      <PreviousPostCode
                        validation={validation}
                        validationMsg={validationMsg}
                        trigger={trigger}
                        clearErrors={clearErrors}
                        preCount="1"
                        setShowNextButton={setShowNextButton2}
                        scrollToEvent={scrollToEvent}
                        prevAddrs="Previous Address"
                        setError={setError}
                      />
                    )}
                    {previousNameSectionCount >= 2 && (
                      <PreviousPostCode
                        validation={validation}
                        validationMsg={validationMsg}
                        trigger={trigger}
                        clearErrors={clearErrors}
                        preCount="2"
                        setShowNextButton={setShowNextButton2}
                        scrollToEvent={scrollToEvent}
                        prevAddrs="Previous Address 1"
                        setError={setError}
                      />
                    )}
                    {previousNameSectionCount >= 3 && (
                      <PreviousPostCode
                        validation={validation}
                        validationMsg={validationMsg}
                        trigger={trigger}
                        clearErrors={clearErrors}
                        preCount="3"
                        setShowNextButton={setShowNextButton2}
                        scrollToEvent={scrollToEvent}
                        prevAddrs="Previous Address 2"
                        setError={setError}
                      />
                    )}
                    {previousNameSectionCount >= 4 && (
                      <PreviousPostCode
                        validation={validation}
                        validationMsg={validationMsg}
                        trigger={trigger}
                        clearErrors={clearErrors}
                        preCount="4"
                        setShowNextButton={setShowNextButton2}
                        scrollToEvent={scrollToEvent}
                        prevAddrs="Previous Address 3"
                        setError={setError}
                      />
                    )}
                    {previousNameSectionCount >= 5 && (
                      <PreviousPostCode
                        validation={validation}
                        validationMsg={validationMsg}
                        trigger={trigger}
                        clearErrors={clearErrors}
                        preCount="5"
                        setShowNextButton={setShowNextButton2}
                        scrollToEvent={scrollToEvent}
                        prevAddrs="Previous Address 4"
                        setError={setError}
                      />
                    )}
                    {previousNameSectionCount < 5 && showNextButton2 ? (
                      <div style={{ textAlign: "end" }}>
                        <Button
                          type="button"
                          className="prev-field-btn"
                          style={{ padding: "6px" }}
                          onClick={addExtraSection}
                          id="addPrevPostcode"
                          name="addPrevPostcode"
                          buttonText="+ Add More"
                        />
                      </div>
                    ) : null}
                  </div>
                  {(previousNameSectionCount >= 2 && showContinue) ||
                    showNextButton2 ? (
                    <div className="col-lg-12 col-12 text-center text-lg-center mt-3 slide_7_div animate__animated animate__fadeInUp">
                      <Button
                        type="submit"
                        className="button next-btn"
                        id=""
                        value="Next"
                        name=""
                        onClick={continueClick}
                        buttonText="Continue"
                      />
                    </div>
                  ) : null}
                </>
              )}
              <div ref={scrollToEvent}></div>
            </div>
          </div>
        </div>
      </div>

      {previousDetailsPopData && (
        <PreviousDetailsConfirm
          getValues={getValues}
          setShow1={setShow1}
          slideChange={slideChange}
          setPreviousDetailsPopData={setPreviousDetailsPopData}
          previousSlideChange={previousSlideChange}
          formSubmit={formSubmit}
        //submitOtherFormDetails={submitOtherFormDetails}
        />
      )}
    </>
  );
};
export default PostCode;
