import React, { useReducer, useState, useContext } from "react";
import AddressTextField from "../../../UI/AddressTextField";
import AddressSelectBox from "../../../UI/AddressSelectBox";
import HiddenField from "../../../UI/HiddenField";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import Button from "../../../UI/Button";
import { usePostCodeAddressCustomApi } from '../../../../Hooks/usePostCodeAddressCustomApi';

const initialState = {
  lookUp: true,
  nextClick: false,
  checkSelect: false,
  getDetails: [],
  getAddress: [],
  showManualAddress: "hide",
  getPostcode: "#",
  clickManualLink: 0,
};
const postcodeReducer = (state, action) => {
  switch (action.type) {
    case "validationSucess": {
      return {
        ...state,
        lookUp: action.payload.lookup,
        nextClick: action.payload.next_click,
        getPostcode: action.payload.postcode,
        getAddress: action.payload.get_address,
        checkSelect: action.payload.check_select,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "getExtraAddressDetails": {
      return {
        ...state,
        getDetails: action.payload.get_details,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "showManualDiv": {
      return {
        ...state,
        showManualAddress: "show",
        lookUp: false,
        nextClick: true,
        checkSelect: false,
        clickManualLink: action.payload.manual_click,
      };
    }
    case "resetaddress": {
      return {
        ...state,
        getDetails: [],
        getAddress: [],
        showManualAddress: "hide",
        lookUp: true,
        nextClick: false,
        checkSelect: false,
      };
    }
    default:
      return state;
  }
};
const PreviousPostCode = (props) => {
  const { getPostcodeAddressApi } = usePostCodeAddressCustomApi();
  const [state, dispatch] = useReducer(postcodeReducer, initialState);
  const [postCodeAddress, setPostCodeAddress] = useState([]);
  const [postCodeEntry, setPostCodeEntry] = useState("0");
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [manualAddress, setManualAddress] = useState(false);
  const [getAddressDetails, setGetAddressDetails] = useState([]);
  const checkValidation = async (e) => {
    e.preventDefault();
    dispatch({
      type: "resetaddress",
      get_address: [],
    });
    const txtPrevPostCode = await props.trigger(
      `txtPrevPostCode_${props.preCount}`
    );
    if (txtPrevPostCode) {
      await getPostcodeAddressApi(e.target.value)
        .then((response) => {
          if (response.length > 0) {
            setGetAddressDetails(response);
            dispatch({
              type: "validationSucess",
              payload: {
                lookup: false,
                skip: false,
                next_click: false,
                postcode: e.target.value,
                get_address: response,
                check_select: true,
                manual_address: "d-none",
              },
            });
          } else {
            props.setError(`txtPrevPostCode_${props.preCount}`, {
              type: "manual",
              message: "Please Enter Valid Postcode",
            });
          }
        })
        .catch(function () {
          props.setError(`txtPrevPostCode_${props.preCount}`, {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });
        });
    }
  };


  const getValue = async (e) => {
    e.preventDefault();
    props.clearErrors([
      `txtPrevHouseNumber_${props.preCount}`,
      `txtPrevAddress3_${props.preCount}`,
      `txtPrevCounty_${props.preCount}`,
      `txtPrevTown_${props.preCount}`,
      `txtPrevCountry_${props.preCount}`,
    ]);

    // check for duplicate address
    const { name, value } = e.target;
    let storedData =
      JSON.parse(localStorage.getItem("userSelectedAddress")) || {};

    for (let key in storedData) {
      if (storedData[key] === value && key !== name) {
        props.setError(name, {
          type: "manual",
          message: "The Address cannot be the same as previously entered",
        });
        dispatch({
          type: "getExtraAddressDetails",
          payload: { get_details: [], manual_address: "d-none" },
        });
        setPostCodeAddress([]);

        delete storedData[name];
        localStorage.setItem("userSelectedAddress", JSON.stringify(storedData));
        e.target.value = "";

        return;
      }
    }

    props.clearErrors(name);
    storedData[name] = value;
    localStorage.setItem("userSelectedAddress", JSON.stringify(storedData));
    ///////////////

    if (e.target.value != "") {
      const getData = getAddressDetails.find(address => address.id === e.target.value);
      //props.setShowNextButton(true);
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: getData, manual_address: "show", next_click: true },
      });
      setPostCodeAddress(getData);
      props.clearErrors([
        `txtPrevHouseNumber_${props.preCount}`,
        `txtPrevAddress3_${props.preCount}`,
        `txtPrevCounty_${props.preCount}`,
        `txtPrevTown_${props.preCount}`,
        `txtPrevCountry_${props.preCount}`,
      ]);
      setTimeout(() => {
        props.scrollToEvent.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 50);
    } else {
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: [], manual_address: "d-none" },
      });
      setPostCodeAddress([]);
    }
  };

  const lookupValidation = async (e) => {
    await props.trigger(`txtPrevPostCode_${props.preCount}`);
  };

  const triggerPostcodeChange = () => {
    props.setSlideValidationError(false);
    dispatch({
      type: "resetaddress",
      get_address: [],
    });
  };

  const HiddenFields = () => (
    <>
      <HiddenField
        name="txtUdprn"
        inputId="txtUdprn"
        inputValue={state.getDetails !== "#" ? state.getDetails.Udprn : ""}
      />
      <HiddenField
        name="txtDeliveryPointSuffix"
        inputId="txtDeliveryPointSuffix"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.deliverypointsuffix : ""
        }
      />
      <HiddenField
        name="txtPz_mailsort"
        inputId="txtPz_mailsort"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.pz_mailsort : ""
        }
      />
      {/* <HiddenField
                name="txtStreet"
                inputId="txtStreet"
                inputValue={
                    state.getDetails.line_3 !== "#" ? state.getDetails.line_3 : ""
                }
            /> */}
    </>
  );
  return (
    <>
      <HiddenField
        name="pCode_EntryType"
        inputId="pCode_EntryType"
        inputValue={postCodeEntry}
      />
      <fieldset className="p-3 mt-3" id="prevcode2">
        <legend>{props.prevAddrs}</legend>
        <div className=" postcode1">
          <div className="mb-3">
            <AddressTextField
              type="text"
              placeholder={`Enter Your Postcode`}
              name={`txtPrevPostCode_${props.preCount}`}
              id={`txtPrevPostCode_${props.preCount}`}
              className="form-control"
              dataId={`txtPrevPostCode_${props.preCount}`}
              onBlur={(e)=>{e.target.value = e.target.value.trim();checkValidation(e);}}
              autoComplete="off"
              onChange={() => {
                props.clearErrors(`txtPrevPostCode_${props.preCount}`);
                triggerPostcodeChange();
              }}
              validation={props.validation({
                required: "Please Enter Postcode",
                pattern: {
                  // value:
                  //   /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i,
                  value: /^[A-Za-z0-9 ]+$/,
                  message: "Please Enter a Valid Postcode",
                },
                minLength: {
                  value: 5,
                  message: "Please Enter a Valid Postcode",
                },
              })}
              validationMsg={
                props.validationMsg[`txtPrevPostCode_${props.preCount}`] && props.validationMsg[`txtPrevPostCode_${props.preCount}`].message
              }
            />
            
          </div>
          {!state.checkSelect && (
            <div className="col-lg-4 ps-lg-0 mb-2 ">
              <Button
                type="button"
                name={`lookUp${props.preCount}`}
                id={`lookUp${props.preCount}`}
                className="btn-comm p-2 w-100  lookup-btn"
                buttonText={`Look Up Address`}
                onClick={lookupValidation}
              />
            </div>
          )}

          {state.checkSelect && (
            <div className="mb-3">
              <AddressSelectBox
                className="form-control"
                OptionValue={state.getAddress}
                name={`txtPrevAddress1_${props.preCount}`}
                id={`txtPrevAddress1_${props.preCount}`}
                onChange={getValue}
                myRef={props.validation({
                  required: "Please Select Address",
                  message: "Please Select Address",
                })}
                validationMsg={
                  props.validationMsg[`txtPrevAddress1_${props.preCount}`] &&
                  props.validationMsg[`txtPrevAddress1_${props.preCount}`]
                    .message
                }
              />
            </div>
          )}
          <div
            id="fullAddress"
            className={`col-lg-12 ${state.showManualAddress}`}
          >
            {state.getDetails.line_1 && (
              <div className={`mb-3`}>
                <AddressTextField
                  type="text"
                  placeholder="Address Line 1 *"
                  name={`txtPrevHouseNumber_${props.preCount}`}
                  id={`txtPrevHouseNumber_${props.preCount}`}
                  className="form-control"
                  dataId={`txtPrevHouseNumber_${props.preCount}`}
                  autoComplete="off"
                  readonly={true}
                  validation={props.validation({
                    required: "Please Enter Address Line 1",
                  })}
                  validationMsg={
                    props.validationMsg[
                    `txtPrevHouseNumber_${props.preCount}`
                    ] &&
                    props.validationMsg[`txtPrevHouseNumber_${props.preCount}`]
                      .message
                  }
                  value={state.getDetails.line_1}
                  onChange={() => {
                    props.clearErrors(`txtPrevHouseNumber_${props.preCount}`);
                  }}
                />
              </div>
            )}
            {state.getDetails.line_2 && (
              <div className={`mb-3`}>
                <AddressTextField
                  type="text"
                  placeholder="Address Line 2"
                  name={`txtPrevAddress2_${props.preCount}`}
                  id={`txtPrevAddress2_${props.preCount}`}
                  className="form-control"
                  dataId={`txtPrevAddress2_${props.preCount}`}
                  autoComplete="off"
                  readonly={true}
                  value={state.getDetails.line_2}
                  validation={props.validation}
                />
              </div>
            )}
            {state.getDetails.line_3 && (
              <div className={`mb-3`}>
                <AddressTextField
                  type="text"
                  placeholder="Address Line 3"
                  name={`txtPrevAddress3_${props.preCount}`}
                  id={`txtPrevAddress3_${props.preCount}`}
                  className="form-control"
                  dataId={`txtPrevAddress3_${props.preCount}`}
                  autoComplete="off"
                  readonly={true}
                  value={state.getDetails.line_3}
                  validation={props.validation}
                />
              </div>
            )}

            {/* <div className={`mb-3 d-none`}>
            <AddressTextField
              type="text"
              placeholder="Street"
              name={`txtPrevStreet_${props.preCount}`}
              id={`txtPrevStreet_${props.preCount}`}
              className="form-control"
              dataId={`txtPrevStreet_${props.preCount}`}
              autoComplete="off"
              readonly={false}
              value={state.getDetails.line_3}
              onChange={() => { props.clearErrors(`txtPrevStreet_${props.preCount}`) }}
              validation={props.validation}
            />
            <i
              className="validate validate_success"
              aria-hidden="true"
              style={{ display: "none" }}
            ></i>
          </div> */}

            {state.getDetails.county && (
              <div className={`mb-3`}>
                <AddressTextField
                  type="text"
                  placeholder="County"
                  name={`txtPrevCounty_${props.preCount}`}
                  id={`txtPrevCounty_${props.preCount}`}
                  className="form-control"
                  dataId={`txtPrevCounty_${props.preCount}`}
                  autoComplete="off"
                  readonly={true}
                  value={state.getDetails.county}
                  validation={props.validation}
                />
              </div>
            )}

            {state.getDetails.town && (
              <div className={`mb-3`}>
                <AddressTextField
                  type="text"
                  placeholder="Town *"
                  name={`txtPrevTown_${props.preCount}`}
                  id={`txtPrevTown_${props.preCount}`}
                  className="form-control"
                  dataId={`txtPrevTown_${props.preCount}`}
                  autoComplete="off"
                  readonly={true}
                  validation={props.validation({
                    required: "Please Enter Town",
                  })}
                  validationMsg={
                    props.validationMsg[`txtPrevTown_${props.preCount}`] &&
                    props.validationMsg[`txtPrevTown_${props.preCount}`].message
                  }
                  value={state.getDetails.town}
                  onChange={() => {
                    props.clearErrors(`txtPrevTown_${props.preCount}`);
                  }}
                />
              </div>
            )}
            {state.getDetails.country && (
              <div className={`mb-3`}>
                <AddressTextField
                  type="text"
                  placeholder="Country *"
                  name={`txtPrevCountry_${props.preCount}`}
                  id={`txtPrevCountry_${props.preCount}`}
                  className="form-control"
                  dataId={`txtPrevCountry_${props.preCount}`}
                  autoComplete="off"
                  readonly={true}
                  validation={props.validation({
                    required: "Please Enter Country",
                  })}
                  validationMsg={
                    props.validationMsg[`txtPrevCountry_${props.preCount}`] &&
                    props.validationMsg[`txtPrevCountry_${props.preCount}`]
                      .message
                  }
                  value={state.getDetails.country}
                  onChange={() => {
                    props.clearErrors(`txtPrevCountry_${props.preCount}`);
                  }}
                />
              </div>
            )}
            <div className="d-none mb-3">
              <AddressTextField
                type="text"
                placeholder="Building Name"
                name={`txtPrevBuildingName_${props.preCount}`}
                id={`txtPrevBuildingName_${props.preCount}`}
                className="form-control address hero-input"
                dataId={`txtPrevBuildingName_${props.preCount}`}
                autoComplete="off"
                validation={props.validation()}
                // validationMsg={
                //   props.validationMsg[`txtPrevBuildingName_${props.preCount}`] && props.validationMsg[`txtPrevBuildingName_${props.preCount}`].message
                // }
                value={state.getDetails?.RawAddress?.BuildingName}
                readonly={true}
              />
            </div>
            <div className="d-none mb-3">
              <AddressTextField
                type="text"
                placeholder="Building Number"
                name={`txtPrevBuildingNumber_${props.preCount}`}
                id={`txtPrevBuildingNumber_${props.preCount}`}
                className="form-control address hero-input"
                dataId={`txtPrevBuildingNumber_${props.preCount}`}
                autoComplete="off"
                validation={props.validation()}
                // validationMsg={
                //   props.validationMsg[`txtPrevBuildingNumber_${props.preCount}`] && props.validationMsg[`txtPrevBuildingNumber_${props.preCount}`].message
                // }
                value={state.getDetails?.RawAddress?.BuildingNumber}
                readonly={true}
              />
            </div>
            <div className="d-none mb-3">
              <AddressTextField
                type="text"
                placeholder="Thoroughfare"
                name={`txtPrevThoroughfare_${props.preCount}`}
                id={`txtPrevThoroughfare_${props.preCount}`}
                className="form-control address hero-input"
                dataId={`txtPrevThoroughfare_${props.preCount}`}
                autoComplete="off"
                validation={props.validation()}
                // validationMsg={
                //   props.validationMsg[`txtPrevThoroughfare_${props.preCount}`] && props.validationMsg[`txtPrevThoroughfare_${props.preCount}`].message
                // }
                value={state.getDetails?.RawAddress?.ThoroughfareName}
                readonly={true}
              />
            </div>
            <div className="d-none mb-3">
              <AddressTextField
                type="text"
                placeholder="Sub building name"
                name={`txtPrevSubBuildingName_${props.preCount}`}
                id={`txtPrevSubBuildingName_${props.preCount}`}
                className="form-control address hero-input"
                dataId={`txtPrevSubBuildingName_${props.preCount}`}
                autoComplete="off"
                validation={props.validation()}
                // validationMsg={
                //   props.validationMsg[`txtPrevSubBuildingName_${props.preCount}`] && props.validationMsg[`txtPrevSubBuildingName_${props.preCount}`].message
                // }
                value={state.getDetails?.RawAddress?.SubBuildingName}
                readonly={true}
              />
            </div>
            <div className="d-none mb-3">
              <AddressTextField
                type="text"
                placeholder="Sub building number"
                name={`txtPrevSubBuildingNumber_${props.preCount}`}
                id={`txtPrevSubBuildingNumber_${props.preCount}`}
                className="form-control address hero-input"
                dataId={`txtPrevSubBuildingNumber_${props.preCount}`}
                autoComplete="off"
                validation={props.validation()}
                // validationMsg={
                //   props.validationMsg[`txtPrevSubBuildingNumber_${props.preCount}`] && props.validationMsg[`txtPrevSubBuildingNumber_${props.preCount}`].message
                // }
                value={state.getDetails?.RawAddress?.SubBuildingNumber}
                readonly={true}
              />
            </div>
            <div className="d-none mb-3">
              <AddressTextField
                type="text"
                placeholder="Address Line 4"
                name={`txtPrevAddress4_${props.preCount}`}
                id={`txtPrevAddress4_${props.preCount}`}
                className="form-control"
                dataId={`txtPrevAddress4_${props.preCount}`}
                autoComplete="off"
                readonly={true}
                value={state.getDetails.line_4}
                validation={props.validation}
              />
            </div>
            <div className="d-none mb-3">
              <AddressTextField
                type="text"
                placeholder="Locality"
                name={`txtPrevLocality_${props.preCount}`}
                id={`txtPrevLocality_${props.preCount}`}
                className="form-control address hero-input"
                dataId={`txtPrevLocality_${props.preCount}`}
                autoComplete="off"
                validation={props.validation()}
                value={state.getDetails?.RawAddress?.Locality}
                readonly={true}
              />
            </div>
            <div className="d-none mb-3">
              <AddressTextField
                type="text"
                placeholder="District"
                name={`txtPrevDistrict_${props.preCount}`}
                id={`txtPrevDistrict_${props.preCount}`}
                className="form-control address hero-input"
                dataId={`txtPrevDistrict_${props.preCount}`}
                autoComplete="off"
                validation={props.validation()}
                value={state.getDetails?.RawAddress?.Location?.District}
                readonly={true}
              />
            </div>
            <div className="d-none mb-3">
              <AddressTextField
                type="text"
                placeholder="Thoroughfare Description"
                name={`txtPrevThoroughfareDesc_${props.preCount}`}
                id={`txtPrevThoroughfareDesc_${props.preCount}`}
                className="form-control address hero-input"
                dataId={`txtPrevThoroughfareDesc_${props.preCount}`}
                autoComplete="off"
                validation={props.validation()}
                value={state.getDetails?.RawAddress?.ThoroughfareDesc}
                readonly={true}
              />
            </div>
            <div className="d-none">
              <AddressTextField
                type="text"
                name={`txtPrevOrganisation_${props.preCount}`}
                id={`txtPrevOrganisation_${props.preCount}`}
                value={state.getDetails?.RawAddress?.Organisation}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name={`txtPrevDepartment_${props.preCount}`}
                id={`txtPrevDepartment_${props.preCount}`}
                value={state.getDetails?.RawAddress?.Department}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name={`txtPrevAddressKey_${props.preCount}`}
                id={`txtPrevAddressKey_${props.preCount}`}
                value={state.getDetails?.RawAddress?.AddressKey}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name={`txtPrevOrganisationKey_${props.preCount}`}
                id={`txtPrevOrganisationKey_${props.preCount}`}
                value={state.getDetails?.RawAddress?.OrganisationKey}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name={`txtPrevPostcodeType_${props.preCount}`}
                id={`txtPrevPostcodeType_${props.preCount}`}
                value={state.getDetails?.RawAddress?.PostcodeType}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name={`txtPrevDependentThoroughfareName_${props.preCount}`}
                id={`txtPrevDependentThoroughfareName_${props.preCount}`}
                value={state.getDetails?.RawAddress?.DependentThoroughfareName}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name={`txtPrevDependentThoroughfareDesc_${props.preCount}`}
                id={`txtPrevDependentThoroughfareDesc_${props.preCount}`}
                value={state.getDetails?.RawAddress?.DependentThoroughfareDesc}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name={`txtPrevDoubleDependentLocality_${props.preCount}`}
                id={`txtPrevDoubleDependentLocality_${props.preCount}`}
                value={state.getDetails?.RawAddress?.DoubleDependentLocality}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name={`txtPrevDependentLocality_${props.preCount}`}
                id={`txtPrevDependentLocality_${props.preCount}`}
                value={state.getDetails?.RawAddress?.DependentLocality}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name={`txtPrevDps_${props.preCount}`}
                id={`txtPrevDps_${props.preCount}`}
                value={state.getDetails?.RawAddress?.Dps}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name={`txtPrevPoBox_${props.preCount}`}
                id={`txtPrevPoBox_${props.preCount}`}
                value={state.getDetails?.RawAddress?.PoBox}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name={`txtPrevPostalCounty_${props.preCount}`}
                id={`txtPrevPostalCounty_${props.preCount}`}
                value={state.getDetails?.RawAddress?.PostalCounty}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name={`txtPrevTraditionalCounty_${props.preCount}`}
                id={`txtPrevTraditionalCounty_${props.preCount}`}
                value={state.getDetails?.RawAddress?.TraditionalCounty}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name={`txtPrevAdministrativeCounty_${props.preCount}`}
                id={`txtPrevAdministrativeCounty_${props.preCount}`}
                value={state.getDetails?.RawAddress?.AdministrativeCounty}
                validation={props.validation()}
              />
              <AddressTextField
                type="text"
                name={`txtPrevCountryISO2_${props.preCount}`}
                id={`txtPrevCountryISO2_${props.preCount}`}
                value={state.getDetails?.RawAddress?.CountryISO2}
                validation={props.validation()}
              />
            </div>
            {Object.keys(state.getDetails).length > 0 ? <HiddenFields /> : null}
          </div>
        </div>
      </fieldset>
    </>
  );
};
export default PreviousPostCode;
