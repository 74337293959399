import { Api } from "../api/Api";
import {useErrorWebhook} from "./useErrorWebhook";

export const useDuplicateInfo = () => {
  const { ErrorWebhookTrigger } = useErrorWebhook();
  const saveDuplicateLog = async (type,uuid,key) => {
    const response = await Api.post("v1/duplicate-log" ,{
        type,uuid,key
      }).catch(function (error) {
        const erroMessage = {
          serviceName : 'DUPLICATE LOG',
          errorReason:error.message,
          errorCode:error.code
        }
        ErrorWebhookTrigger(erroMessage);
      })
    return response;
  };

  return { saveDuplicateLog};
};
