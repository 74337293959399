//import {useState} from 'react';
import { Api, CommonEndApi } from "../api/Api";
import * as EnvConstants from "../Constants/EnvConstants";
import { useErrorWebhook } from "./useErrorWebhook";


export const usePostCodeAddress = () => {

  const { ErrorWebhookTrigger } = useErrorWebhook();
  const getPostcodeAddress = async (postcode, uuid) => {
    const response = await CommonEndApi.get(
      "api/get-addr-list?ProjectCode=" + EnvConstants.AppAdtopiaUPID + '&Environment=' + EnvConstants.AppEnv + '&postcode=' + postcode + '&uuid=' + uuid,
      {}
    ).catch(function (error) {
      const erroMessage = {
        serviceName: 'GET POSTCODE ADDRESS LIST',
        errorReason: error.message,
        errorCode: error.code
      }
      ErrorWebhookTrigger(erroMessage);
    });;
    return response;
  };
  // const getSplitPostcodeAddress = async (AddressID, postcode, uuid) => {
  //   const response = await CommonEndApi.get(
  //     "api/get-addr-details?ProjectCode=" + EnvConstants.AppAdtopiaUPID + '&Environment=' + EnvConstants.AppEnv + '&addressId=' + AddressID + "&uuid=" + uuid,
  //     {}
  //   ).catch(function (error) {
  //     const erroMessage = {
  //       serviceName: 'GET POSTCODE ADDRESS DETAILS',
  //       errorReason: error.message,
  //       errorCode: error.code
  //     }
  //     ErrorWebhookTrigger(erroMessage);
  //   });
  //   return response;
  // };
  const getSplitPostcodeAddress = async (AddressID, uuid) => {
    const response = await CommonEndApi.post(
      "api/get-addr-details",
      {
        ProjectCode : EnvConstants.AppAdtopiaUPID,
        Environment: EnvConstants.AppEnv ,
        addressId :  AddressID,
        uuid : uuid
      }
    ).catch(function (error) {
      const erroMessage = {
        serviceName: 'GET POSTCODE ADDRESS DETAILS',
        errorReason: error.message,
        errorCode: error.code
      }
      ErrorWebhookTrigger(erroMessage);
    });
    let addressDetails = {};
    if (response.data && typeof response.data === 'object') {
        const addressData = response.data;
        const vendorResponse = addressData.vendor_response || {};
        console.log(addressData);
        addressDetails = {
            RawAddress: {
              BuildingName : addressData.building_name || vendorResponse.RawAddress?.BuildingName || "",
              BuildingNumber : addressData.building_number || vendorResponse.RawAddress?.BuildingNumber || "",
              Department : addressData.department || vendorResponse.RawAddress?.Department || "",
              Organisation : addressData.organisation || vendorResponse.RawAddress?.Organisation || "",
              OrganisationKey :  addressData.organisation_key || vendorResponse.RawAddress?.OrganisationKey || "",
              SubBuildingName : addressData.sub_building_name || vendorResponse.RawAddress?.SubBuildingName || "",
              SubBuildingNumber : addressData.sub_building_number || vendorResponse.RawAddress?.SubBuildingNumber || "",
              ThoroughfareName : addressData.thoroughfare || vendorResponse.RawAddress?.ThoroughfareName || "",
              ThoroughfareDesc : addressData.thoroughfare_desc || vendorResponse.RawAddress?.ThoroughfareDesc || "",
              Locality:addressData.locality || vendorResponse.RawAddress?.Locality || "",
              Location:{
                District:addressData.district || vendorResponse.RawAddress?.Location?.District || "",
              },
              AddressKey : addressData.address_key || vendorResponse.RawAddress?.AddressKey || "",
              PostcodeType : addressData.postcode_type || vendorResponse.RawAddress?.PostcodeType || "",
              DependentThoroughfareName : addressData.dependent_thoroughfarename || vendorResponse.RawAddress?.DependentThoroughfareName,
              DependentThoroughfareDesc : addressData.dependent_thoroughfare_desc || vendorResponse.RawAddress?.DependentThoroughfareDesc,
              DoubleDependentLocality : addressData.double_dependent_locality || vendorResponse.RawAddress?.DoubleDependentLocality,
              DependentLocality : addressData.dependent_locality || vendorResponse.RawAddress?.DependentLocality,
              Dps : addressData.dps || vendorResponse.RawAddress?.Dps,
              PoBox : addressData.po_box || vendorResponse.RawAddress?.PoBox,
              PostalCounty : addressData.postal_county || vendorResponse.RawAddress?.PostalCounty,
              TraditionalCounty : addressData.traditional_county || vendorResponse.RawAddress?.TraditionalCounty,
              AdministrativeCounty : addressData.administartive_county || vendorResponse.RawAddress?.AdministrativeCounty 
            },
            line_1: addressData.line_1 || vendorResponse.Address?.Lines[0] || "",
            line_2: addressData.line_2 || vendorResponse.Address?.Lines[1] || "",
            line_3: addressData.line_3 || vendorResponse.Address?.Lines[2] || "",
            line_4: addressData.line_4 || vendorResponse.Address?.Lines[3] || "",
            town: addressData.town || vendorResponse.Address?.Lines[4] || "",
            county: addressData.county || vendorResponse.Address?.Lines[5] || "",
            country: addressData.country || vendorResponse.RawAddress?.Location?.Country || "England",
            CountryISO2:addressData.country_iso2 || vendorResponse.RawAddress?.CountryISO2 || "",
            postCode:addressData.postcode || vendorResponse.RawAddress?.Postcode
        };
        addressDetails.id = "";
        addressDetails.address = "Please Select Address";
    }
    return addressDetails;
  };
  return { getPostcodeAddress, getSplitPostcodeAddress };
};
