import React, { useState, useContext } from "react";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import Telephone from "../../../UI/Telephone";
import { numberValidation } from "../../../../Utility/Validations";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";
import Email from "../../../UI/Email";
import { slideValidation } from "../../../../Utility/Validations";
import Button from "../../../UI/Button";
import CheckBox from "../../../UI/CheckBox";
import { getBrandName } from "../Common/BrandBasedOnDomain";
import GTMDataLayer from "../../../../Utility/GTMDataLayer";
// import DynamicFooter from "../Common/DynamicFooter";

const ContactDetails = (props) => {
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();
  const [contactValidation, setContactValidation] = useState({
    txtPhone: false,
    txtEmail: false,
  });
  const brandName = getBrandName();
  const [checkBox, setCheckBox] = useState(true);
  const phoneValidCheck = async () => {
    const values = props.getValues();
    const phone = values["txtPhone"].replace(/^0+/, '');
    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(
        phone,
        visitorParameters.visitor_parameters.uuid
      );
      if (getPhoneValidation.data.status_code === 0) {
        setContactValidation((prevState) => ({
          ...prevState,
          txtPhone: false,
        }));
        props.setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 2) {
        setContactValidation((prevState) => ({
          ...prevState,
          txtPhone: false,
        }));
        props.setError("txtPhone", {
          type: "manual",
          message: "Phone Number Already Exist",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 3) {
        setContactValidation((prevState) => ({
          ...prevState,
          txtPhone: false,
        }));
        props.setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        return 0;
      } else {
        setContactValidation((prevState) => ({
          ...prevState,
          txtPhone: true,
        }));
        props.clearErrors("txtPhone");
        return 1;
      }
    } else {
      props.setError("txtPhone", {
        type: "manual",
        message: "Please Enter Valid Phone Number",
      });
      return 0;
    }
  };
  const emailValidCheck = async () => {
    const values = props.getValues();
    const email = values["txtEmail"];
    if (email !== "") {
      const getEmailValidation = await emailValidation(
        email,
        visitorParameters.visitor_parameters.uuid
      );
      if (getEmailValidation.data.status_code === 1) {
        setContactValidation((prevState) => ({
          ...prevState,
          txtEmail: true,
        }));
        props.clearErrors("txtEmail");
        return 1;
      } else {
        setContactValidation((prevState) => ({
          ...prevState,
          txtEmail: false,
        }));
        props.setError("txtEmail", {
          type: "manual",
          message: "Invalid Email",
        });
      }
    } else {
      props.setError("txtEmail", {
        type: "manual",
        message: "Please Enter Valid Email",
      });
    }
  };
  const eventOnchange = (e) => {
    var field = e.target.id;
    setContactValidation((prevState) => ({
      ...prevState,
      [field]: false,
    }));
    if ((e.target.name = "txtphone" && e.target.value.length >= 11))
      phoneValidCheck();
  };
  const handleContactDetails = async (e) => {
    e.preventDefault();
    props.setIsButtonDisabled(false);
    try {
      var validationArr = ["txtPhone", "txtEmail"];
      const validationCheck = await slideValidation(
        validationArr,
        props.trigger
      );
      if (validationCheck === "Success") {
        const contactStatus = Object.entries(contactValidation).find(
          ([key, value]) => value === false
        );
        // let checkBoxStatus
        if(checkBox == false){
          props.setError("confirmBox", {
            type: "manual",
            message: "Please tick to accept in order to proceed",
          });
        } else {
          if (contactStatus) {
            let response;
            if (contactStatus[0] === "txtPhone") {
              response = await phoneValidCheck();
            } else if (contactStatus[0] === "txtEmail") {
              response = await emailValidCheck();
            }
            if (response == 1) {
              props.setIsButtonDisabled(true);
            //   GTMDataLayer({
            //     clickEvent: 'slideclick',
            //     question: 'communication details',
            //     answer: '',
            //   });
              props.slideChange(e);
            }
            return;
          } else {
            props.setIsButtonDisabled(true);
            // GTMDataLayer({
            //   clickEvent: 'slideclick',
            //   question: 'communication details',
            //   answer: '',
            // });
            props.slideChange(e);
          }
        }
      }
    } catch (error) {
      console.error("Error during validation or submission process:", error);
    }
  };
 

  return (
    <div className="drop-in ">
      <div className="col-lg-12 mb-3">
        <Telephone
          name="txtPhone"
          id="txtPhone"
          className="form-control privacyg pr-guard"
          placeholder="Enter Mobile Number"
          onBlur={phoneValidCheck}
          onChange={(e) => {
            props.clearErrors("txtPhone");
            eventOnchange(e);
          }}
          onKeyPress={(e) => {
            numberValidation(e);
          }}
          maxlength="11"
          minLength="11"
          validation={props.validation({
            pattern: {
              value: /^[0-9]*$/i,
              message: "Please Enter Valid Phone Number",
            },
            required: "Please Enter Phone Number",
          })}
          validationMsg={
            props.validationMsg.txtPhone &&
            props.validationMsg.txtPhone.message
          }
        ></Telephone>
      </div>

      <div className="col-lg-12 mb-3">
        <Email
          name="txtEmail"
          className="form-control privacyg pr-guard"
          id="txtEmail"
          placeholder="Email Addresss"
          onBlur={emailValidCheck}
          onKeyPress={(e) => {
            if (e.key === " ") e.preventDefault();
          }}
          onChange={(e) => {
            props.clearErrors("txtEmail");
            eventOnchange(e);
          }}
          validation={props.validation({
            required: "Please Enter Valid Email Address",
          })}
          validationMsg={
            props.validationMsg.txtEmail &&
            props.validationMsg.txtEmail.message
          }
        ></Email>
      </div>

      <p className="form-check  d-flex mt-3">
        <CheckBox
          className="form-check-input cntct-plcy"
          type="checkbox"
          value=""
          id="confirmBox"
          name="confirmBox"
          checked={true}
          // onChange={() => {setCheckBox(!checkBox); props.clearErrors("confirmBox")}}
          labelClassName="pe-2"
        />
              <span style={{ fontSize: 12, textAlign: "left" }}>
                  Your personal information will be treated carefully in
                  accordance with our Privacy Policy. We {brandName}, is a
                  trading style of The Claims Guys Legal, will contact you
                  about claim opportunities using the contact details you
                  provide. You can opt out of receiving communications from us
                  at any time by sending an email to{" "}
                  <a href="mailto:dataprotection@theclaimsguyslegal.com">
                      dataprotection@theclaimsguyslegal.com
                  </a>
              </span>
      </p>
      {props.validationMsg.confirmBox && (<span className="error_msg">{props.validationMsg.confirmBox.message}</span>)}
      <div className="col-12 row">
        <div className="col-4">
          <Button
            type="button"
            name="back3"
            className="btn-back  mt-4 prev-slide3"
            id="contactDetails"
            onClick={props.previousSlideChange}
            buttonText={`< Back`}
          />
        </div>
        <div className="col-8 pr-0">
          <Button
            name="slide4"
            className="btn-banner btn-fullwidth mt-4 nextsignature"
            buttonText="Next >"
            type="button"
            onClick={(e) => {
              handleContactDetails(e);
            }}
            disabled={props.isButtonDisabled}
          />
        </div>
      </div>
    </div>
  )
};

export default ContactDetails;
